import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { NgFloorPipeModule } from 'angular-pipes';

import { TimeDifferencePipe } from './time-difference.pipe';
import { TimeAgoPipe } from './time-ago.pipe';
import { FilterPipe } from './filter.pipe';
import { SearchFilterPipe } from './search-filter.pipe';
import { SanitizeHtmlPipe } from './sanitize-html.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';
import { TranslatePipe } from './translate.pipe';

@NgModule({
  declarations: [
    TimeDifferencePipe,
    TimeAgoPipe,
    FilterPipe,
    SearchFilterPipe,
    SanitizeHtmlPipe,
    SafeHtmlPipe,
    TranslatePipe
  ],
  imports: [
    CommonModule,
    IonicModule,
    NgFloorPipeModule
  ],
  exports: [
    NgFloorPipeModule,
    TimeDifferencePipe,
    TimeAgoPipe,
    FilterPipe,
    SearchFilterPipe,
    SanitizeHtmlPipe,
    SafeHtmlPipe,
    TranslatePipe
  ]
})
export class PipesModule {}

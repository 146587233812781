import { Component, EventEmitter, HostListener, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AppRoutes } from 'app/enums/app-routes.enum';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastService } from 'app/services/toast.service';
import { Clipboard } from '@capacitor/clipboard';

@Component({
  selector: 'app-contact-us',
  templateUrl: 'contact-us.component.html',
  styleUrls: [
    './contact-us.component.scss'
  ]
})

export class ContactUsComponent {
  @Output() routeEvent = new EventEmitter<AppRoutes>();
  contactUsForm: FormGroup;
  public appRoutes = AppRoutes;
  private clipboard = Clipboard;

  validation_messages = {
    'email': [
      { type: 'required', message: 'Email is required.' },
      { type: 'pattern', message: 'Enter a valid email.' }
    ]
  };

  constructor(private modalController: ModalController, private toastService: ToastService) {
  
    this.contactUsForm = new FormGroup({
      'email': new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*$')
      ]))
    });
  }

  contactUs(): void {
    // TODO implement forgot password
    this.toastService.showSuccess('Thanks for your message, we will follow up with you soon.', null, {duration: 3000, position: 'top'})
    this.routeEvent.emit(AppRoutes.back);
  }

  dismiss(): void {
    this.modalController.dismiss();
  }

  @HostListener('document:keydown.enter')
  public submitFormOnEnterPress() {
    if (this.contactUsForm.valid) {
      this.contactUs();
    }
  }

  public async copyLink() {
    await Clipboard.write({
      string: "support@yeahshare.com"
    });

    this.toastService.showSuccess('Email copied to clipboard', null, {position: 'top'});
  }
}

import { env } from './env';

export class Env {
  env: string;
  production: boolean;
  serviceWorker: boolean;
  app: any;
  api: any;
  logger: any;
  integrations: any;
  settings: any;
  appShellConfig: any;

  constructor(obj: any) {
    this.env = env.environment;
    this.production = obj.production;
    this.serviceWorker = obj.serviceWorker;
    this.app = obj.app;
    this.api = obj.api;
    this.logger = obj.logger;
    this.integrations = obj.integrations;
    this.settings = obj.settings;
    this.appShellConfig = obj.appShellConfig;

    switch (env.environment) {
      case 'production': // ionic deploy
        this.production = true;
        this.integrations.ga.enabled = true;
        this.integrations.ga.measurementId = 'G-3BZRNDH947';
        this.integrations.datadog.rumEnabled = false;
        // tslint:disable-next-line:no-switch-case-fall-through
      case 'prod':
        this.app.baseUrl = 'https://app.yeahshare.com';
        this.api.baseUrl = '/api';
        this.api.baseWebSocket = 'wss://wss.yeahshare.com';
        this.logger.defaultLevels = { fatal: true, error: true, warn: true, info: true, debug: false, trace: false };
        this.logger.console = false;
        this.logger.cloud = false;
        this.logger.datadog = true;
        break;
      case 'staging': // ionic deploy
      // this.production = true;
      this.integrations.datadog.rumEnabled = false;
      this.logger.datadog = true;
      // tslint:disable-next-line:no-switch-case-fall-through
      case 'stg':
        this.app.baseUrl = 'https://stg.yeahshare.com';
        this.api.baseUrl = 'https://stg.yeahshare.com/api';
        this.api.baseWebSocket = 'ws:api.stg.yeahshare.com:7006';
        this.logger.defaultLevels = { fatal: true, error: true, warn: true, info: true, debug: false, trace: false };
        this.logger.console = true;
        this.logger.cloud = false;
        this.logger.datadog = true;
        this.settings.devMetaData = false;
        this.settings.showErrorMessages = true;
        this.settings.showBigWigOnDashboard = true;
        break;
      case 'local':
        this.app.baseUrl = 'http://localhost:8100';
        this.api.baseUrl = 'http://localhost:8001';
        this.api.baseWebSocket = 'ws://localhost:8003';
        this.logger.defaultLevels = { fatal: true, error: true, warn: true, info: true, debug: true, trace: true };
        this.logger.console = true;
        this.logger.cloud = false;
        this.settings.devMetaData = true;
        this.settings.showErrorMessages = true;
        this.settings.showBigWigOnDashboard = true;
        break;
      case 'local-k8s':
        this.app.baseUrl = '';
        this.api.baseUrl = '/api';
        this.api.baseWebSocket = '';
        this.logger.console = true;
        this.logger.cloud = false;
        this.settings.devMetaData = true;
        this.settings.showErrorMessages = true;
        this.settings.showBigWigOnDashboard = true;
        break;
    }
  }
}

const devConfig = {
  production: false,
  serviceWorker: false,
  app: {
    baseUrl: 'https://app.yeahshare.com',
    version: '1.2.11',
    bundleId: 'io.yeahshare.app',
    yeahShareUserId: '00000000-0000-0000-0000-000000000000',
    sessionTimeout: 1800, // do not set less than 1 minute, do not set more than api secretLoginTimeout, 1800 seconds = 30 minutes
    modalSessionTime: 30, // 30 seconds
    showTranslationKeys: false,
    versionAgreements: '1.0.0',
  },
  api: {
    baseUrl: 'http://api.yeahshare.com',
    baseWebSocket: 'wss://ws.yeahshare.com',
    headers: {
      xApiKey: {
        key: 'X-Api-Key',
        value: 'c68982b6-0dc0-4b63-9e01-637a60e71f59'
      },
      authorization: {
        key: 'Authorization',
        value: 'Bearer'
      },
      subIdentity: {
        key: 'X-Sub-Identity'
      }
    }
  },
  logger: {
    console: false,
    cloud: false,
    datadog: false,
    maxLogLineCache: 1000,
    defaultLevels: { fatal: true, error: true, warn: true, info: true, debug: false, trace: false }
  },
  integrations: {
    ga: {
      enabled: false
    },
    datadog: { // RUM
      rumEnabled: false
    }
  },
  settings: {
    largeViewSize: 768,
    largeViewSizeNative: 1367,
    disableNoNetwork: false,
    devMetaData: false,
    showErrorMessages: false,
    showErrorSupportMessage: false,
    showBigWigOnDashboard: false
  },
  appShellConfig: {
    debug: false,
    networkDelay: 500
  }
};

export const environment = new Env(devConfig);

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

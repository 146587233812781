<ion-header>
  <ion-toolbar mode="md" color="secondary">
    <ion-buttons slot="end">
      <ion-button color="primay" fill="solid" type="button" color="primary" fill="clear" (click)="dismiss()">
        Close
        <ion-icon slot="end" name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      Get on top
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <ng-container [ngSwitch]="musingContext">

    <div *ngSwitchCase="'location'">
      <ion-row class="ion-align-items-start pb-32">
        <ion-col size="auto" class="pt-16">
          <div class="flex-column align-center pb-8">
            <ion-icon class="fs-24" name="bar-chart" color="tertiary"></ion-icon>
          </div>
          <div class="flex-column align-center ion-text-center musing-page" [ngClass]="{'w-100': isLargeView, 'w-75': !isLargeView}">
            Statistic
          </div>
        </ion-col>
        <ion-col>
          <div class="musing-page-text">
            58+ million companies are on LinkedIn and 80%+ of posted jobs are still tied to geo.
          </div>
        </ion-col>
      </ion-row>
      <ion-row class="ion-align-items-start pb-32">
        <ion-col size="auto" class="pt-16">
          <div class="flex-column align-center pb-8">
            <ion-icon class="fs-24" name="trophy" color="tertiary"></ion-icon>
          </div>
          <div class="flex-column align-center ion-text-center musing-page" [ngClass]="{'w-100': isLargeView, 'w-75': !isLargeView}">
            Take<br>Action
          </div>
        </ion-col>
        <ion-col>
          <div class="musing-page-text">
            Wherever you plan to go, see where the companies you reach are geographically based.
            Help your friends and colleagues do the same by quickly sharing your top locations.
          </div>
        </ion-col>
      </ion-row>
      <ion-row class="ion-align-items-start pb-32">
        <ion-col size="auto" class="pt-16">
          <div class="flex-column align-center pb-8">
            <ion-icon class="fs-24" name="thumbs-up" color="tertiary"></ion-icon>
          </div>
          <div class="flex-column align-center ion-text-center musing-page" [ngClass]="{'w-100': isLargeView, 'w-75': !isLargeView}">
            Best<br>Practice
          </div>
        </ion-col>
        <ion-col>
          <div class="musing-page-text">
            Face to face meetings matter. Building connections, ensuring participation, studying body language, and improved focus on key topics are all byproducts of bringing people together.
          </div>
        </ion-col>
      </ion-row>
    </div>

    <div *ngSwitchCase="'company'">
      <ion-row class="ion-align-items-start pb-32">
        <ion-col size="auto" class="pt-16">
          <div class="flex-column align-center pb-8">
            <ion-icon class="fs-24" name="bar-chart" color="tertiary"></ion-icon>
          </div>
          <div class="flex-column align-center ion-text-center musing-page" [ngClass]="{'w-100': isLargeView, 'w-75': !isLargeView}">
            Statistic
          </div>
        </ion-col>
        <ion-col>
          <div class="musing-page-text">
            50M people use LinkedIn to search for jobs every week and 95 applications are submitted every second.
            But the #1 way people discover a new job is through a referral.
          </div>
        </ion-col>
      </ion-row>
      <ion-row class="ion-align-items-start pb-32">
        <ion-col size="auto" class="pt-16">
          <div class="flex-column align-center pb-8">
            <ion-icon class="fs-24" name="trophy" color="tertiary"></ion-icon>
          </div>
          <div class="flex-column align-center ion-text-center musing-page" [ngClass]="{'w-100': isLargeView, 'w-75': !isLargeView}">
            Take<br>Action
          </div>
        </ion-col>
        <ion-col>
          <div class="musing-page-text">
            The average number of connections is ~500-999. Compare your number of connections.
            The more people you are connected with, the higher the potential engagement.
          </div>
        </ion-col>
      </ion-row>
      <ion-row class="ion-align-items-start pb-32">
        <ion-col size="auto" class="pt-16">
          <div class="flex-column align-center pb-8">
            <ion-icon class="fs-24" name="thumbs-up" color="tertiary"></ion-icon>
          </div>
          <div class="flex-column align-center ion-text-center musing-page" [ngClass]="{'w-100': isLargeView, 'w-75': !isLargeView}">
            Best<br>Practice
          </div>
        </ion-col>
        <ion-col>
          <div class="musing-page-text">
            LinkedIn’s job board is an amazing starting point and research tool for any job search.
            But it is imperative to couple every serious online application with networking and referrals.
          </div>
        </ion-col>
      </ion-row>
    </div>

    <div *ngSwitchCase="'message'">
      <ion-row class="ion-align-items-start pb-32">
        <ion-col size="auto" class="pt-16">
          <div class="flex-column align-center pb-8">
            <ion-icon class="fs-24" name="bar-chart" color="tertiary"></ion-icon>
          </div>
          <div class="flex-column align-center ion-text-center musing-page" [ngClass]="{'w-100': isLargeView, 'w-75': !isLargeView}">
            Statistic
          </div>
        </ion-col>
        <ion-col>
          <div class="musing-page-text">
            LinkedIn users publish more than 2 million posts, articles, and videos each day. That’s a lot of noise. We bring you all of the benefits of LinkedIn without all of the distractions.
          </div>
        </ion-col>
      </ion-row>
      <ion-row class="ion-align-items-start pb-32">
        <ion-col size="auto" class="pt-16">
          <div class="flex-column align-center pb-8">
            <ion-icon class="fs-24" name="trophy" color="tertiary"></ion-icon>
          </div>
          <div class="flex-column align-center ion-text-center musing-page" [ngClass]="{'w-100': isLargeView, 'w-75': !isLargeView}">
            Take<br>Action
          </div>
        </ion-col>
        <ion-col>
          <div class="musing-page-text">
            <a target="_blank" href="https://www.linkedin.com/help/linkedin/answer/a547065">Import your contacts</a> to expand your network and reestablish contact.
            Then follow up with a quick message thanking them for the connection.
          </div>
        </ion-col>
      </ion-row>
      <ion-row class="ion-align-items-start pb-32">
        <ion-col size="auto" class="pt-16">
          <div class="flex-column align-center pb-8">
            <ion-icon class="fs-24" name="thumbs-up" color="tertiary"></ion-icon>
          </div>
          <div class="flex-column align-center ion-text-center musing-page" [ngClass]="{'w-100': isLargeView, 'w-75': !isLargeView}">
            Best<br>Practice
          </div>
        </ion-col>
        <ion-col>
          <div class="musing-page-text">
            Personalize every single connection request.
            The longer you wait to engage after a meeting, the less likely people are to remember you.
          </div>
        </ion-col>
      </ion-row>
    </div>

    <div *ngSwitchCase="'network'">
      <ion-row class="ion-align-items-start pb-32">
        <ion-col size="auto" class="pt-16">
          <div class="flex-column align-center pb-8">
            <ion-icon class="fs-24" name="bar-chart" color="tertiary"></ion-icon>
          </div>
          <div class="flex-column align-center ion-text-center musing-page" [ngClass]="{'w-100': isLargeView, 'w-75': !isLargeView}">
            Statistic
          </div>
        </ion-col>
        <ion-col>
          <div class="musing-page-text">
            As much as 80% of jobs are filled through personal and professional connections. You are who you surround yourself with.
          </div>
        </ion-col>
      </ion-row>
      <ion-row class="ion-align-items-start pb-32">
        <ion-col size="auto" class="pt-16">
          <div class="flex-column align-center pb-8">
            <ion-icon class="fs-24" name="trophy" color="tertiary"></ion-icon>
          </div>
          <div class="flex-column align-center ion-text-center musing-page" [ngClass]="{'w-100': isLargeView, 'w-75': !isLargeView}">
            Take<br>Action
          </div>
        </ion-col>
        <ion-col>
          <div class="musing-page-text">
            Imitate the very best of your own network - Relevant job titles, industry-specific terms, and listed skills can attract specific job opportunities.
            Open up lucrative networking opportunities by following business and industry leaders.
          </div>
        </ion-col>
      </ion-row>
      <ion-row class="ion-align-items-start pb-32">
        <ion-col size="auto" class="pt-16">
          <div class="flex-column align-center pb-8">
            <ion-icon class="fs-24" name="thumbs-up" color="tertiary"></ion-icon>
          </div>
          <div class="flex-column align-center ion-text-center musing-page" [ngClass]="{'w-100': isLargeView, 'w-75': !isLargeView}">
            Best<br>Practice
          </div>
        </ion-col>
        <ion-col>
          <div class="musing-page-text">
            Building and maintaining meaningful relationships with other professionals in your industry is an essential career development tool.
          </div>
        </ion-col>
      </ion-row>
    </div>
  </ng-container>
</ion-content>

import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiUrls } from '../enums/api-urls.enum';
import { ExistsResponse } from '../models/exists-response';
import { User } from '../models/user';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private source = 'UserService';

  constructor(private apiService: ApiService) {
  }

  public exists(email: string): Observable<ExistsResponse> {
    if (email && email.length > 0) {
      const func = 'exists';
      return this.apiService.post(this.source, func, ApiUrls.exists, { email }, { expand: 'user' })
        .pipe(map((res) => {
          return new ExistsResponse(res);
        }, (error) => {
          return error;
        }));
    } else {
      return of(new ExistsResponse());
    }
  }

  public existsWelcome(email: string, firstName: string, lastName: string): Observable<ExistsResponse> {
    if (email && email.length > 0) {
      const func = 'exists';
      return this.apiService.post(this.source, func, ApiUrls.exists, { email, firstName, lastName, welcome: true }, { expand: 'user' })
        .pipe(map((res) => {
          return new ExistsResponse(res);
        }, (error) => {
          return error;
        }));
    } else {
      return of(new ExistsResponse());
    }
  }

  public getById(id: string): Observable<User> {
    const func = 'getById';
    return this.apiService.get(this.source, func, `${ApiUrls.user}/${id}`)
      .pipe(map((res) => {
        return new User(res);
      }, (error) => {
        return error;
      }));
  }

  public put(user: User): Observable<User> {
    const func = 'put';
    const u = new User(user);
    u.cleanseForAPI();
    return this.apiService.put(this.source, func, `${ApiUrls.user}/${u.id}`, u)
      .pipe(map((userRes) => {
        return new User(userRes);
      }, (error) => {
        return error;
      }));
  }
}

<ion-modal [isOpen]="modalShowing" [cssClass]="this.isLargeView ? 'modal-tall' : ''" (didDismiss)="modalShowing=false"  (ionModalDidPresent)="resetNewShare()">
    <ng-template>

      <ion-header>
        <ion-toolbar color="primary" ion-toolbar mode="md">
          <ion-buttons slot="start">
            <ion-menu-button menu="none" autoHide="false" color="light" (click)="closeModal()">
              <ion-icon class="fs-24" name="arrow-back-outline"></ion-icon>
            </ion-menu-button>
          </ion-buttons>
          <ion-title>Share your record</ion-title>
        </ion-toolbar>
      </ion-header>
      
      <ion-content>
        <div class="ion-padding">

          <div>Enter the recipient's name and email.</div>
          <ion-item>
            <ion-label color="tertiary">Name:</ion-label>
            <ion-input placeholder="name" (ionChange)="newShare.sharedWithName = $event.target.value"></ion-input>
          </ion-item>
  
          <ion-item>
            <ion-label color="tertiary">Email:</ion-label>
            <ion-input placeholder="name@example.com" (ionChange)="newShare.sharedWithEmail = $event.target.value"></ion-input>
          </ion-item>

          <ion-item lines="none" [disabled]="!newShare.sendEmail">
            <ion-label color="tertiary" [position]="!isLargeView ? 'stacked' : null">Message:</ion-label>
            <ion-textarea placeholder="I’m confident this snapshot of my professional network will help you."
              spellcheck="true" rows="3" auto-grow="true" inputmode="text" type="text"
              (ionChange)="newShare.sharedWithEmailMessage = $event.target.value"></ion-textarea>
          </ion-item>
          <ion-item lines="full">
            <ion-checkbox mode="md" class="checkbox-small" slot="start" [checked]="newShare.sendEmail" (ionChange)="newShare.sendEmail = !!!newShare.sendEmail"></ion-checkbox>
            <div *ngIf="newShare.sendEmail" class="fs-14 pb-6 color-primary">Uncheck this box to disable yeahshare system email. Instead, you’ll copy/paste your share link on next screen.</div>
            <div *ngIf="!newShare.sendEmail" class="fs-14 pb-6 color-medium-shade">Check this box and we'll deliver your yeahshare record link via email.</div>
          </ion-item>

          <p class="base-margin">Choose what categories of your network you would like to share and how many connections for each.</p>

          <div class="flex-row">
            <ion-item style="width: 50%;">
              <ion-checkbox slot="start" [checked]="newShare?.locationShared" (ionChange)="newShare.locationShared = !!!newShare.locationShared"></ion-checkbox>
              <ion-label [ngClass]="{'color-medium-shade': !newShare?.locationShared}">Location</ion-label>
            </ion-item>
            <div style="width: 50%;" class="flex-row">
              <p [ngClass]="{'color-medium-shade': !newShare?.locationShared}">Top:</p>
              <ion-item class="mt-5">
                <ion-select [disabled]="!newShare?.locationShared" [ngModel]="locationTop" (ionChange)="updateTop('location', $event.target.value)">
                  <ion-select-option value="10">10</ion-select-option>
                  <ion-select-option value="50">50</ion-select-option>
                  <ion-select-option value="100">100</ion-select-option>
                  <ion-select-option value="-1">All</ion-select-option>
                </ion-select>
              </ion-item>
            </div>
          </div>

          <div class="flex-row">
            <ion-item style="width: 50%;">
              <ion-checkbox slot="start" [checked]="newShare?.companiesShared" (ionChange)="newShare.companiesShared = !!!newShare.companiesShared"></ion-checkbox>
              <ion-label [ngClass]="{'color-medium-shade': !newShare?.companiesShared}">Companies</ion-label>
            </ion-item>
            <div style="width: 50%;" class="flex-row">
              <p [ngClass]="{'color-medium-shade': !newShare?.companiesShared}">Top:</p>
              <ion-item class="mt-5">
                <ion-select [disabled]="!newShare?.companiesShared" [ngModel]="companiesTop" (ionChange)="updateTop('companies', $event.target.value)">
                  <ion-select-option value="10">10</ion-select-option>
                  <ion-select-option value="50">50</ion-select-option>
                  <ion-select-option value="100">100</ion-select-option>
                  <ion-select-option value="-1">All</ion-select-option>
                </ion-select>
              </ion-item>
            </div>
          </div>

          <div class="flex-row">
            <ion-item style="width: 50%;">
              <ion-checkbox slot="start" [checked]="newShare?.messagesShared" (ionChange)="newShare.messagesShared = !!!newShare.messagesShared"></ion-checkbox>
              <ion-label [ngClass]="{'color-medium-shade': !newShare?.messagesShared}">Engagement</ion-label>
            </ion-item>
            <div style="width: 50%;" class="flex-row">
              <p [ngClass]="{'color-medium-shade': !newShare?.messagesShared}">Top:</p>
              <ion-item class="mt-5">
                <ion-select [disabled]="!newShare?.messagesShared" [ngModel]="messagesTop" (ionChange)="updateTop('messages', $event.target.value)">
                  <ion-select-option value="10">10</ion-select-option>
                  <ion-select-option value="50">50</ion-select-option>
                  <ion-select-option value="100">100</ion-select-option>
                  <ion-select-option value="-1">All</ion-select-option>
                </ion-select>
              </ion-item>
            </div>
          </div>

          <div class="flex-row">
            <ion-item style="width: 50%;">
              <ion-checkbox slot="start" [checked]="newShare?.networkShared" (ionChange)="newShare.networkShared = !!!newShare.networkShared"></ion-checkbox>
              <ion-label [ngClass]="{'color-medium-shade': !newShare?.networkShared}">Network</ion-label>
            </ion-item>
            <div style="width: 50%;" class="flex-row">
              <p [ngClass]="{'color-medium-shade': !newShare?.networkShared}">Top:</p>
              <ion-item class="mt-5">
                <ion-select [disabled]="!newShare?.networkShared" [ngModel]="networkTop" (ionChange)="updateTop('network', $event.target.value)">
                  <ion-select-option value="10">10</ion-select-option>
                  <ion-select-option value="50">50</ion-select-option>
                  <ion-select-option value="100">100</ion-select-option>
                  <ion-select-option value="all">All</ion-select-option>
                </ion-select>
              </ion-item>
            </div>
          </div>
        </div>
        <div class="background-secondary pl-16 pr-16">
          <ion-row class="ion-align-items-center">
            <ion-col>
              Preview the focused record your recipient will access.
            </ion-col>
            <ion-col size="auto">
              <ion-button (click)="previewShare()" color="primary" fill="clear" class="toolbar-button mr-16">
                <ion-icon name="open-outline"></ion-icon>
              </ion-button>
            </ion-col>
          </ion-row>
        </div>
        <div class="pl-16 pr-16 fs-14 pt-10">
          Your shared record does <strong>NOT</strong> contain your messages, message insights & counts, any search function, nor the percentage breakdowns of your network.
        </div>
        <div class="ion-padding">
          <ion-button type="submit" expand="block" color="primary" fill="solid" [disabled]="!validEmail()" (click)="share()">Share</ion-button>
        </div>

      </ion-content>

    </ng-template>
  </ion-modal>


<ion-header *ngIf="isLargeView">
  <ion-toolbar color="primary" ion-toolbar mode="md">
    <ion-buttons *ngIf="!isLargeView" slot="start">
      <ion-menu-button menu="none" autoHide="false" color="light" (click)="handleRouteEvent(appRoutes.back)">
        <ion-icon class="fs-24" name="arrow-back-outline"></ion-icon>
      </ion-menu-button>
    </ion-buttons>
    <ion-title>Profile</ion-title>
    <ion-buttons *ngIf="isLargeView" slot="end">
      <ion-button color="primay" fill="solid" type="button" color="primary" fill="solid" (click)="handleRouteEvent(appRoutes.dismiss)">
        Close
        <ion-icon slot="end" name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">

  <form [formGroup]="profileForm">
    <ion-list class="inputs-list" lines="full">
      <ion-item class="input-item">
        <ion-label position="floating">First Name:</ion-label>
        <ion-input type="text" placeholder="name" formControlName="firstName" clearInput autocapitalize="off" [readonly]="!editProfile"></ion-input>
      </ion-item>
      <div class="error-container">
        <ng-container *ngFor="let validation of validation_messages.firstName">
          <div class="error-message" *ngIf="profileForm.get('firstName').hasError(validation.type) && (profileForm.get('firstName').dirty || profileForm.get('firstName').touched)">
            <ion-icon name="information-circle-outline"></ion-icon>
            <span>{{ validation.message }}</span>
          </div>
        </ng-container>
      </div>

      <ion-item class="input-item">
        <ion-label position="floating">Last Name:</ion-label>
        <ion-input type="text" placeholder="surname" formControlName="lastName" clearInput autocapitalize="off" [readonly]="!editProfile"></ion-input>
      </ion-item>
      <div class="error-container">
        <ng-container *ngFor="let validation of validation_messages.lastName">
          <div class="error-message" *ngIf="profileForm.get('lastName').hasError(validation.type) && (profileForm.get('lastName').dirty || profileForm.get('lastName').touched)">
            <ion-icon name="information-circle-outline"></ion-icon>
            <span>{{ validation.message }}</span>
          </div>
        </ng-container>
      </div>

      <ion-item class="input-item">
        <ion-label position="floating">LinkedIn Url:</ion-label>
        <ion-input type="url" placeholder="https://www.linkedin.com/in/name" formControlName="url" clearInput autocapitalize="off" inputmode="url" [readonly]="!editProfile"></ion-input>
      </ion-item>
      <div class="error-container">
        <ng-container *ngFor="let validation of validation_messages.url">
          <div class="error-message" *ngIf="profileForm.get('url').hasError(validation.type) && (profileForm.get('url').dirty || profileForm.get('url').touched)">
            <ion-icon name="information-circle-outline"></ion-icon>
            <span>{{ validation.message }}</span>
          </div>
        </ng-container>
      </div>

      <ion-item class="input-item">
        <ion-label position="floating">Email:</ion-label>
        <ion-input type="email" placeholder="name@example.com" formControlName="email" clearInput autocapitalize="off" inputmode="email" [readonly]="!editProfile"></ion-input>
      </ion-item>
      <div class="error-container">
        <ng-container *ngFor="let validation of validation_messages.email">
          <div class="error-message" *ngIf="profileForm.get('email').hasError(validation.type) && (profileForm.get('email').dirty || profileForm.get('email').touched)">
            <ion-icon name="information-circle-outline"></ion-icon>
            <span>{{ validation.message }}</span>
          </div>
        </ng-container>
      </div>

    </ion-list>

    <div class="pt-10">
      <ion-button *ngIf="editProfile" type="submit" expand="block" color="primary" fill="solid" [disabled]="!profileForm.valid" (click)="submitProfileForm()">Save</ion-button>
      <ion-button *ngIf="editProfile" type="button" expand="block" color="primary" fill="outline" (click)="cancelSubmitProfile()">Cancel</ion-button>
    </div>
  </form>

  <div *ngIf="!editProfile && !resetPassword" class="flex-row justify-space-between">
    <ion-button color="primay" fill="solid" type="button" color="primary" fill="solid" (click)="editProfile=true">
      Edit profile
      <ion-icon slot="end" name="pencil-outline"></ion-icon>
    </ion-button>
    <ion-button color="primay" fill="solid" type="button" color="primary" fill="outline" (click)="resetPassword=true">
      Change password
      <ion-icon slot="end" name="key-outline"></ion-icon>
    </ion-button>
  </div>

  <form [@showHide]="resetPassword" [formGroup]="passwordForm">
    <ion-list class="inputs-list" lines="full">

      <div formGroupName="matching_passwords">
        <ion-item class="input-item">
          <ion-label color="tertiary" position="floating">Password:</ion-label>
          <app-show-hide-password [ngClass]="{'show-hide-password': !isLargeView, 'show-hide-password-isLargeView': isLargeView}">
            <ion-input type="password" placeholder="password" formControlName="password"></ion-input>
          </app-show-hide-password>
        </ion-item>
        <div class="error-container">
          <ng-container *ngFor="let validation of validation_messages.password">
            <div class="error-message" *ngIf="passwordForm.get('matching_passwords').get('password').hasError(validation.type) && (passwordForm.get('matching_passwords').get('password').dirty || passwordForm.get('matching_passwords').get('password').touched)">
              <ion-icon name="information-circle-outline"></ion-icon>
              <span>{{ validation.message }}</span>
            </div>
          </ng-container>
        </div>
  
        <ion-item class="input-item">
          <ion-label color="tertiary" position="floating">Password (confirm):</ion-label>
          <app-show-hide-password [ngClass]="{'show-hide-password': !isLargeView, 'show-hide-password-isLargeView': isLargeView}">
            <ion-input type="password" placeholder="confirm password" formControlName="confirm_password"></ion-input>
          </app-show-hide-password>
        </ion-item>
        <div class="error-container">
          <ng-container *ngFor="let validation of validation_messages.confirm_password">
            <div class="error-message" *ngIf="passwordForm.get('matching_passwords').get('confirm_password').hasError(validation.type) && (passwordForm.get('matching_passwords').get('confirm_password').dirty || passwordForm.get('matching_passwords').get('confirm_password').touched)">
              <ion-icon name="information-circle-outline"></ion-icon>
              <span>{{ validation.message }}</span>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="error-container">
        <ng-container *ngFor="let validation of validation_messages.matching_passwords">
          <div class="error-message" *ngIf="passwordForm.get('matching_passwords').hasError(validation.type) && (passwordForm.get('matching_passwords').get('confirm_password').dirty || passwordForm.get('matching_passwords').get('confirm_password').touched)">
            <ion-icon name="information-circle-outline"></ion-icon>
            <span>{{ validation.message }}</span>
          </div>
        </ng-container>
      </div>

    </ion-list>

    <div class="pt-10">
      <ion-button type="submit" expand="block" color="primary" fill="solid" [disabled]="!passwordForm.valid" (click)="submitPasswordForm()">Save</ion-button>
      <ion-button type="button" expand="block" color="primary" fill="outline" (click)="cancelResetPasword()">Cancel</ion-button>
    </div>
  </form>
  
</ion-content>

import { LinkedInConnection } from "./linkedin-connection"
import { LinkedInProfile } from "./linkedin-profile"
import { LinkedInUpload } from "./linkedin-upload"
import { UserProfile } from "./user-profile"

export class LinkedInMessage {
  public id: string
  public createdAt: string
  public updatedAt: string
  public deletedAt: any
  public instanceVersion: number
  public createdBy: string
  public updatedBy: string
  public metadata: any
  public conversationId: string
  public conversationTitle: string
  public from: string
  public senderProfileUrl: string
  public to: string
  public date: string
  public subject: string
  public content: string
  public folder: string
  public sentByMe: boolean
  public linkedInUploadId: string
  public profileId: string
  public linkedInProfileId: string
  public linkedInConnectionId?: string
  public profile: UserProfile
  public linkedInProfile: LinkedInProfile
  public linkedInConnection: LinkedInConnection
  public linkedInUpload: LinkedInUpload

  constructor(obj?: any) {
    Object.assign(this, obj);

    if (obj.profile) {
      this.profile = new UserProfile(obj.profile)
    }

    if (obj.linkedInProfile) {
      this.linkedInProfile = new LinkedInProfile(obj.linkedInProfile)
    }

    if (obj.LinkedInConnection) {
      this.linkedInConnection = new LinkedInConnection(obj.linkedInConnection)
    }

    if (obj.LinkedInUpload) {
      this.linkedInUpload = new LinkedInUpload(obj.linkedInUpload)
    }
  }
}
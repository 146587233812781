<ion-row class="ion-align-items-center ion-justify-content-end width-full">
  <ion-col size="auto" class="cursor-pointer" (click)="emitAction(appRoutes.dismiss)">
    <ion-icon name="close-outline"></ion-icon>
  </ion-col>
</ion-row>
<ion-list>
  <ion-item (click)="emitAction(appRoutes.record)">
    <ion-label class="cursor-pointer">My Record</ion-label>
  </ion-item>
  <ion-item (click)="emitAction(appRoutes.profile)">
    <ion-label class="cursor-pointer">Profile</ion-label>
  </ion-item>
  <ion-item (click)="emitAction(appRoutes.shares)">
    <ion-label class="cursor-pointer">Share</ion-label>
  </ion-item>
  <ion-item (click)="emitAction(appRoutes.uploads)">
    <ion-label class="cursor-pointer">Upload</ion-label>
  </ion-item>
  <ion-item (click)="emitAction(appRoutes.about)">
    <ion-label class="cursor-pointer">About</ion-label>
  </ion-item>
  <ion-item lines="none" (click)="emitAction(appRoutes.logout)">
    <ion-label class="cursor-pointer">Log out</ion-label>
  </ion-item>
</ion-list>

<ion-list *ngIf="isBigWig && isLargeView" [@showHide]="!bigWigMenu">
  <ion-item></ion-item>
  <ion-item lines="none">
    <ion-label color="tertiary" class="fw-600 cursor-pointer" (click)="bigWigMenu = !bigWigMenu">Bigwig Stuff</ion-label>
  </ion-item>
</ion-list>

<ion-list [@showHide]="bigWigMenu">
  <ion-item></ion-item>
  <ion-item lines="none">
    <ion-label color="tertiary" class="fw-600 cursor-pointer" (click)="bigWigMenu = !bigWigMenu">Bigwig Stuff</ion-label>
  </ion-item>
  <ion-item lines="none">
    <ion-label color="warning">Modals</ion-label>
  </ion-item>
  <ion-item class="cursor-pointer" (click)="emitAction(appRoutes.connections)">
    <ion-label class="cursor-pointer">Connections</ion-label>
  </ion-item>
  <ion-item class="cursor-pointer" (click)="emitAction(appRoutes.loader)">
    <ion-label class="cursor-pointer">Loader</ion-label>
  </ion-item>
  <ion-item lines="none">
    <ion-label color="warning">App Messages</ion-label>
  </ion-item>
  <ion-item class="cursor-pointer" (click)="emitAction(appRoutes.processing)">
    <ion-label class="cursor-pointer">Processing</ion-label>
  </ion-item>
  <ion-item class="cursor-pointer" (click)="emitAction(appRoutes.latestFailed)">
    <ion-label class="cursor-pointer">Upload Failed</ion-label>
  </ion-item>
  <!-- // TODO cannot test these messages -->
  <ion-item class="cursor-pointer" [disabled]="true">
    <ion-label class="cursor-pointer">No Uploads</ion-label>
  </ion-item>
  <ion-item lines="none" class="cursor-pointer" [disabled]="true">
    <ion-label class="cursor-pointer">Basic Upload</ion-label>
  </ion-item>
</ion-list>

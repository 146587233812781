
export class CompanySummaryResponse {
  public companies: Array<CompanySummary>;

  constructor(obj: any) {
    Object.assign(this, obj);

    if (obj.companies) {
      this.companies = obj.companies.map((c) => new CompanySummary(c));
    }
  }
}

export class CompanySummary {
  public companyName: string;
  public numConnections: number;
  public percentOfConnections: number;

  constructor(obj: any) {
    Object.assign(this, obj);
  }
}
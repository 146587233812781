<div class="backdrop" *ngIf="loaderCount > 0">
  <div [ngClass]="{'loaderClear': loaderClear, 'loader-largeScreen': !loaderClear && isLargeView, 'loader-smallScreen': !loaderClear && !isLargeView}">
    <div [ngClass]="{'contents': !loaderClear, 'contentsClear': loaderClear}">
      <svg class="spinner" viewBox="0 0 50 50">
        <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="3"></circle>
      </svg>
      <img class="logo" src="assets/img/yeahshare_mark_green.svg" />
      <div *ngIf="!unresponsive" class="loading-text">Loading...</div>
      <div *ngIf="unresponsive" class="unresponsive-text">This upload may take some time...</div>
      <div *ngIf="unresponsive" class="actions"><span class="action" (click)="wait()">Wait</span> &nbsp; &nbsp; <span class="action" (click)="back()">Go Back</span></div>
    </div>
  </div>
</div>

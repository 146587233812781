import { animate, state, style, transition } from '@angular/animations';

export const Accordion = {
  animation: [
    state('true',
      style({
        transform: 'scaleY(1)',
        height: '*',
        visibility: 'visible',
        opacity: '1',
        overflow: 'unset'
      })),
    state('false',
      style({
        transform: 'scaleY(0)',
        height: '0',
        visibility: 'hidden',
        opacity: '0',
        overflow: 'hidden'
      })),
    transition('false <=> true', animate(200))
  ],
  ngIfAnimation: [
    transition(
      ':enter',
      [
        style({ height: 0, opacity: 0 }),
        animate('.3s ease-out',
          style({ height: '*', opacity: 1 }))
      ]
    ),
    transition(
      ':leave',
      [
        style({ height: '*', opacity: 1 }),
        animate('.3s ease-in',
          style({ height: 0, opacity: 0 }))
      ]
    )
  ]
};

import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CardActionsEvent } from '../../models/card-actions-event';
import { BaseComponent } from '../../components/base/base.component';
import { CardActionsMenu } from '../../models/card-actions-menu';

@Component({
  selector: 'app-menu-popover',
  templateUrl: './menu-popover.component.html',
  styleUrls: ['./menu-popover.component.scss']
})
export class MenuPopoverComponent extends BaseComponent implements OnInit {

  constructor(public popoverController: PopoverController) {
    super();
  }

  /**
   * Set a manual y offset for popovers
   * this only applies to popovers using cssClass: ['popover-offset-y']
   */
  static setStylePropPopoverOffsetY(px: `${number}px`) {
    const root = document.documentElement;
    root.style.setProperty('--ys-popover-offset-y', px)
  }

  ngOnInit() {
    setTimeout(() => {
      // wait long enough for browser to paint the submenu
      // before checking content bounds and adjusting the y offset
      // this only applies to popovers using cssClass: ['popover-offset-y']
      // TODO code smell - avoid magic timeouts!
      this.ensurePopoverIsWithinPageBounds();
    }, 25);
  }

  /**
   * Checks if the popover bottom is outside of the page bounds and adds the necessary y offset
   * this only applies to popovers using cssClass: ['popover-offset-y']
   */
  ensurePopoverIsWithinPageBounds() {
    const p = document.querySelector('.popover-viewport')
    const {height, top} = p?.getBoundingClientRect() || {height: 0, top: 0};
    const bottom = height + top;
    const isOffScreen = bottom > window.innerHeight;

    if (isOffScreen) {
      const offset = window.innerHeight - bottom;
      MenuPopoverComponent.setStylePropPopoverOffsetY(`${offset}px`);
    }
  }

  public handleActionEvent(action) {
    this.popoverController.dismiss(action);
  }
}

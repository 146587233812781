<ion-header>
  <ion-toolbar color="primary" ion-toolbar mode="md">
    <ion-title *ngIf="getStarted">Get started</ion-title>
    <ion-title *ngIf="!getStarted">Upload your archive</ion-title>
    <ion-buttons slot="end">
      <ion-button color="primay" fill="solid" type="button" color="primary" fill="solid" (click)="cancel($event)">
        Cancel
        <ion-icon slot="end" name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>

  <div class="ion-padding signup-content" [@showHide]="getStarted && !getStartedHaveDeets">
    <p>First, a few deets so we can get you started.</p>

    <form [formGroup]="signupForm">
      <ion-list class="inputs-list" lines="full">
        <ion-item class="input-item">
          <ion-label color="tertiary">First Name:</ion-label>
          <ion-input type="text" placeholder="name" formControlName="firstName" clearInput autocapitalize="off"></ion-input>
        </ion-item>
        <div class="error-container">
          <ng-container *ngFor="let validation of validation_messages.firstName">
            <div class="error-message" *ngIf="signupForm.get('firstName').hasError(validation.type) && (signupForm.get('firstName').dirty || signupForm.get('firstName').touched)">
              <ion-icon name="information-circle-outline"></ion-icon>
              <span>{{ validation.message }}</span>
            </div>
          </ng-container>
        </div>
  
        <ion-item class="input-item">
          <ion-label color="tertiary">Last Name:</ion-label>
          <ion-input type="text" placeholder="surname" formControlName="lastName" clearInput autocapitalize="off"></ion-input>
        </ion-item>
        <div class="error-container">
          <ng-container *ngFor="let validation of validation_messages.lastName">
            <div class="error-message" *ngIf="signupForm.get('lastName').hasError(validation.type) && (signupForm.get('lastName').dirty || signupForm.get('lastName').touched)">
              <ion-icon name="information-circle-outline"></ion-icon>
              <span>{{ validation.message }}</span>
            </div>
          </ng-container>
        </div>
  
        <ion-item class="input-item">
          <ion-label color="tertiary">Email:</ion-label>
          <ion-input type="email" placeholder="name@example.com" formControlName="email" clearInput autocapitalize="off" inputmode="email"></ion-input>
        </ion-item>
        <div class="error-container">
          <ng-container *ngFor="let validation of validation_messages.email">
            <div class="error-message" *ngIf="signupForm.get('email').hasError(validation.type) && (signupForm.get('email').dirty || signupForm.get('email').touched)">
              <ion-icon name="information-circle-outline"></ion-icon>
              <span>{{ validation.message }}</span>
            </div>
          </ng-container>
        </div>
    
      </ion-list>
  
      <ion-button class="signup-btn" type="submit" expand="block" [disabled]="!signupForm.valid" (click)="submitFormOnEnterPress()">Get Started</ion-button>
    </form>
  
    <div class="width-full ion-text-center">
      <ion-button class="login-btn" fill="clear" (click)="login()">
        Already have an account?
      </ion-button>
    </div>
  </div>

  <div class="height-full flex-column justify-space-between" [@showHide]="!getStarted || getStartedHaveDeets">

    <div class="ion-padding">
      <div *ngIf="getStartedHaveDeets && !fileUploaded">
        <p>Thanks! Next, grab your LinkedIn data.</p>
        <p>No time now? These instructions are also in your email inbox.</p>
      </div>

      <div class="flex-row align-center p-10">
        <div class="flex-column description">
          <h1 class="color-dark">
            Instructions
            <ion-buttons *ngIf="fileUploaded" class="ion-align-items-center ion-float-end">
              <ion-button type="button" color="primary" fill="clear" class="toolbar-button"  *ngIf="!isInstructionsShowing" (click)="isInstructionsShowing = !isInstructionsShowing">
                <ion-icon name="chevron-forward-outline"></ion-icon>
              </ion-button>
              <ion-button type="button" color="primary" fill="clear" class="toolbar-button"  *ngIf="isInstructionsShowing" (click)="isInstructionsShowing = !isInstructionsShowing">
                <ion-icon name="chevron-down-outline"></ion-icon>
              </ion-button>
            </ion-buttons>
          </h1>
          <div [@accordion]="isInstructionsShowing">
            <div class="text-bullet">
              <div class="flex-row">
                <div class="flex-row align-center justify-center bullet-number">
                  <div class="bullet-number-text">1</div>
                </div>
                <div class="text-bullet-label">
                  <span class="bold">Go</span> to LinkedIn’s <a class="color-blue" target="_blank" href="https://www.linkedin.com/settings/data-export-page">Data Export Page</a>
                </div>
              </div>
            </div>
            <div class="text-bullet">
              <div class="flex-row">
                <div class="flex-row align-center justify-center bullet-number">
                  <div class="bullet-number-text">2</div>
                </div>
                <div class="text-bullet-label">
                  <span class="bold">Select</span>&nbsp;<span class="instruction">‘Download larger data archive’</span> and <span class="bold">Click</span> on <span  class="instruction">‘Request Archive’</span>
                </div>
              </div>
            </div>
            <div class="text-bullet">
              <div class="flex-row">
                <div class="flex-row align-center justify-center bullet-number">
                  <div class="bullet-number-text">3</div>
                </div>
                <div class="text-bullet-label">
                  <span class="bold">Receive</span> LinkedIn confirmation email and wait up to <span class="color-warning-shade">24 hours</span> for a link to your data
                </div>
              </div>
            </div>
            <div class="text-bullet">
              <div class="flex-row">
                <div class="flex-row align-center justify-center bullet-number">
                  <div class="bullet-number-text">4</div>
                </div>
                <div class="text-bullet-label">
                  <span class="bold">Click</span> on your emailed Archive link within 72 hours and <span class="bold">Save</span> the .zip file <span [ngClass]="{'instruction': isLargeView, 'instruction-small': !isLargeView}">‘Complete_LinkedInDataExport_XX-XX-XXXX.zip’</span>
                </div>
              </div>
            </div>
            <div class="text-bullet">
              <div class="flex-row">
                <div class="flex-row align-center justify-center bullet-number">
                  <div class="bullet-number-text">5</div>
                </div>
                <div class="text-bullet-label">
                  <span class="bold">Upload</span> the zip file to yeahshare using the button below
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <input #fileInput class="file-input" style="display: none;" type="file" (change)="uploadArchive($event)" id="file-input" accept=".zip">

      <div class="flex-row justify-center py-10">
        <ion-button class="button" (click)="showUploadFile()" [innerHTML]="uploadButtonText"></ion-button>
      </div>

      <div *ngIf="fileUploaded" class="flex-row align-center p-10">
        <div class="flex-column description">
          <div class="text-bullet">
            <div class="flex-row">
              <div class="flex-row align-center justify-center bullet-warning">
                <div class="bullet-warning-text">!</div>
              </div>
              <div class="text-bullet-label">
                Yeahshare will generate limited results based upon the <span class="instruction">‘Basic’</span> LinkedIn Archive file you have provided.
                <p>Instead, upload the <span class="instruction">‘Complete’</span> LinkedIn Archive file to receive 100% of what we offer.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="fileUploaded" class="flex-row justify-center">
        <ion-button color="primary"  fill="outline" (click)="continue($event)">
          Continue with&nbsp;<span class="color-warning">Basic</span>&nbsp;Archive
        </ion-button>
      </div>
    </div>

  </div>

</ion-content>

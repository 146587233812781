import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { takeUntil } from 'rxjs';
import { BaseComponent } from '../../components/base/base.component';

@Component({
  selector: 'app-app-message',
  templateUrl: './app-message.component.html',
  styleUrls: ['./app-message.component.scss']
})
export class AppMessageComponent extends BaseComponent implements OnInit {
  @Input() messageContext: string;
  @Input() actionDisabled = false;
  @Output() actionEvent = new EventEmitter<string>();
  public animated3dot = '...';

  constructor() {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.setup();
  }

  private setup() {
    switch (this.messageContext) {
      case ('processing'):
        this.subscribeToAnimated3dot();
        break;
    }

    if (this.actionDisabled) {
      this.subscribeToActionDisabledEnd();
    }
  }

  private subscribeToActionDisabledEnd() {
    this.utilsService.actionDisabledEnd$.pipe(takeUntil(this.destroyed$))
      .subscribe((actionDisabledEnd) => {
        if (actionDisabledEnd) {
          this.actionDisabled = false;
        }
      });
  }

  private subscribeToAnimated3dot() {
    this.utilsService.animated3dot$.pipe(takeUntil(this.destroyed$))
      .subscribe((animated3dot) => {
        this.animated3dot = animated3dot;
        this.utilsService.animate3dot(animated3dot);
      });
  }

  public emitAction(action: string) {
    this.actionEvent.emit(action);
  }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiUrls } from '../enums/api-urls.enum';
import { UserProfile } from '../models/user-profile';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  private source = 'ProfileService';

  constructor(private apiService: ApiService) {
  }

  public getById(profileId: string): Observable<UserProfile> {
    const func = 'getById';
    return this.apiService.get(this.source, func, `${ApiUrls.profile}/${profileId}?expand=business`)
      .pipe(map((res) => {
        return new UserProfile(res);
      }, (error) => {
        return error;
      }));
  }

  public put(profile: UserProfile, welcome?: boolean): Observable<UserProfile> {
    const func = 'put';
    const p = new UserProfile(profile);
    if (welcome) {
      p.welcome = true;
    }
    p.cleanseForAPI();
    return this.apiService.put(this.source, func, `${ApiUrls.profile}/${p.id}`, p)
      .pipe(map((res) => {
        return new UserProfile(res);
      }, (error) => {
        return error;
      }));
  }

  public post(profile: UserProfile): Observable<UserProfile> {
    const func = 'post';
    const p = new UserProfile(profile);
    p.cleanseForAPI();
    return this.apiService.post(this.source, func, ApiUrls.profile, p)
      .pipe(map((res) => {
        return new UserProfile(res);
      }, (error) => {
        return error;
      }));
  }

  public updateAvatar(file: any): Observable<any> {
    const func = 'updateAvatar';
    const formData = new FormData();
    formData.append('file', file);
    return this.apiService.post(this.source, func, `${ApiUrls.profile}/avatar`, formData)
      .pipe(map((response) => {
          return response;
        }, (error) => {
          return error;
        }));
  }

  public setDefault(profileId: string): Observable<any> {
    return this.apiService.put(this.source, 'setDefault', `${ApiUrls.profile}/${profileId}/default`, null)
    .pipe(map((response) => {
      return response;
    }, (error) => {
      return error;
    }));
  }

  public getOpenIntegrationForms(integrationId: string): Observable<any> {
    return this.apiService.get(this.source, 'getOpenIntegrationForms', `${ApiUrls.profile}/forms/${integrationId}/open`)
    .pipe(map((res) => {
      return res;
    }, (error) => {
      return error;
    }));
  }
}

import { Component, OnInit } from '@angular/core';
// import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';
import { Loader } from '../../models/loader';
import { LoadingService } from '../../services/loading.service';
import { UtilsService } from '../../services/utils.service';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {
  public loader = new Loader(false, false);
  // public faSpinnerThird = faSpinnerThird;
  public loaderCount = 0;
  public loaderClear = true;
  public unresponsive = false;
  public isLargeView: boolean;
  private timer: any;
  private unresponsiveTimer: any;
  private willDismissAll = false;

  constructor(private loadingService: LoadingService, private utilsService: UtilsService) {
  }

  ngOnInit() {
    this.loadingService.loader$.subscribe((loader: Loader) => {
      this.isLargeView = this.utilsService.isLargeView();
      this.loader = loader;
      if (this.loader.isShowing) {
        if (!this.willDismissAll) {
          this.loaderClear = true;
        }
        this.timer = setTimeout(() => {
          this.loaderClear = false;
        }, 1500);
        this.unresponsive = false;
        clearTimeout(this.unresponsiveTimer);
        this.unresponsiveTimer = setTimeout(() => {
          this.unresponsive = true;
        }, 15000);
        this.loaderCount++;
        if (loader.dismissAll) {
          this.willDismissAll = true;
        }
      } else if (this.loader.doDismiss && !this.willDismissAll) {
        this.loaderCount--;
      }
      if ((this.loaderCount < 0 && !this.willDismissAll) || (loader.doDismiss && loader.dismissAll)) {
        this.loaderClear = true;
        clearTimeout(this.timer);
        this.unresponsive = false;
        clearTimeout(this.unresponsiveTimer);
        this.loaderCount = 0;
        this.willDismissAll = false;
      }
    });
  }

  public wait() {
    this.unresponsive = false;
    clearTimeout(this.unresponsiveTimer);
    this.unresponsiveTimer = setTimeout(() => {
      this.unresponsive = true;
    }, 10000);
  }

  public back() {
    this.loaderClear = true;
    clearTimeout(this.timer);
    this.unresponsive = false;
    clearTimeout(this.unresponsiveTimer);
    this.utilsService.back();
    this.loaderCount = 0;
    this.willDismissAll = false;
  }
}

import { Injector } from '@angular/core';

export let AppServiceInjector: Injector;

export function setAppInjector(injector: Injector) {
  if (AppServiceInjector) {
      // Should not happen
      console.error('Programming error: AppServiceInjector was already set');
  }
  else {
      AppServiceInjector = injector;
  }
}

import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { BaseComponent } from '../base/base.component';

@Component({
  selector: 'app-musing-modal',
  templateUrl: 'musing-modal.component.html',
  styleUrls: [
    './musing-modal.component.scss'
  ]
})

export class MusingModalComponent extends BaseComponent {
  @Input() musingContext: string;

  constructor() {
    super();
  }

  dismiss(): void {
    this.modalController.dismiss();
  }
}

<div scrolly="true" style="max-height: 500px;">
  <ion-list [ngClass]="{'actionList-padding': actions.length > 1,'actionList-noPadding': actions.length < 2}">
    <div *ngIf="titleEnabled" class="popover-title">
      <span class="title">{{title}}</span>
      <hr>
    </div>
    <div *ngFor="let action of actions">
      <div *ngIf="action.isStartOfList" class="actionListDivider">
        <hr>
      </div>
      <ion-item
        class="actionItem"
        [ngClass]="{'actionListHeader': action.isListHeader, 'actionListItem': action.isListItem, 'clauseCategory': action.isClauseCategory, 'clauseItem': action.isClauseItem, 'cursor-pointer': !action.isListHeader && !action.isListItem && !action.isClauseCategory}"
        [lines]="actions.length > 1 ? 'inset': 'none'"
        [disabled]="action.isDisabled"
        (click)="action.isClauseCategory ? '' : actionClicked(action.action)">
        <ion-checkbox *ngIf="action.checkbox" [checked]="action.checkboxIsChecked" slot="start" mode="md"></ion-checkbox>
        <span [innerHTML]="action.textKey | translate"></span>
      </ion-item>
      <div *ngIf="action.isEndOfList" class="actionListDivider">
        <hr>
      </div>
    </div>
  </ion-list>
</div>

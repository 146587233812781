import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../base/base.component';
import { SignInRequest } from '../../models/sign-in-request';
import { StorageKeys } from '../../enums/storage-keys.enum';
import { ApiService } from '../../services/api.service';
import { UserService } from '../../services/user.service';
import { RegisterRequest } from '../../models/register-request';
import { environment } from '../../../environments/environment';
import { AppRoutes } from 'app/enums/app-routes.enum';
import * as moment from 'moment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: [
    './login.component.scss'
  ]
})
export class LoginComponent extends BaseComponent implements OnInit {
  loginForm: FormGroup;
  public appRoutes = AppRoutes;  
  public email: string;
  public alreadyhaveAnAccount = false;

  validation_messages = {
    'email': [
      { type: 'required', message: 'Email is required.' },
      { type: 'pattern', message: 'Enter a valid email.' }
    ],
    'password': [
      { type: 'required', message: 'Password is required.' },
      { type: 'minlength', message: 'Password must be at least 5 characters long.' }
    ]
  };

  constructor(
    public router: Router,
    public modalController: ModalController,
    private userService: UserService,
    private apiService: ApiService
  ) {
    super();
    this.loginForm = new FormGroup({
      'email': new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*$')
      ])),
      'password': new FormControl('', Validators.compose([
        Validators.minLength(5),
        Validators.required
      ]))
    });
  }

  ngOnInit() {
    super.ngOnInit();
    this.subscribeToAccountData();
  }

  doLogin(): void {
    const req = new SignInRequest();
    req.email = this.loginForm.value.email;
    req.password = this.loginForm.value.password;
    this.handleSignIn(req);
  }

  public handleSignIn(req: SignInRequest) {
    this.showLoader();
    this.authService.loginWithPassword(req).pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (res) => {
          this.dismissLoader();
          this.storeJwt(req, res, false);
        },
        error: (error) => {
          if (error.status === 401) {
            this.dismissLoader();
            this.toastService.showError(['Invalid email or password']);
          } else {
            this.handleError(error);
          }
        }
      });
  }

  private subscribeToAccountData() {
    this.utilsService.accountEmail$.pipe(takeUntil(this.destroyed$))
      .subscribe((email) => {
        if (email) {
          this.email = email;
          this.loginForm.controls.email.setValue(email);
          this.alreadyhaveAnAccount = true;
        }
      });
  }

  // store jwt
  private async storeJwt(req, res, termAck) {
    await this.storageService.set(StorageKeys.jwt, res.jwt);
    await this.apiService.setJWT();
    if (res.token) {
      await this.storageService.set(StorageKeys.token, res.token);
    }
    if (res.secret) {
      await this.storageService.set(StorageKeys.secret, res.secret);
    }
    if (res.renewableSecret) {
      await this.storageService.set(StorageKeys.secretRenewable, res.renewableSecret);
    } else {
      await this.storageService.set(StorageKeys.secretRenewable, false);
    }
    if (req.rememberMe) {
      await this.storageService.set(StorageKeys.rememberMe, true);
    } else {
      await this.storageService.set(StorageKeys.rememberMe, false);
    }
    await this.setWhoAmI(termAck, req);
  }

  private async setWhoAmI(termAck: boolean, regReq?: RegisterRequest) {
    this.showLoader();
    this.authService.whoAmI().subscribe((user) => {
      this.dismissLoader();
      if (termAck) {
        this.showLoader();
        user.metadata.agreements.push({ ack: true, version: environment.app.versionAgreements, datetime: moment().utc() });
        this.authService.setLoggedInUser(user).then((activeProfile) => {
          this.userService.put(user).pipe(takeUntil(this.destroyed$)).subscribe({
            next: () => {
              this.dismissLoader();
              this.email = activeProfile.email;
              this.handleRouteEvent(AppRoutes.record);
            }, error: (error) => {
              this.handleError(error);
            }
          });
        });
      } else {
        this.authService.setLoggedInUser(user).then((activeProfile) => {
          this.email = activeProfile.email;
          this.handleRouteEvent(AppRoutes.record);
        });
      }
    }, (error) => {
      this.handleError(error)
    }
    );
  }

  @HostListener('document:keydown.enter')
  public submitFormOnEnterPress() {
    if (this.loginForm.valid) {
      this.doLogin();
    }
  }

  public emailChanged($event) {
    if (!$event?.target?.value) {
      this.utilsService.accountEmail$.next(null);      
    }
  }
}

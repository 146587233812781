import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UserProfile } from '../../models/user-profile';
import { BaseComponent } from '../base/base.component';
import { takeUntil } from 'rxjs/operators';
import { ViewChild } from '@angular/core';
import { ConnectionsService } from '../../services/connections.service';
import { LinkedInConnection, LinkedInConnectionSummary } from './../../models/linkedin-connection';
import { LinkedInLocationResponse } from '../../models/linkedin-location-response';
import { TextPopoverComponent } from '../text-popover/text-popover.component';
import { Util } from '../../util';
import { LinkedInConnectionsResponse } from 'app/models/linkedin-connections-response';


@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss']
})
export class LocationComponent extends BaseComponent implements OnInit, AfterViewInit {
  @Input() profile$ = new BehaviorSubject<UserProfile>(null);
  @Input() openConnectionsModal: (searchTerm?: string, searchType?: string, locationType?: string) => void;
  @Input() shareDisplay$: BehaviorSubject<string>;
  @Input() locations$: BehaviorSubject<Array<LinkedInLocationResponse>>;
  public shareDisplay: string;

  @ViewChild('map') mapElement: any;
  map: google.maps.Map;

  public lastIndex = 0;
  public displayedLocations = [];
  public showMore = true;

  public sortedLocations: Array<LinkedInLocationResponse>;
  public undeterminedPercent: number;

  constructor(private connectionService: ConnectionsService) {
    super();
  }

  ngOnInit() {
    this.showMore = true;
    super.ngOnInit();
    this.subscribeToShareDisplay();
    this.subscribeToLocations();
  }

  ngAfterViewInit() {
    this.initMap();
  }


  private initMap() {
    const mapProperties = {
      center: new google.maps.LatLng(39.8283, -98.5795),
      zoom: 3,
      mapId: 'b41879c2dab043d8',
      zoomControl: false,
      streetViewControl: false,
      fullscreenControl: false,
      mapTypeControl: false,
      backgroundColor: '#f6faf8',
      clickableIcons: false,
      keyboardShortcuts: false,
      disableDefaultUI: true,
      minZoom: 2,
      maxZoom: 10,
      restriction: {
        latLngBounds: {
          north: 80,
          south: -80,
          east: 180,
          west: -180,
        },
      },
    };
    this.map = new google.maps.Map(this.mapElement.nativeElement, mapProperties);
    this.addPins();
  }

  /*
  Subscriptions
  */

  private subscribeToShareDisplay() {
    this.shareDisplay$.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (shareDisplay) => {
        this.shareDisplay = shareDisplay;
      },
      error: (error) => {
        this.handleError(error);
      }
    });
  }

  private subscribeToLocations() {
    this.locations$.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (sharedLocations) => {
        if (sharedLocations && sharedLocations.length > 0) {
          const undetermined = sharedLocations.find((location) => { return location.country === 'Undetermined'; });
          if (undetermined) {
            this.undeterminedPercent = undetermined.percentOfConnections;
          }

          this.sortedLocations = sharedLocations.sort((a, b) => b.numConnections - a.numConnections).filter((location) => { return location.country !== 'Undetermined'; });
          this.lastIndex = 0;
          this.getMoreLocations();
          this.addPins();
        }
      },
      error: (error) => {
        this.handleError(error);
      }
    });
  }

  /*
  Utils
  */

  public getMoreLocations() {
    if (!this.sortedLocations) {
      return;
    }

    this.showMore = true;

    this.lastIndex += 10;
    if (this.lastIndex > this.sortedLocations.length) {
      this.lastIndex = this.sortedLocations.length;
      this.showMore = false;
    }

    this.displayedLocations = this.sortedLocations.slice(0, this.lastIndex);
  }

  public resetLocations(scroll: boolean = false) {
    if (scroll) {
      document.getElementById("location-top").scrollIntoView();
    }
    this.lastIndex = 0;
    this.showMore = true;
    this.getMoreLocations();
  }

  public formatPercent(percent: number): string {
    if (!percent) {
      return 'unknown number';
    }
    return (percent * 100).toFixed(2) + "%";
  }

  public formatLocationName(location: LinkedInLocationResponse): string {
    var name = '';
    if (location.city) {
      name += location.city;
    }

    if (location.country === 'United States') {
      if (location.state && name !== '') {
        name += ', ' + location.state;
      } else {
        name = location.state;
      }
    } else {
      if (location.country && name !== '') {
        name += ', ' + location.country;
      } else {
        name = location.country;
      }
    }

    return name
  }

  public addPins(): void {
    if (this.sortedLocations && this.sortedLocations.length > 0) {
      const orderedLocations = new Array<LinkedInLocationResponse>();
      Object.assign(orderedLocations, this.sortedLocations);
      orderedLocations.sort((a, b) => a.numConnections - b.numConnections);
      for (let i = 0; i < orderedLocations.length; i++) {
        const location = orderedLocations[i];

        const svgMarker = {
          path: "M 0,0 L -43.3,-75 A 50 50 1 1 1 43.30,-75 L 0,0 z",
          fillColor: "#004a43",
          fillOpacity: 0.9,
          strokeWeight: 0,
          rotation: 0,
          scale: 0.25,
          anchor: new google.maps.Point(0, 0),
          labelOrigin: new google.maps.Point(0, -100)
        };

        const numConnections = location.numConnections;
        const label = {text: numConnections.toString(), color: 'white', fontWeight: '500', fontSize: '14px'};
        if (numConnections > 9 && numConnections < 100) {
          label.fontSize = '12px';
        }
        if (numConnections > 99 && numConnections < 1000) {
          label.fontSize = '11px';
        }
        if (numConnections > 999) {
          label.text = this.getMarkerLabelTextLarge(numConnections).text;
          label.fontSize = this.getMarkerLabelTextLarge(numConnections).fontSize;
          label.fontWeight = this.getMarkerLabelTextLarge(numConnections).fontWeight;
        }

        const marker = new google.maps.Marker({
          position: new google.maps.LatLng(location.lat, location.lon),
          title: this.formatLocationName(location) + ' (' + location.numConnections + ')',
          label: label,
          icon: svgMarker
        });

        marker.addListener("click", ($event) => {
          this.clickMarker($event, location);
        });
  
        marker.setMap(this.map);
      };
    }
  }

  private getMarkerLabelTextLarge(numConnections: number): any {
    let fontSize = '10px';
    let fontWeight = '500';
    let text = (numConnections / 1000).toFixed(1).toString() + 'k';
    if (text.endsWith('.0k')) {
      text = text.replace('.0k', 'k');
      fontWeight = '600';
    }
    return {text, fontSize, fontWeight};
  }

  public async clickMarker($event, location: LinkedInLocationResponse) {
    if (this.shareDisplay) {
      this.connectionService.getShared(this.shareDisplay, null, null, location.city, location.state, location.country).subscribe({
        next: (res) => {
          const connections = new Array<LinkedInConnectionSummary>();
          res.rows.forEach((connection) => {
            if (connection.firstName && connection.firstName !== '') {
              connections.push(connection);
            }
          });
      
          connections.sort((a, b) => {
            const fullNameA = a.firstName + ' ' + a.lastName;
            const fullNameB = b.firstName + ' ' + b.lastName;
            return fullNameA.localeCompare(fullNameB);
          });
      
          this.clickMarkerPopover($event, this.getMarkerPopoverHtml(location, connections), location, connections.length > 5 ? true : false);
        },
        error: (error) => {
          this.handleError(error);
        }
      });
    } else {
      this.connectionService.getlatest(null, null, location.city, location.state, location.country).subscribe({
        next: (res) => {
          const connections = new Array<LinkedInConnection>();
          res.rows.forEach((connection) => {
            if (connection.firstName && connection.firstName !== '') {
              connections.push(connection);
            }
          });
      
          connections.sort((a, b) => {
            const fullNameA = a.firstName + ' ' + a.lastName;
            const fullNameB = b.firstName + ' ' + b.lastName;
            return fullNameA.localeCompare(fullNameB);
          });
      
          this.clickMarkerPopover($event, this.getMarkerPopoverHtml(location, connections), location, connections.length > 5 ? true : false);
        },
        error: (error) => {
          this.handleError(error);
        }
      });
    }
  }

  private async clickMarkerPopover($event, html: string, location: LinkedInLocationResponse, showViewAll: boolean) {
    if (this.popover) {
      await this.popover.dismiss();
    }

    this.popover = await this.popoverController.create({
      component: TextPopoverComponent,
      componentProps: {
        text$: Util.getBehaviorSubject(html),
        showViewAll
      },
      event: $event.domEvent,
      showBackdrop: false,
      mode: 'ios',
      cssClass: 'marker-popover'
    });
    await this.popover.present();

    this.popover.onDidDismiss().then((data) => {
      if (data.data) {
        this.openConnModal(location)
      }
    }, (err) => this.handleError(err));
  }

  private getMarkerPopoverHtml(location: LinkedInLocationResponse, connections?: Array<any>): string {
    let html = '';
    if (location) {
      html += `<div class="color-tertiary fw-500 pb-4">${this.formatLocationName(location)} ( ${location.numConnections})</div>`;
    }
    if (connections && connections.length > 0) {
      const max = connections.length > 5 ? 5 : connections.length;
      for (let i = 0; i < max; i++) {
        html += `<div class="clamp-1 color-dark pt-3">${connections[i].firstName} ${connections[i].lastName}<span class="color-medium-shade">, ${connections[i].companyName}</span></div>`
      }
    }
    return html;
  }

  public openConnModal(location: LinkedInLocationResponse): void {
    let searchTerm = '';
    let locationType = '';

    if (location.city) {
      searchTerm = location.city;
      locationType = 'city';
    } else if (location.state) {
      searchTerm = location.state;
      locationType = 'state';
    } else if (location.country) {
      searchTerm = location.country;
      locationType = 'country';
    }

    this.openConnectionsModal(searchTerm, 'location', locationType);
  }
}

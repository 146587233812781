import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UserProfile } from '../../models/user-profile';
import { BaseComponent } from '../base/base.component';
import { ConnectionsService } from '../../services/connections.service';
import { takeUntil } from 'rxjs/operators';
import { SummaryService } from '../../services/summary.service';
import { ShareService } from '../../services/shares.service';
import { Share } from '../../models/share';
import { LinkedInUpload } from '../../models/linkedin-upload';
import { LinkedInProfile } from '../../models/linkedin-profile';
import { AppRoutes } from '../../enums/app-routes.enum';
import { Util } from 'app/util';

@Component({
  selector: 'app-shares-display',
  templateUrl: './shares-display.component.html',
  styleUrls: ['./shares-display.component.scss']
})
export class SharesDisplayComponent extends BaseComponent implements OnInit {
  @Input() profile: UserProfile;
  @Output() routeEvent = new EventEmitter<AppRoutes>();
  public appRoutes = AppRoutes;
  public util = Util;

  public shares = new Array<Share>();
  public createModalShowing = false;
  public latestUpload: LinkedInUpload;
  public latestProfile: LinkedInProfile;
  public detailModalShowing = false;
  public detailShare: Share;


  constructor(private summaryService: SummaryService, private shareService: ShareService,
    private connectionService: ConnectionsService) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.setup();
  }

  private setup() {
    this.getShares();
    this.getLatestUpload();
    this.getLatestProfile();
  }

  /*
  API
  */

  private getShares(event$?: any, goToDetailForId?: string) {
    this.shareService.getShares().subscribe({
      next: (shares) => {
        this.shares = shares.sort((a, b) => {
          return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
        });
        if (event$) {
          event$.target.complete();
        }
        if (goToDetailForId) {
          this.toastService.showSuccess('Your new record share was successfully created.', null, {position: 'top'});
          const share = this.shares.find((s) => s.id === goToDetailForId);
          if (share) {
            this.showDetailModal(share);
          }
        }
      },
      error: (error) => {
        this.handleError(error);
      }
    });
  }

  private getLatestUpload()  {
    this.connectionService.getLatestUpload([]).subscribe({
      next: (upload) => {
        this.latestUpload = upload;
      }, error: (error) => {
        this.handleError(error);
      }
    });
  }

  private getLatestProfile() {
    this.connectionService.getLatestProfile([]).subscribe({
      next: (profile) => {
        this.latestProfile = profile;
      }, error: (error) => {
        this.handleError(error);
      }
    });
  }

  /*
  Utils
  */

  public refresh($event) {
    this.getShares($event);
    this.getLatestUpload();
    this.getLatestProfile();
  }

  public showCreateModal() {
    this.createModalShowing = true;
  }

  public closeCreateModal(id: string) {
    this.createModalShowing = false;
    this.getShares(null, id);
  }

  public showDetailModal(share: Share) {
    this.shareService.getShare(share.id, ['shareView']).subscribe({
      next: (share) => {
        share.shareViews.sort((a, b) => { return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime() });
        this.detailShare = share;
        this.detailModalShowing = true;
      }, error: (error) => {
        this.handleError(error);
      }
    });
  }

  public closeDetailModal() {
    this.detailModalShowing = false;
    this.getShares();
  }

  handleRouteEvent(route: AppRoutes) {
    this.routeEvent.emit(route);
  }
}

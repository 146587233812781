import { UploadStatus } from "../enums/upload-status"
import { LinkedInConnection } from "./linkedin-connection"
import { LinkedInMessage } from "./linkedin-message"
import { LinkedInProfile } from "./linkedin-profile"
import { UserProfile } from "./user-profile"

export class LinkedInUpload {
  public id: string
  public createdAt: string
  public updatedAt: string
  public deletedAt: any
  public instanceVersion: number
  public createdBy: string
  public updatedBy: string
  public metadata: any
  public type: string
  public uploadVersion: number
  public connectionErrors: any[]
  public messageErrors: any[]
  public profileId: string
  public documentId: string
  public profile: UserProfile
  public document: any
  public linkedInProfile: LinkedInProfile
  public linkedInMessages: LinkedInMessage[]
  public linkedInConnections: LinkedInConnection[]
  public uploadStatus: UploadStatus

  constructor(obj?: any) {    
    Object.assign(this, obj);

    if (obj.profile) {
      this.profile = new UserProfile(obj.profile)
    }

    if (obj.linkedInProfile) {
      this.linkedInProfile = new LinkedInProfile(obj.linkedInProfile)
    }

    if (obj.linkedInMessages) {
      this.linkedInMessages = new Array<LinkedInMessage>();
      obj.linkedInMessages.forEach(lm => {
        this.linkedInMessages.push(new LinkedInMessage(lm));
      });
    }

    if (obj.linkedInConnections) {
      this.linkedInConnections = new Array<LinkedInConnection>();
      obj.linkedInConnections.forEach(lc => {
        this.linkedInConnections.push(new LinkedInConnection(lc));
      });
    }
  }
}
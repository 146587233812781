import { LegalAddress } from './legal-address';

export class ConsumerLegalInformation {
  public fullName: string;
  public address: LegalAddress;
  public phone: string;
  public fax: string;
  public email: string;

  constructor(obj?: any) {
    Object.assign(this, obj);
    this.address = this.address ? this.address : new LegalAddress();
  }

  get isValid() {
    return this.fullName && this.email;
    // return this.fullName && this.phone && this.email && new LegalAddress(this.address).isValid;
  }
}

import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../base/base.component';
import { RegisterRequest } from '../../models/register-request';
import { PasswordValidator } from '../../validators/password.validator';
import { StorageKeys } from '../../enums/storage-keys.enum';
import { AppRoutes } from '../../enums/app-routes.enum';
import { UserService } from '../../services/user.service';
import { ApiService } from '../../services/api.service';
import { UserProfile } from '../../models/user-profile';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: [
    './signup.component.scss'
  ]
})
export class SignupComponent extends BaseComponent implements OnInit {
  @Output() routeEvent = new EventEmitter<AppRoutes>();
  signupForm: FormGroup;
  matching_passwords_group: FormGroup;
  public appRoutes = AppRoutes;
  public haveDeets = false;
  private registerReq = new RegisterRequest();
  private userProfile: UserProfile;

  validation_messages = {
    'email': [
      { type: 'required', message: 'Email is required.' },
      { type: 'pattern', message: 'Enter a valid email.' }
    ],
    'url': [
      { type: 'required', message: 'LinkedIn url is required.' },
      { type: 'pattern', message: 'Enter a valid LinkedIn url.' }
    ],
    'firstName': [
      { type: 'required', message: 'First name is required.' },
    ],
    'lastName': [
      { type: 'required', message: 'Last name is required.' },
    ],
    'password': [
      { type: 'required', message: 'Password is required.' },
      { type: 'minlength', message: 'Password must be at least 5 characters long.' }
    ],
    'confirm_password': [
      { type: 'required', message: 'Confirm password is required' }
    ],
    'matching_passwords': [
      { type: 'areNotEqual', message: 'Password mismatch' }
    ]
  };

  constructor(
    public router: Router,
    public modalController: ModalController,
    private userService: UserService,
    private apiService: ApiService
  ) {
    super();
    this.matching_passwords_group = new FormGroup({
      'password': new FormControl('', Validators.compose([
        Validators.minLength(5),
        Validators.required
      ])),
      'confirm_password': new FormControl('', Validators.required)
    }, (formGroup: FormGroup) => {
      return PasswordValidator.areNotEqual(formGroup);
    });

    this.signupForm = new FormGroup({
      'firstName': new FormControl('', Validators.compose([
        Validators.required
      ])),
      'lastName': new FormControl('', Validators.compose([
        Validators.required
      ])),
      'url': new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('^(https?:\/\/)?(www\.)?linkedin\.com\/in\/.+\/?$')
      ])),
      'email': new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*$')
      ])),
      'matching_passwords': this.matching_passwords_group
    });
  }

  ngOnInit() {
    super.ngOnInit();
    this.subscribeToAccountData();
  }

  private subscribeToAccountData() {
    combineLatest([this.utilsService.accountEmail$, this.utilsService.accountFirstName$, this.utilsService.accountLastName$]).pipe(takeUntil(this.destroyed$))
      .subscribe(([email, firstName, lastName]) => {
        if (email) {
          this.haveDeets = true;
          this.signupForm.controls.email.setValue(email);
        }
        if (firstName) {
        this.signupForm.controls.firstName.setValue(firstName);
        }
        if (lastName) {
        this.signupForm.controls.lastName.setValue(lastName);
        }
      });
  }

  doSignup(): void {
    this.showLoader();
    this.authService.prepForNewLogin().finally(() => {
      this.userService.exists(this.signupForm.controls.email.value).subscribe({
          next: (res) => {
            if (res.exists && res.profiles[0].id !== "") {
              // already exists
              this.dismissLoader();
              this.utilsService.accountEmail$.next(this.signupForm.controls.email.value);
              this.routeEvent.emit(AppRoutes.login);
            } else {
              this.userProfile = res.profiles[0];
              this.registerReq.email = this.signupForm.controls.email.value;
              this.registerReq.password = this.signupForm.controls.matching_passwords.value.password;
              this.registerReq.displayName = this.signupForm.controls.firstName.value;
              this.registerReq.linkedInProfileUrl = this.signupForm.controls.url.value;
              this.registerReq.firstName = this.signupForm.controls.firstName.value;
              this.registerReq.lastName = this.signupForm.controls.lastName.value;

              this.authService.register(this.registerReq, this.userProfile.userId, this.userProfile.id).pipe(takeUntil(this.destroyed$))
                .subscribe({
                  next: (resp) => {
                    this.dismissLoader();
                    this.storeJwt(this.registerReq, resp, this.registerReq.termAck);
                  },
                  error: (error) => {
                    this.dismissLoader();
                    this.handleError(error)
                  }
                });
            }
          },
          error: (error) => {
            this.handleError(error);
            this.dismissLoader();
          }
        }
        );
    });
  }

  // store jwt
  private async storeJwt(req, res, termAck) {
    await this.storageService.set(StorageKeys.jwt, res.jwt);
    await this.apiService.setJWT();
    if (res.token) {
      await this.storageService.set(StorageKeys.token, res.token);
    }
    if (res.secret) {
      await this.storageService.set(StorageKeys.secret, res.secret);
    }
    if (res.renewableSecret) {
      await this.storageService.set(StorageKeys.secretRenewable, res.renewableSecret);
    } else {
      await this.storageService.set(StorageKeys.secretRenewable, false);
    }
    if (req.rememberMe) {
      await this.storageService.set(StorageKeys.rememberMe, true);
    } else {
      await this.storageService.set(StorageKeys.rememberMe, false);
    }
    await this.setWhoAmI();
  }

  private async setWhoAmI() {
    this.showLoader();
    this.authService.whoAmI().subscribe({
      next: (user) => {
        this.dismissLoader();
        this.authService.setLoggedInUser(user).then((activeProfile) => {
          this.utilsService.handleNewUpload$.next(true);
          this.routeEvent.emit(AppRoutes.record);
        });
      },
      error: (error) => {
        this.handleError(error)
        this.dismissLoader();
      }
    });
  }

  handleRouteEvent(route: AppRoutes) {
    this.routeEvent.emit(route);
  }

  @HostListener('document:keydown.enter')
  public submitFormOnEnterPress() {
    if (this.signupForm.valid) {
      this.doSignup();
    }
  }
}

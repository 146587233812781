import { Injectable } from '@angular/core';
import * as translationsJson from '../../assets/translations/translations.en.json';
import { environment } from '../../environments/environment';

type ExtractPaths<T> = T extends string ? [] : {
  [K in Extract<keyof T, string>]: [K, ...ExtractPaths<T[K]>]
}[Extract<keyof T, string>];

type Join<T extends string[], D extends string> =
    T extends [] ? never :
        T extends [infer F] ? F :
            T extends [infer G, ...infer R] ?
                G extends string ?
                    `${G}${D}${Join<Extract<R, string[]>, D>}` : never : string;

export type TypedTranslationPath = Join<ExtractPaths<typeof translationsJson>, '.'>


@Injectable({
  providedIn: 'root'
})
export class TranslateService {
  private translations: any;

  constructor() {
    this.setTranslations();
  }

  // a typesafe version of TranslateService.get(string)
  public getTypedPath(key: TypedTranslationPath) {
    return this.get(key);
  }

  public get(key: string): string {
    if (environment.app.showTranslationKeys) {
      return key;
    }
    try {
      const keys = key.split('.');
      let needle = this.translations;
      keys.forEach((k) => {
        needle = needle[k];
      });
      return needle || key;
    } catch (error) {
      return key;
    }
  }

  private setTranslations(lang?: string) {
    this.translations = (translationsJson as any).default;
  }
}

<div class="dash-section-container">
  <h1>Locations Reached</h1>
  <div class="visual-border" #map style="width:100%;height:40vh"></div>

  <app-musings [musingContext]="'location'" [shareDisplay$]="shareDisplay$"></app-musings>

  <h2 id="location-top">Top Locations</h2>
  <ion-list>
    <ion-item *ngFor="let loc of displayedLocations; let i = index" (click)="openConnModal(loc)" class="cursor-pointer">
      <ion-row class="ion-align-items-center width-full">
        <ion-col>
          <div class="flex-row align-center">
            <div class="item-text-number">{{ i + 1 }}.</div>
            <div class="item-text clamp-2" [innerHTML]="formatLocationName(loc)"></div>
          </div>
        </ion-col>
        <ion-col *ngIf="!shareDisplay" size="auto" class="rank-text-percentage" [innerHTML]="formatPercent(loc.percentOfConnections)"></ion-col>
        <ion-col size="auto" class="rank-text">{{ loc.numConnections }}</ion-col>
      </ion-row>
    </ion-item>
    <ion-item lines="none" *ngIf="showMore || displayedLocations?.length > 10">
      <div *ngIf="showMore" class="more-button" (click)="getMoreLocations()">More</div>
      <ion-button *ngIf="displayedLocations?.length > 10" (click)="resetLocations(true)" color="medium" fill="outline">
        <ion-icon name="chevron-up-outline"></ion-icon>
      </ion-button>
    </ion-item>
  </ion-list>

  <div *ngIf="isBigWig && showBigWigOnDashboard" class="bigwig-stuff">
    <div class="title">Bigwig Stuff</div>
    <p>{{ formatPercent(undeterminedPercent) }} undetermined</p>
  </div>
</div>

<ng-container [ngSwitch]="messageContext" >

  <div *ngSwitchCase="'zeroUploads'" class="message-container">
    <ion-icon size="large" color="primary" name="alert-circle-outline"></ion-icon>
    <h3 class="ion-text-center">You have not yet uploaded a LinkedIn data archive</h3>
    <ion-button color="primary"  fill="solid" (click)="emitAction('upload')">
      Upload Archive
    </ion-button>
  </div>

  <div *ngSwitchCase="'processing'" class="height-full flex-column justify-space-between align-center p-10">
    <div class="width-full flex-column align-center">
      <h3 *ngIf="actionDisabled" class="ion-text-center">Your latest LinkedIn data archive is still processing</h3>
      <h4 *ngIf="!actionDisabled" class="ion-text-center">Processing completed. Click below to view your record.</h4>
      <div class="width-80">
        <div class="subhead py-10 ion-text-center">We’re building your yeahshare record and it usually takes two minutes or more.</div>
        <div class="subhead py-10 ion-text-center">But get excited because you’ll soon receive a shareable yeashare record snapshotting the who, where, when, what of your professional reach.</div>
        <div class="subhead py-10 ion-text-center">Use yeahshare to more quickly network toward a new job, a new venture, a new sale, or a new investment.</div>
      </div>
    </div>
    <div class="width-full flex-column align-center">
      <div class="ion-padding-bottom w-100 h-35"><span *ngIf="actionDisabled">Please wait{{animated3dot}}</span></div>
      <ion-button color="primary" fill="solid" (click)="emitAction('dismiss')" [disabled]="actionDisabled">
        View my record
      </ion-button>
    </div>
    <div></div>
  </div>
  
  <div *ngSwitchCase="'latestFailed'" class="message-container">
    <ion-icon size="large" color="primary" name="alert-circle-outline"></ion-icon>
    <h3 class="ion-text-center">Your latest data upload failed</h3>
    <div class="width-60">
      <div class="subhead py-10 ion-text-center">Something went wrong. We failed to generate your yeahshare record.</div>
      <div class="subhead py-10 ion-text-center">Please try uploading your LinkedIn Data Archive .zip file again.</div>
      <div class="subhead py-10 ion-text-center">If it won’t work, please confirm you’ve exported your data from LinkedIn using yeahshare instructions.</div>
    </div>
    <ion-button color="primary"  fill="solid" (click)="emitAction('uploadNew')">
      Continue
    </ion-button>
  </div>

</ng-container>

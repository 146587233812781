import { AppServiceInjector } from '../../services/service-injector.service';
import { User } from '../../models/user';
import { UserProfile } from '../../models/user-profile';
import { AuthService } from '../../services/auth.service';
import { UtilsService } from '../../services/utils.service';
import { Component, EventEmitter, OnInit, OnDestroy, Output } from '@angular/core';
import { Subject, combineLatest, fromEvent } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Logger } from '../../services/logger.service';
import { Util } from '../../util';
import { RouteBasedStructure } from '../../models/route-based-structure';
import { RouteBasedStructureService } from '../../services/route-based-structure.service';
import { ModalController, NavController, PopoverController } from '@ionic/angular';
import { Loader } from '../../models/loader';
import { LoadingService } from '../../services/loading.service';
import { TextPopoverComponent } from '../text-popover/text-popover.component';
import { AppRoutes } from '../../enums/app-routes.enum';
import { StorageService } from '../../services/storage.service';
import { ToastService } from './../../services/toast.service';
import { TermsOfServiceComponent } from '../terms-of-service/terms-of-service.component';
import { PrivacyPolicyComponent } from '../privacy-policy/privacy-policy.component';
import { FaqComponent } from '../faq/faq.component';
import { ContactUsComponent } from '../contact-us/contact-us.component';
import { environment } from '../../../environments/environment';

@Component({
  template: ''
})

export class BaseComponent implements OnInit, OnDestroy {
  @Output() errorEvent = new EventEmitter<any>();
  @Output() routeEvent = new EventEmitter<AppRoutes>();
  public util = Util;
  public authService: AuthService;
  public utilsService: UtilsService;
  public toastService: ToastService;
  public storageService: StorageService;
  public destroyed$ = new Subject();
  public activeUser = new User();
  public activeProfile = new UserProfile();
  public isLargeView: boolean;
  public screenWidthIsLargeView: boolean;
  public isMobileWeb: boolean;
  public isNavOpen: boolean;
  public isNative: boolean;
  public isBusiness: boolean;
  public isConsumer: boolean;
  public routeBasedStructureService: RouteBasedStructureService;
  public routeBasedStructure = new RouteBasedStructure();
  public logger: Logger;
  public debug: string;
  public popover: HTMLIonPopoverElement;
  public popoverController: PopoverController;
  public isBigWig = false;
  public showBigWigOnDashboard = false;
  public modal: HTMLIonModalElement;
  protected modalController: ModalController;
  protected navController: NavController;
  protected loadingService: LoadingService;

  constructor() {
    const injector = AppServiceInjector;
    this.authService = injector.get(AuthService);
    this.utilsService = injector.get(UtilsService);
    this.loadingService = injector.get(LoadingService);
    this.storageService = injector.get(StorageService);
    this.toastService = injector.get(ToastService);
    this.navController = injector.get(NavController);
    this.modalController = injector.get(ModalController);
    this.popoverController = injector.get(PopoverController);
    this.logger = injector.get(Logger);
    this.routeBasedStructureService = injector.get(RouteBasedStructureService);
  }

  ngOnInit() {
    this.setIsLargeView();
    this.subscribeIsNative();
    this.subscribeAuthInfo();
    this.subscribeToStructure();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
    this.destroyed$.unsubscribe();
  }

  private setIsLargeView() {
    this.isMobileWeb = this.utilsService.isMobileWeb();
    this.isLargeView = this.utilsService.isLargeView();
    this.screenWidthIsLargeView = window.innerWidth >= environment.settings.largeViewSize;
  }

  private subscribeIsNative() {
    this.utilsService.isNative$.pipe(takeUntil(this.destroyed$))
      .subscribe((isNative) => {
        this.isNative = isNative;
      });
  }

  private subscribeAuthInfo() {
    combineLatest([this.utilsService.isAuthenticated$, this.authService.user$, this.authService.currentProfile$]).pipe(takeUntil(this.destroyed$))
      .subscribe(([isAuthenticated, user, currentProfile]) => {
        if (isAuthenticated && user && currentProfile) {
          this.activeUser = user;
          this.activeProfile = currentProfile;
          this.activeProfile.user = user;

          if (this.activeProfile.isBigWig) {
            this.isBigWig = true;
          }

          this.showBigWigOnDashboard = environment.settings.showBigWigOnDashboard;
        }
      });
  }

  // footer
  public async showCardModal(type: string) {
    if (this.modal) {
      await this.modal.dismiss();
    }

    const routerOutlet =  await this.modalController.getTop() || document.getElementById('ion-router-outlet-content');
    switch (type) {
      case 'terms':
        this.modal = await this.modalController.create({
          swipeToClose: true,
          presentingElement: routerOutlet,
          component: TermsOfServiceComponent,
          cssClass: this.isLargeView ? 'modal-tall' : ''
        });
        break;
      case 'privacy':
        this.modal = await this.modalController.create({
          swipeToClose: true,
          presentingElement: routerOutlet,
          component: PrivacyPolicyComponent,
          cssClass: this.isLargeView ? 'modal-tall' : ''
        });
        break;
      case 'faq':
        this.modal = await this.modalController.create({
          swipeToClose: true,
          presentingElement: routerOutlet,
          component: FaqComponent,
          cssClass: this.isLargeView ? 'modal-normal' : ''
        });
        break;
      case 'contact':
        this.modal = await this.modalController.create({
          swipeToClose: true,
          presentingElement: routerOutlet,
          component: ContactUsComponent,
          cssClass: this.isLargeView ? 'modal-normal' : ''
        });
        break;
    } 

    return await this.modal.present();
  }

  // routing
  private subscribeToStructure() {
    this.routeBasedStructureService.routeBasedStructure$.pipe(takeUntil(this.destroyed$))
      .subscribe((structure) => {
        this.routeBasedStructure = new RouteBasedStructure(structure);
      });

    fromEvent(window, 'resize').subscribe((event) => {
      this.onResizeComponent(event);
    });
  }

  public handleBackClickEvent($event) {
    this.utilsService.back();
  }

  // errors
  public handleError(error: any, back?: boolean, route?: string, display?: boolean) {
    const appError = Util.processError(error, back, route, display);
    this.errorEvent.emit(appError);
  }

  // info popover
  public async handleInfoPopoverClick(event: any, text: string, alignment?: string) {
    if (this.popover) {
      await this.popover.dismiss();
    }

    var positionAlignment: any;
    if (alignment) {
      positionAlignment = alignment
    }

    this.popover = await this.popoverController.create({
      component: TextPopoverComponent,
      componentProps: {
        text$: this.util.getBehaviorSubject(text)
      },
      event,
      showBackdrop: false,
      alignment: positionAlignment,
      cssClass: 'popover-text',
      dismissOnSelect: false
    });
    await this.popover.present();
  }

    // loader
  public dismissLoader(dismissAll?: boolean) {
    this.loadingService.loader$.next(new Loader(false, true, dismissAll));
  }

  public showLoader(willDissmissAll?: boolean) {
    this.loadingService.loader$.next(new Loader(true, false, willDissmissAll));
  }

  // resize
  private onResizeComponent(event) {
    const func = 'onResize';
    this.setIsLargeView();
  }

  // routing
  handleRouteEvent(route: AppRoutes) {
    this.routeEvent.emit(route);
  }
}

import { LinkedInMessage } from "./linkedin-message"
import { LinkedInProfile } from "./linkedin-profile"
import { LinkedInUpload } from "./linkedin-upload"
import { UserProfile } from "./user-profile"

export class LinkedInConnection {
  public id: string
  public createdAt: string
  public updatedAt: string
  public deletedAt: any
  public instanceVersion: number
  public createdBy: string
  public updatedBy: string
  public metadata: any
  public firstName: string
  public lastName: string
  public emailAddress: string
  public companyName: string
  public position: string
  public connectedOn: string
  public locationId: string
  public location: LinkedInLocation
  public excluded: boolean
  public linkedInUploadId: string
  public profileId: string
  public linkedInProfileId: string
  public profile: UserProfile
  public linkedInProfile: LinkedInProfile
  public linkedInMessages: LinkedInMessage[]
  public linkedInUpload: LinkedInUpload

  constructor(obj?: any) {
    Object.assign(this, obj);
    this.connectedOn = new Date(this.connectedOn).toLocaleDateString();

    if (obj.profile) {
      this.profile = new UserProfile(obj.profile)
    }

    if (obj.linkedInProfile) {
      this.linkedInProfile = new LinkedInProfile(obj.linkedInProfile)
    }

    if (!obj.linkedInMessages) {
      this.linkedInMessages = new Array<LinkedInMessage>();
      obj.linkedInMessages?.forEach(lm => {
        this.linkedInMessages.push(new LinkedInMessage(lm));
      });
    }

    if (obj.linkedInUpload) {
      this.linkedInUpload = new LinkedInUpload(obj.linkedInUpload)
    }

    if (obj.location) {
      this.location = new LinkedInLocation(obj.location)
    }
  }
}

export class LinkedInConnectionSummary {
  public firstName: string
  public lastName: string
  public companyName: string
  public position: string
  public location: LinkedInLocation

  constructor(obj?: any) {
    Object.assign(this, obj);

    if (obj.location) {
      this.location = new LinkedInLocation(obj.location)
    }
  }
}

export class LinkedInLocation {
  public id: string;
  public createdAt: string;
  public updatedAt: string;
  public deletedAt: any;
  public instanceVersion: number;
  public createdBy: string;
  public updatedBy: string;
  public metadata: any;

  public propertyAddress: string;
  public street1: string;
  public street2: string;
  public city: string;
  public county: string;
  public state: string;
  public zip: string;
  public country: string;
  public googlePlaceId: string;
  public lat: number;
  public long: number;
  public multipleResponses: boolean;
  public exclude: boolean;
  public unresolved: boolean;

  constructor(obj?: any) {
    Object.assign(this, obj);
  }
}

import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';
import { StorageKeys } from '../enums/storage-keys.enum';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  public source = 'StorageService';
  private storage = Preferences;

  constructor() {
  }

  public async set(key: StorageKeys, value: any) {
    await this.storage.set({ key, value: JSON.stringify(value) });
  }

  public async get(key: StorageKeys) {
    const obj = await this.storage.get({ key });
    return JSON.parse(obj.value);
  }

  public async remove(key: StorageKeys) {
    await this.storage.remove({ key });
  }

  public async getKeys() {
    return await this.storage.keys();
  }
}

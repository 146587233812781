import { LinkedInProfile } from "./linkedin-profile"
import { LinkedInUpload } from "./linkedin-upload"

export class Share {
  public id: string
  public createdAt: string
  public updatedAt: string
  public deletedAt: any
  public instanceVersion: number
  public createdBy: string
  public updatedBy: string
  public metadata: any

  public sharedByEmail: string;
  public sharedByName: string;
	public sharedWithEmail: string;
  public sharedWithName: string;
  public sharedWithEmailMessage: string;
  public sendEmail: boolean;
	public opened:     number;
	public clicked:    number;
	public viewed:     number;
	public downloaded: number;
	public linkedInUploadId:  string;
	public linkedInProfileId: string;
	public sharedByProfileId: string;
	public linkedInUpload:  LinkedInUpload;
	public linkedInProfile: LinkedInProfile;

  public locationShared:  boolean;
	public locationTop:     number;
	public messagesShared:  boolean;
	public messagesTop:     number;
	public companiesShared: boolean;
	public companiesTop:    number;
	public networkShared:   boolean;
	public networkTop:      number;

  public preview: boolean;

  public shareViews: Array<ShareView>;

  constructor(obj?: any) {
    Object.assign(this, obj);
    this.shareViews = this.shareViews ? this.shareViews.map((sv) => new ShareView(sv)) : [];

    if (obj?.linkedInUpload) {
      this.linkedInUpload = new LinkedInUpload(obj.linkedInUpload)
    }

    if (obj?.linkedInProfile) {
      this.linkedInProfile = new LinkedInProfile(obj.linkedInProfile)
    }
  }
}

export class ShareView {
  public id: string
  public createdAt: string
  public updatedAt: string
  public deletedAt: any
  public instanceVersion: number
  public createdBy: string
  public updatedBy: string
  public metadata: any

	public sharedWithEmail: string;

  public opened:     number;
	public clicked:    number;
	public viewed:     number;
	public downloaded: number;

  public locationShared:  boolean;
	public locationTop:     number;
	public messagesShared:  boolean;
	public messagesTop:     number;
	public companiesShared: boolean;
	public companiesTop:    number;
	public networkShared:   boolean;
	public networkTop:      number;  

  constructor(obj?: any) {
    Object.assign(this, obj);
  }
}
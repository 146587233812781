import {Component, Input, OnInit} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {PopoverController} from '@ionic/angular';
import { IonicModule } from '@ionic/angular';

@Component({
  selector: 'app-text-popover',
  templateUrl: './text-popover.component.html',
  styleUrls: ['./text-popover.component.scss'],
})
export class TextPopoverComponent implements OnInit {
  @Input() text$ = new BehaviorSubject<string>(null);
  @Input() showViewAll: boolean;
  @Input() showClose: boolean;
  public text = null;

  constructor(public popoverController: PopoverController) {
  }

  ngOnInit() {
    this.subscribeToText();
  }

  public dismiss() {
    this.popoverController.dismiss();
  }

  public dismissViewAll() {
    this.popoverController.dismiss({viewAll: true});
  }

  private subscribeToText() {
    this.text$.subscribe((text) => {
      this.text = text;
    });
  }

}

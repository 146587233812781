import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TranslateService } from '../services/translate.service';

@Pipe({
  name: 'translate'
})
export class TranslatePipe implements PipeTransform {

  constructor(private translateService: TranslateService, private sanitizer: DomSanitizer) {
  }

  transform(key: string, shouldSanitize = true): SafeHtml | string {
    if (shouldSanitize) {
      return this.sanitizer.sanitize(SecurityContext.HTML, this.translateService.get(key));
    } else {
      this.translateService.get(key);
    }
  }

}

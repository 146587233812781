import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiUrls } from '../enums/api-urls.enum';
import { ApiService } from './api.service';
import { Share } from '../models/share';
import { ShareSummary } from '../models/share-summary';

@Injectable({
  providedIn: 'root'
})
export class ShareService {
  private source = 'ShareService';

  constructor(private apiService: ApiService) {
  }

  public getShares(): Observable<Array<Share>> {
    const func = 'get';

    return this.apiService.get(this.source, func, `${ApiUrls.share}`)
      .pipe(map((res) => {
        return res.map((r) => new Share(r));
      }, (error) => {
        return error;
      }));
  }

  public getShare(shareId: string, expand?: Array<string>): Observable<Share> {
    const func = 'get';

    const params = this.apiService.expandParams(expand);

    return this.apiService.get(this.source, func, `${ApiUrls.share}/${shareId}${params}`)
      .pipe(map((res) => {
        return new Share(res);
      }, (error) => {
        return error;
      }));
  }

  public createShare(share: Share): Observable<Share> {
    const func = 'create';

    return this.apiService.post(this.source, func, `${ApiUrls.share}`, share)
      .pipe(map((res) => {
        return new Share(res);
      }, (error) => {
        return error;
      }));
  }

  public updateShare(share: Share): Observable<Share> {
    const func = 'update';

    return this.apiService.put(this.source, func, `${ApiUrls.share}/${share.id}`, share)
      .pipe(map((res) => {
        return new Share(res);
      }, (error) => {
        return error;
      }));
  }

  public getShareSummary(shareId: string, email: string): Observable<ShareSummary> {
    const func = 'get';

    return this.apiService.get(this.source, func, `${ApiUrls.summary}/share/${shareId}?email=${email}`)
      .pipe(map((res) => {
        return new ShareSummary(res);
      }, (error) => {
        return error;
      }));
  }

  public deleteShare(shareId: string): Observable<any> {
    const func = 'delete';

    return this.apiService.delete(this.source, func, `${ApiUrls.share}/${shareId}`)
      .pipe(map((res) => {
        return
      }, (error) => {
        return error;
      }));
  }

  public incrementShareViewed(shareId: string, email: string): Observable<Share> {
    const func = 'increment';

    return this.apiService.put(this.source, func, `${ApiUrls.share}/${shareId}/view?email=${email}`, null)
      .pipe(map((res) => {
        return new Share(res);
      }, (error) => {
        return error;
      }));
  }
}

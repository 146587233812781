<ion-header>
  <ion-toolbar color="primary" ion-toolbar mode="md">
    <ion-title>My Connections</ion-title>
    <ion-buttons slot="end">
      <ion-button color="primay" fill="solid" type="button" color="primary" fill="solid" (click)="closeThisModal()">
        Close
        <ion-icon slot="end" name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <ion-toolbar>
    <ion-searchbar [debounce]="500" (ionChange)="handleSearchChange($event)" [(ngModel)]="searchValue"></ion-searchbar>
  </ion-toolbar>
</ion-header>

<ion-content style="height: calc(100% - 112px)">
  <ion-list>
    <ion-item *ngFor="let connection of displayedConnections" class="py-10 cursor-pointer" (click)="showModal(connection)">
      <div style="display: flex; flex-direction: column;">
        <p style="margin: 0; font-size: medium;">{{ connection.firstName }} {{ connection.lastName }}</p>
        <p style="margin: 5px 0px; font-size: small; color: grey;" [innerHTML]="util.getPosAndCompanyText(connection)"></p>
      </div>
      <ion-icon slot="end" color="primary" name="information-circle-outline"></ion-icon>
    </ion-item>
  </ion-list>
  <div *ngIf="showMore" (click)="getMoreConnections()" class="more-button">More</div>
</ion-content>

<ion-modal [isOpen]="modalShowing" (didDismiss)="modalShowing=false" [ngClass]="{'modal-tall': isLargeView}">
  <ng-template>
    <ion-header>
      <ion-toolbar color="primary" ion-toolbar mode="md">
        <ion-title>Connection Details</ion-title>
        <ion-buttons slot="end">
          <ion-button color="primay" fill="solid" type="button" color="primary" fill="solid" (click)="closeModal()">
            Close
            <ion-icon slot="end" name="close-outline"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
      <div class="basic-connection-info">
        <h2 class="detail-section-header">Summary</h2>
        <div class="fs-14 color-medium-shade italic">Included in shared record.</div>
        <ion-item class="connection-detail-info-line">
          <ion-label>Name</ion-label>
          <span>{{ detailedConnection?.firstName }} {{ detailedConnection?.lastName }}</span>
        </ion-item>
        <ion-item class="connection-detail-info-line">
          <ion-label>Position</ion-label>
          <span>{{ detailedConnection?.position }}</span>
        </ion-item>
        <ion-item class="connection-detail-info-line">
          <ion-label>Company</ion-label>
          <span>{{ detailedConnection?.companyName }}</span>
        </ion-item>
        <ion-item class="connection-detail-info-line">
          <ion-label>Location</ion-label>
          <span  [innerHTML]="util.getLocation(detailedConnection) | safeHtml"></span>
        </ion-item>
        <h2 class="detail-section-header">Detail</h2>
        <div class="fs-14 color-medium-shade italic">Exluded from shared record.</div>
        <ion-item class="connection-detail-info-line">
          <ion-label>Connected on</ion-label>
          <span [innerHTML]="util.getDate(detailedConnection?.connectedOn)"></span>
        </ion-item>
        <ion-item *ngIf="detailedConnection?.email" class="connection-detail-info-line">
          <ion-label>Email</ion-label>
          <span>{{ detailedConnection?.email }}</span>
        </ion-item>
      </div>
      <h2 class="detail-section-header">Messages</h2>
      <div class="fs-14 color-medium-shade italic">Exluded from shared record.</div>
      <p class="detail-section-header" *ngIf="!detailedConnection?.linkedInMessages || detailedConnection?.linkedInMessages?.length === 0">
        There are no messages to/from {{ detailedConnection?.firstName }} {{ detailedConnection?.lastName }} in this record.
      </p>
      <ion-list>
        <div *ngFor="let message of detailedConnection?.linkedInMessages" lines="none"  class="ion-no-padding flex-column my-8" [ngClass]="{'align-end': message.sentByMe, 'align-start': !message.sentByMe}">
          <div class="ion-padding flex-column width-80" [ngClass]="{'message-me': message.sentByMe, 'message-other': !message.sentByMe}">
            <div class="flex-row justify-space-between py-8 message-heading">
              <div>{{ message.from }}</div>
              <div [innerHTML]="util.getDateAndTime(message.date, true)"></div> 
            </div>
            <div>
              {{ message.content }}
            </div>
          </div>
        </div>
      </ion-list>
    </ion-content>
  </ng-template>
</ion-modal>
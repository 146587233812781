<ion-header>
  <ion-toolbar color="primary" ion-toolbar mode="md">
    <ion-buttons *ngIf="!isLargeView" slot="start">
      <ion-menu-button menu="none" autoHide="false" color="light" (click)="handleRouteEvent(appRoutes.back)">
        <ion-icon class="fs-24" name="arrow-back-outline"></ion-icon>
      </ion-menu-button>
    </ion-buttons>
    <ion-title>Sign Up</ion-title>
    <ion-buttons *ngIf="isLargeView" slot="end">
      <ion-button color="primay" fill="solid" type="button" color="primary" fill="solid" (click)="handleRouteEvent(appRoutes.dismiss)">
        Cancel
        <ion-icon slot="end" name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding signup-content">

  <p *ngIf="!haveDeets">First, a few deets so we can get you started.</p>
  <p *ngIf="haveDeets">Complete your profile.</p>

  <form [formGroup]="signupForm">
    <ion-list class="inputs-list" lines="full">
      <ion-item class="input-item">
        <ion-label color="tertiary">First Name:</ion-label>
        <ion-input type="text" placeholder="name" formControlName="firstName" clearInput autocapitalize="off"></ion-input>
      </ion-item>
      <div class="error-container">
        <ng-container *ngFor="let validation of validation_messages.firstName">
          <div class="error-message" *ngIf="signupForm.get('firstName').hasError(validation.type) && (signupForm.get('firstName').dirty || signupForm.get('firstName').touched)">
            <ion-icon name="information-circle-outline"></ion-icon>
            <span>{{ validation.message }}</span>
          </div>
        </ng-container>
      </div>

      <ion-item class="input-item">
        <ion-label color="tertiary">Last Name:</ion-label>
        <ion-input type="text" placeholder="surname" formControlName="lastName" clearInput autocapitalize="off"></ion-input>
      </ion-item>
      <div class="error-container">
        <ng-container *ngFor="let validation of validation_messages.lastName">
          <div class="error-message" *ngIf="signupForm.get('lastName').hasError(validation.type) && (signupForm.get('lastName').dirty || signupForm.get('lastName').touched)">
            <ion-icon name="information-circle-outline"></ion-icon>
            <span>{{ validation.message }}</span>
          </div>
        </ng-container>
      </div>

      <ion-item class="input-item">
        <ion-label color="tertiary">LinkedIn Url:</ion-label>
        <ion-input type="url" placeholder="https://www.linkedin.com/in/name" formControlName="url" clearInput autocapitalize="off" inputmode="url"></ion-input>
      </ion-item>
      <div class="error-container">
        <ng-container *ngFor="let validation of validation_messages.url">
          <div class="error-message" *ngIf="signupForm.get('url').hasError(validation.type) && (signupForm.get('url').dirty || signupForm.get('url').touched)">
            <ion-icon name="information-circle-outline"></ion-icon>
            <span>{{ validation.message }}</span>
          </div>
        </ng-container>
      </div>

      <ion-item class="input-item">
        <ion-label color="tertiary">Email:</ion-label>
        <ion-input type="email" placeholder="name@example.com" formControlName="email" clearInput autocapitalize="off" inputmode="email"></ion-input>
      </ion-item>
      <div class="error-container">
        <ng-container *ngFor="let validation of validation_messages.email">
          <div class="error-message" *ngIf="signupForm.get('email').hasError(validation.type) && (signupForm.get('email').dirty || signupForm.get('email').touched)">
            <ion-icon name="information-circle-outline"></ion-icon>
            <span>{{ validation.message }}</span>
          </div>
        </ng-container>
      </div>

      <div formGroupName="matching_passwords">
        <ion-item class="input-item">
          <ion-label color="tertiary">Password:</ion-label>
          <app-show-hide-password [ngClass]="{'show-hide-password': !isLargeView, 'show-hide-password-isLargeView': isLargeView}">
            <ion-input type="password" placeholder="password" formControlName="password"></ion-input>
          </app-show-hide-password>
        </ion-item>
        <div class="error-container">
          <ng-container *ngFor="let validation of validation_messages.password">
            <div class="error-message" *ngIf="signupForm.get('matching_passwords').get('password').hasError(validation.type) && (signupForm.get('matching_passwords').get('password').dirty || signupForm.get('matching_passwords').get('password').touched)">
              <ion-icon name="information-circle-outline"></ion-icon>
              <span>{{ validation.message }}</span>
            </div>
          </ng-container>
        </div>

        <ion-item class="input-item">
          <ion-label color="tertiary">Password:</ion-label>
          <app-show-hide-password [ngClass]="{'show-hide-password': !isLargeView, 'show-hide-password-isLargeView': isLargeView}">
            <ion-input type="password" placeholder="confirm password" formControlName="confirm_password"></ion-input>
          </app-show-hide-password>
        </ion-item>
        <div class="error-container">
          <ng-container *ngFor="let validation of validation_messages.confirm_password">
            <div class="error-message" *ngIf="signupForm.get('matching_passwords').get('confirm_password').hasError(validation.type) && (signupForm.get('matching_passwords').get('confirm_password').dirty || signupForm.get('matching_passwords').get('confirm_password').touched)">
              <ion-icon name="information-circle-outline"></ion-icon>
              <span>{{ validation.message }}</span>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="error-container">
        <ng-container *ngFor="let validation of validation_messages.matching_passwords">
          <div class="error-message" *ngIf="signupForm.get('matching_passwords').hasError(validation.type) && (signupForm.get('matching_passwords').get('confirm_password').dirty || signupForm.get('matching_passwords').get('confirm_password').touched)">
            <ion-icon name="information-circle-outline"></ion-icon>
            <span>{{ validation.message }}</span>
          </div>
        </ng-container>
      </div>

    </ion-list>

    <ion-button class="signup-btn" type="submit" expand="block" [disabled]="!signupForm.valid" (click)="submitFormOnEnterPress()">Sign Up</ion-button>
  </form>

  <div *ngIf="!haveDeets" class="width-full ion-text-center">
    <ion-button class="login-btn" fill="clear" (click)="handleRouteEvent(appRoutes.login)">
      Already have an account?
    </ion-button>
  </div>

  <div class="width-full legal-stuff">
    By creating an account you agree to our<br><a class="legal-action" (click)="showCardModal('privacy')">Privacy Policy</a> and <a class="legal-action" (click)="showCardModal('terms')">Terms of Use</a>.
  </div>

</ion-content>

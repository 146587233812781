<ion-header>
  <ion-toolbar color="primary" ion-toolbar mode="md">
    <ion-buttons slot="start">
      <ion-menu-button menu="none" autoHide="false" color="light" (click)="handleRouteEvent(appRoutes.back)">
        <ion-icon class="fs-24" name="arrow-back-outline"></ion-icon>
      </ion-menu-button>
    </ion-buttons>
    <ion-title>Forgot Password?</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding forgot-password-content">

  <h2 class="auth-title">Recover your password</h2>
  <p class="recover-message">
    Please enter your email address and we'll send you an email to reset your password.
  </p>
  <form [formGroup]="forgotPasswordForm">
    <ion-list class="inputs-list" lines="full">
      <ion-item class="input-item">
        <ion-label color="tertiary">Email:</ion-label>
        <ion-input type="email" placeholder="name@example.com" formControlName="email" clearInput autocapitalize="off" inputmode="email"></ion-input>
      </ion-item>
      <div class="error-container">
        <ng-container *ngFor="let validation of validation_messages.email">
          <div class="error-message" *ngIf="forgotPasswordForm.get('email').hasError(validation.type) && (forgotPasswordForm.get('email').dirty || forgotPasswordForm.get('email').touched)">
            <ion-icon name="information-circle-outline"></ion-icon>
            <span>{{ validation.message }}</span>
          </div>
        </ng-container>
      </div>
    </ion-list>

    <ion-button class="reset-password-btn" type="submit" expand="block" [disabled]="!forgotPasswordForm.valid" (click)="submitFormOnEnterPress()">Reset Password</ion-button>
  </form>

</ion-content>

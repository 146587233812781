<ion-header *ngIf="true">
  <ion-toolbar color="primary" ion-toolbar mode="md">
    <div [innerHTML]="'components.errorModal.title' | translate" class="ion-padding-start subtitle bold"></div>
  </ion-toolbar>
</ion-header>
<ion-content *ngIf="true">
  <div class="modalContainer text-center">
    <img class="error-image" src="/assets/img/error-oops.svg">
    <div>
      <div class="content" [innerHTML]="'components.errorModal.content' | translate"></div>
      <div class="continue-button">
        <ion-button (click)="continue()" [innerHTML]="'components.errorModal.continue' | translate" color="primary" fill="solid"></ion-button>
      </div>
    </div>
    <div class="support"><div *ngIf="showErrorSupportMessage" [innerHTML]="'components.errorModal.support' | translate"></div></div>
    <div *ngIf="correlationId" class="correlationId">Id: {{correlationId}}</div>
    <div *ngIf="showErrorMessages" class="errorMessages">NON-PROD ONLY MESSAGE VISIBILITY:<br><pre>{{messages}}</pre></div>
  </div>
</ion-content>
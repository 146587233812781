import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UserProfile } from '../../models/user-profile';
import { BaseComponent } from '../base/base.component';
import { takeUntil } from 'rxjs/operators';
import { ShareService } from '../../services/shares.service';
import { Share } from '../../models/share';
import { AppRoutes } from '../../enums/app-routes.enum';
import { environment } from '../../../environments/environment';
import { Clipboard } from '@capacitor/clipboard';
import { state, style, trigger } from '@angular/animations';


@Component({
  selector: 'app-share-detail',
  templateUrl: './share-detail.component.html',
  styleUrls: ['./share-detail.component.scss'],
  animations: [
    trigger('showHide', [
      state('true', style({ display: 'block' })),
      state('false', style({ display: 'none' }))
    ])
  ]
})
export class ShareDetailComponent extends BaseComponent implements OnInit {
  @Input() profile: UserProfile;
  @Input() closeDetailModal: () => void;
  @Input() modalShowing: boolean;
  @Input() share: Share;
  public appRoutes: AppRoutes;
  public showShares = false;
  private clipboard = Clipboard;

  constructor(private shareService: ShareService) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
  }

  /*
  API
  */

  private updateShare() {
    const share = new Share();
    Object.assign(share, this.share);
    share.shareViews = null;
    this.shareService.updateShare(share).subscribe({
      next: (share) => {
        this.shareService.getShare(share.id, ['shareView']).subscribe({
          next: (share) => {
            this.share = share;
            this.toastService.showSuccess('Share content updated!', null, {position: 'top'});
          }, error: (error) => {
            this.handleError(error);
          }
        });
      },
      error: (error) => {
        this.handleError(error);
      }
    });
  }

  private deleteShare() {
    this.shareService.deleteShare(this.share.id).subscribe({
      next: () => {
        this.closeModal();
      },
      error: (error) => {
        this.handleError(error);
      }
    });
  }

  /*
  Utils
  */

  public update() {
    this.updateShare();
  }

  public delete($event) {
    this.deleteShare();
  }

  public closeModal() {
    this.modalController.dismiss();
    this.closeDetailModal();
  }

  public viewMessage($event) {
    const text = `<span class="color-tertiary">Your message:</span><p>${this.share.sharedWithEmailMessage}</p>`;
    this.handleInfoPopoverClick($event, text, 'end');
  }

  public viewShares($event) {
    this.showShares = !this.showShares;
  }

  public getNumber(s: string): number {
    if (s === "-1") {
      return -1;
    }
    return Number(s);
  }

  public getString(n: number): string {
    if (n === -1) {
      return "-1";
    }
    return n?.toString();
  }

  public getDate(s: string): string {
    const date = new Date(s);
    return date.toLocaleString();
  }

  public getShareLink(id: string, withBreak?: boolean): string {
    if (withBreak) {
      return environment.app.baseUrl + AppRoutes.share + "/<br>" + id;
    } else {
      return environment.app.baseUrl + AppRoutes.share + "/" + id;
    }
  }

  public goToShare() {
    window.open(this.getShareLink(this.share.id), '_blank');
  }

  public async copyLink() {
    await this.clipboard.write({
      string: this.getShareLink(this.share.id)
    });

    this.toastService.showSuccess('Link copied to clipboard', null, {position: 'top'});
  }
}

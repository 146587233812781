import { environment } from '../../environments/environment';
import { IBaseApiModel } from '../interfaces/i-base-api-model';

export class DeviceData implements IBaseApiModel {
  // api model
  public bundleId: string = environment.app.bundleId;
  public browser: string = window.navigator.userAgent;
  public name: string;
  public model: string;
  public platform: string;
  public deviceUuid: string;
  public appVersion: string;
  public appBuild: string;
  public operatingSystem: string;
  public osVersion: string;
  public manufacturer: string;
  public isVirtual: boolean;
  public diskFree: number;
  public diskTotal: number;
  public memUsed: number;
  public metadata: any;
  public fcmToken: string;
  public created: Date;
  public updated: Date;
  public deleted: Date;
  // created by API when upsert, used to logout
  public id: string;

  constructor(obj?: any) {
    if (obj) {
      if (obj.id) {
        this.id = obj.id;
      } else {
        this.id = environment.app.yeahShareUserId;
      }
      if (obj.name) {
        this.name = obj.name;
      }
      if (obj.model) {
        this.model = obj.model;
      }
      if (obj.platform) {
        this.platform = obj.platform;
      }
      if (obj.uuid) {
        this.deviceUuid = obj.uuid;
      } else {
        if (obj.deviceUuid) {
          this.deviceUuid = obj.deviceUuid;
        }
      }
      if (obj.appVersion) {
        this.appVersion = obj.appVersion;
      }
      if (obj.appBuild) {
        this.appBuild = obj.appBuild;
      }
      if (obj.operatingSystem) {
        this.operatingSystem = obj.operatingSystem;
      }
      if (obj.osVersion) {
        this.osVersion = obj.osVersion;
      }
      if (obj.manufacturer) {
        this.manufacturer = obj.manufacturer;
      }
      if (obj.isVirtual) {
        this.isVirtual = obj.isVirtual;
      }
      this.metadata = {
        diskFree: obj.diskFree, diskTotal: obj.diskTotal, memUsed: obj.memUsed
      };
      if (obj.fcmToken) {
        this.fcmToken = obj.fcmToken;
      }
      if (obj.created) {
        this.created = obj.created;
      }
      if (obj.updated) {
        this.updated = obj.updated;
      }
      if (obj.deleted) {
        this.deleted = obj.deleted;
      }
    }
  }

  get isValid(): boolean {
    return true;
  }

  public cleanseForAPI(): void {
    const removeKeys = ['metadata', 'id'];
    const currentKeys = Object.keys(this);
    currentKeys.forEach((key) => {
      if (removeKeys.indexOf(key) > -1) {
        delete this[key];
      }
    });
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UserProfile } from '../../models/user-profile';
import { BaseComponent } from '../base/base.component';
import { takeUntil } from 'rxjs/operators';
import { ShareService } from '../../services/shares.service';
import { Share } from '../../models/share';
import { AppRoutes } from '../../enums/app-routes.enum';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-shares-create',
  templateUrl: './shares-create.component.html',
  styleUrls: ['./shares-create.component.scss']
})
export class SharesCreateComponent extends BaseComponent implements OnInit {
  @Input() profile: UserProfile;
  @Input() closeCreateModal: (id: string) => void;
  @Input() modalShowing: boolean;
  @Input() linkedInUploadId: string;
  @Input() linkedInProfileId: string;

  public locationTop: string;
  public companiesTop: string;
  public messagesTop: string;
  public networkTop: string;

  public newShare: Share;
  shareForm: FormGroup;

  validation_messages = {
    'email': [
      { type: 'required', message: 'Email is required.' },
      { type: 'pattern', message: 'Enter a valid email.' }
    ]
  };

  constructor(private shareService: ShareService) {
    super();
    this.shareForm = new FormGroup({
      'email': new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*$')
      ]))
    });
  }

  ngOnInit() {
    super.ngOnInit();
    this.resetNewShare();
  }

  /*
  API
  */

  public share() {
    this.newShare.sharedByEmail = this.profile.email;
    this.newShare.linkedInUploadId = this.linkedInUploadId;
    this.newShare.linkedInProfileId = this.linkedInProfileId;
    this.newShare.sharedByProfileId = this.profile.id;

    this.shareService.createShare(this.newShare).subscribe({
      next: (share) => {
        this.closeModal(share.id);
      }, error: (error) => {
        this.handleError(error);
      }
    });
  }

  /*
  Utils
  */

  public closeModal(id: string) {
    this.closeCreateModal(id);
  }

  public resetNewShare() {
    this.newShare = new Share();
    this.newShare.sendEmail = true;
    this.newShare.locationShared = true;    
    this.newShare.locationTop = 10;
    this.locationTop = "10";
    this.newShare.companiesShared = true;
    this.newShare.companiesTop = 10;
    this.companiesTop = "10";
    this.newShare.messagesShared = true;
    this.newShare.messagesTop = 10;
    this.messagesTop = "10";
    this.newShare.networkShared = true;
    this.newShare.networkTop = 10;
    this.networkTop = "10";
  }

  public updateTop(type: string, val: string) {
    switch (type) {
      case ('location'):
        this.newShare.locationTop = this.getNumber(val);
        this.locationTop = val.toString();
        break;
      case ('compaines'):
        this.newShare.companiesTop = this.getNumber(val);
        this.companiesTop = val.toString();
        break;
      case ('messages'):
        this.newShare.messagesTop = this.getNumber(val);
        this.messagesTop = val.toString();
        break;
      case ('network'):
        this.newShare.networkTop = this.getNumber(val);
        this.networkTop = val.toString();
        break;
    }
  }

  public getNumber(s: string) {
    if (s === "-1") {
      return -1;
    }
    return Number(s);
  }

  public getString(n: number) {
    if (n === -1) {
      return "-1";
    }
    return n.toString();
  }

  public validEmail(): boolean {
    if (!this.newShare.sharedWithEmail) {
      return false;
    }

    const re = /\S+@\S+\.\S+/;
    return re.test(this.newShare.sharedWithEmail);
  }

  public previewShare() {
    this.newShare.sharedByEmail = this.profile.email;
    this.newShare.linkedInUploadId = this.linkedInUploadId;
    this.newShare.linkedInProfileId = this.linkedInProfileId;
    this.newShare.sharedByProfileId = this.profile.id;
    this.newShare.preview = true;

    this.shareService.createShare(this.newShare).subscribe({
      next: (share) => {
        this.goToShare(share.id);
      }, error: (error) => {
        this.handleError(error);
      }
    });
  }

  private getShareLink(id: string, withBreak?: boolean): string {
    if (withBreak) {
      return environment.app.baseUrl + AppRoutes.share + "/<br>" + id;
    } else {
      return environment.app.baseUrl + AppRoutes.share + "/" + id;
    }
  }

  private goToShare(id: string) {
    window.open(this.getShareLink(id), '_blank');
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from '../base/base.component';
import { MusingModalComponent } from '../musing-modal/musing-modal.component';
import { StorageKeys } from 'app/enums/storage-keys.enum';
import { TextPopoverComponent } from '../text-popover/text-popover.component';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-musings',
  templateUrl: './musings.component.html',
  styleUrls: ['./musings.component.scss']
})
export class MusingsComponent extends BaseComponent implements OnInit {
  @Input() musingContext: string;
  @Input() shareDisplay$ = new BehaviorSubject<string>(null);
  public highlight = false;
  public doFlicker = false;
  private disableShowModal = false;

  constructor() {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.startHighlight();
  }

  private async startHighlight() {
    /*
    const shareDisplay = this.shareDisplay$.getValue();
    if (shareDisplay) {
      return;
    }
    */

    const wasHighlighted = await this.storageService.get(StorageKeys.musingHighlighted);
    if (!wasHighlighted) {
      this.doFlicker = true;
      this.doHighlight();
      if (this.musingContext === 'location') {
        this.disableShowModal = true
        const musingPosition = this.isLargeView ? 'musing-location-stat' : 'musing-location-action';
        const popoverEl = document.getElementById(musingPosition);      
        popoverEl.addEventListener('click', this.musingPopover.bind(this));
        popoverEl.click();
        setTimeout(() => {
          popoverEl.removeEventListener('click', this.musingPopover.bind(this));
          this.disableShowModal = false;
        }, 1000);
      }
    }
  }

  private async musingPopover($event) {
    if (!this.disableShowModal) {
      return;
    }

    this.popover = await this.popoverController.create({
      component: TextPopoverComponent,
      componentProps: {
        text$: this.util.getBehaviorSubject('<span class="color-tertiary cursor-pointer">&#8593; click on these icons to access more insights...</span>')
      },
      event: $event,
      showBackdrop: false,
      mode: 'ios',
      cssClass: 'musing-popover',
      dismissOnSelect: true,
      side: 'bottom'
    });
    await this.popover.present();

    this.popover.onDidDismiss().then((data) => {
      if (data && !data.role) {
        this.showModal();
      }
    });
  }

  private doHighlight() {
    if (this.doFlicker) {
      setTimeout(() => {
        this.highlight = true;
        setTimeout(() => {
          this.highlight = false;
          this.doHighlight();
        }, 1000);
      }, 1000);
    }
  }

  private async setDoNotHighlight() {
    await this.storageService.set(StorageKeys.musingHighlighted, true);
    this.doFlicker = false;
    this.highlight = false;
  }

  public async showModal() {
    if (this.disableShowModal) {
      return;
    }
    if (this.popover) {
      await this.popover.dismiss();
    }
    if (this.modal) {
      await this.modal.dismiss();
    }

    const routerOutlet =  await this.modalController.getTop() || document.getElementById('ion-router-outlet-content');
    this.modal = await this.modalController.create({
      swipeToClose: true,
      presentingElement: routerOutlet,
      component: MusingModalComponent,
      componentProps: {
        musingContext: this.musingContext       
      },
      cssClass: this.isLargeView ? 'modal-475h' : ''
    });

    await this.modal.present();

    this.setDoNotHighlight();
  }
}

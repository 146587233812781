import { Orientation } from '../enums/orientation';

export class ScreenInfo {
  width: number;
  height: number;
  orientation: Orientation;
  isLargeView: boolean;

  constructor(obj?: any) {
    Object.assign(this, obj);
  }
}

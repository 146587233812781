
  <ion-avatar class="ion-margin-end avatar-wrapper">

    <ng-container *ngIf="vm$ | async as vm">
      <ion-img *ngIf="vm.avatarUrl" [src]="vm.avatarUrl"></ion-img>
      <div *ngIf="vm.avatarUrl === ''" class="default-avatar badge {{util.getBadgeClass(vm.persona)}}">
        <span class="initials">{{vm.initials}}</span>
      </div>
    </ng-container>


  </ion-avatar>
  


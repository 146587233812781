import { LinkedInConnection, LinkedInConnectionSummary } from './linkedin-connection';

export class LinkedInConnectionsResponse {
  public limit: number;
  public page: number;
  public sort: string;
  public totalRows: number;
  public totalPages: number;
  public rows: Array<LinkedInConnection>;

  constructor(obj?: any) {
    Object.assign(this, obj);

    if (obj.rows) {
      this.rows = new Array<LinkedInConnection>();
      obj.rows.forEach(lm => {
        this.rows.push(new LinkedInConnection(lm));
      });
    }
  }
}

export class LinkedInSharedConnectionsResponse {
  public limit: number;
  public page: number;
  public sort: string;
  public totalRows: number;
  public totalPages: number;
  public rows: Array<LinkedInConnectionSummary>;

  constructor(obj?: any) {
    Object.assign(this, obj);

    if (obj.rows) {
      this.rows = new Array<LinkedInConnectionSummary>();
      obj.rows.forEach(lm => {
        this.rows.push(new LinkedInConnectionSummary(lm));
      });
    }
  }
}
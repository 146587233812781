export class LegalAddress {
  public address1: string;
  public address2: string;
  public city: string;
  public state: string;
  public zip: string;

  constructor(obj?: any) {
    Object.assign(this, obj);
  }

  get isValid() {
    return this.address1 && this.city && this.state && this.zip;
  }
}

import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-faq',
  templateUrl: 'faq.component.html',
  styleUrls: [
    './faq.component.scss'
  ]
})

export class FaqComponent {
  public appVersion = environment.app.version;

  constructor(private modalController: ModalController) { }

  dismiss(): void {
    this.modalController.dismiss();
  }
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';
import { StorageKeys } from '../enums/storage-keys.enum';
import { LogLevels } from '../models/logLevels';
import { StorageService } from './storage.service';
import { datadogLogs } from '@datadog/browser-logs';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class Logger {
  public logLevels: LogLevels;
  public setLogLevels$: BehaviorSubject<LogLevels> = new BehaviorSubject(environment.logger.defaultLevels);
  public retryTransmitLogLineToCloud: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public FATAL = 'FATAL';
  public WARN = 'WARN';
  public ERROR = 'ERROR';
  public INFO = 'INFO';
  public DEBUG = 'DEBUG';
  public TRACE = 'TRACE';
  private cacheBusy = false;

  constructor(
    private http: HttpClient,
    private storageService: StorageService
  ) {
    // subscribe to log levels updates
    this.setLogLevels$.subscribe((logLevels) => {
      this.logLevels = logLevels;
    });
    // subscribe for retry
    this.retryTransmitLogLineToCloud.subscribe((retry) => {
      if (retry && environment.logger.cloud && !this.cacheBusy) {
        this.retry();
      }
    }, err => {
      this.manageLogLineCache(err);
    });
  }

  private async retry() {
    const loggingToken = await this.storageService.get(StorageKeys.loggingToken);
    if (loggingToken) {
      this.doRetryTransmitToCloud(loggingToken);
    }
  }

  public logFatal(source: string, func: string, data: any, message: string, correlationId?: string) {
    if (this.logLevels.fatal) {
      this.logLine(this.FATAL, source, func, data, message, correlationId);
    }
  }

  public logError(source: string, func: string, data: any, message: string, correlationId?: string) {
    if (this.logLevels.error) {
      this.logLine(this.ERROR, source, func, data, message, correlationId);
    }
  }

  public logWarn(source: string, func: string, data: any, message: string, correlationId?: string) {
    if (this.logLevels.warn) {
      this.logLine(this.WARN, source, func, null, message, correlationId);
    }
  }

  public logInfo(source: string, func: string, message: string, correlationId?: string) {
    if (this.logLevels.info) {
      this.logLine(this.INFO, source, func, null, message, correlationId);
    }
  }

  public logDebug(source: string, func: string, data: any, message: string, correlationId?: string) {
    if (this.logLevels.debug) {
      this.logLine(this.DEBUG, source, func, data, message, correlationId);
    }
  }

  public logInfoAndDebug(source: string, func: string, data: any, message: string, correlationId?: string) {
    if (this.logLevels.info) {
      this.logLine(this.INFO, source, func, null, message, correlationId);
    }
    if (this.logLevels.debug) {
      this.logLine(this.DEBUG, source, func, data, message, correlationId);
    }
  }

  public logTrace(source: string, func: string, data: any, message: string, correlationId?: string) {
    if (this.logLevels.trace) {
      this.logLine(this.TRACE, source, func, data, message, correlationId);
    }
  }

  public logInfoAndDebugAndTrace(source: string, func: string, debugdata: any, tracedata: any, message: string, correlationId?: string) {
    if (this.logLevels.info) {
      this.logLine(this.INFO, source, func, null, message, correlationId);
    }
    if (this.logLevels.debug) {
      this.logLine(this.DEBUG, source, func, debugdata, message, correlationId);
    }
    if (this.logLevels.trace) {
      this.logLine(this.TRACE, source, func, tracedata, message, correlationId);
    }
  }

  private async logLine(level: string, source: string, func: string, data: any, message: string, correlationId?: string) {
    if (!environment.logger.console && !environment.logger.cloud) {
      return;
    }
    Promise.all([this.storageService.get(StorageKeys.currentProfileId), this.storageService.get(StorageKeys.deviceData)])
    .then(([currentProfileId, deviceData]) => {
      const jsonMsg = {
        time: moment().utc(). valueOf(),
        sourcetype: '_json',
        event: {
          orig: environment.app.bundleId,
          level,
          src: source,
          function: func,
          message,
          data,
          correlationId,
          profileId: currentProfileId,
          deviceUuid: deviceData ? deviceData.deviceUuid : 'no-device-data',
          devicePlatform: deviceData ? deviceData.platform : null
        }
      };
      if (environment.logger.console) {
        console.log(JSON.stringify(jsonMsg));
      }
      if (environment.logger.cloud) {
        this.sendLogLineToCloud(jsonMsg);
      }
      if (environment.logger.datadog) {
        const ddJsonMsg: any = {};
        ddJsonMsg.event = jsonMsg.event;
        ddJsonMsg.event.time = jsonMsg.time;
        switch (level) {
          case (this.FATAL):
          case (this.ERROR):
            datadogLogs.logger.log(ddJsonMsg.event.message, ddJsonMsg.event, 'error');
            break;
          case (this.WARN):
            datadogLogs.logger.log(ddJsonMsg.event.message, ddJsonMsg.event, 'warn');
            break;
          case (this.INFO):
            datadogLogs.logger.log(ddJsonMsg.event.message, ddJsonMsg.event, 'info');
            break;
          case (this.DEBUG):
          case (this.TRACE):
            datadogLogs.logger.log(ddJsonMsg.event.message, ddJsonMsg.event, 'debug');
            break;
        }
      }
    });
  }

  public addLineToCache(level: string, source: string, func: string, data: any, message: string, correlationId?: string) {
    Promise.all([this.storageService.get(StorageKeys.currentProfileId), this.storageService.get(StorageKeys.deviceData)])
    .then(([currentProfileId, deviceData]) => {
      const jsonMsg = {
        time: moment().utc(). valueOf(),
        sourcetype: '_json',
        event: {
          orig: environment.app.bundleId,
          level,
          src: source,
          function: func,
          message,
          data,
          correlationId,
          profileId: currentProfileId,
          deviceUuid: deviceData ? deviceData.deviceUuid : 'no-device-data',
          devicePlatform: deviceData ? deviceData.platform : null
        }
      };
      this.addLineToCacheAndManageCache(jsonMsg, { status: 401 });
    });
  }

  private async sendLogLineToCloud(jsonMsg: any) {
    const loggingToken = await this.storageService.get(StorageKeys.loggingToken);
    if (loggingToken) {
      this.transmitLogLineToCloud(jsonMsg, loggingToken);
    } else { // cache for retry after a successful login
      this.addLineToCacheAndManageCache(jsonMsg, { status: 401 });
    }
  }

  private transmitLogLineToCloud(jsonMsg: any, loggingToken: any) {
    // send directly to Splunk HEC
    const headers = new HttpHeaders({
      Authorization: 'Splunk ' + loggingToken,
      'Content-Type': 'application/x-www-form-urlencoded'
    });
    this.http.post(environment.logger.splunkHecEndpoint, jsonMsg, { headers }).subscribe(() => {
      // success do nothing
    }, err => {
      this.addLineToCacheAndManageCache(jsonMsg, err);
    });
  }

  private async addLineToCacheAndManageCache(jsonMsg: any, err: any) {
    // 2022-06-08 turning logging back on without cache, cache was causing issues so need to revisit but we'd like to get logging back if network/splunk available
    return;

    // if we have a token but cannot connect to splunk, turn off logging until next login
    if (err.status !== 401) {
      await this.storageService.remove(StorageKeys.loggingToken);
    }
    this.addLogLineToCache(jsonMsg);
    this.manageLogLineCache(err);
  }

  private async manageLogLineCache(err: any) {
    // 2022-06-08 turning logging back on without cache, cache was causing issues so need to revisit but we'd like to get logging back if network/splunk available
    return;

    Promise.all([this.storageService.get(StorageKeys.currentProfileId), this.storageService.get(StorageKeys.deviceData)])
    .then(([currentProfileId, deviceData]) => {
      // if we don't have a token it's ok to be here, otherwise add the error to the cache
      if (err.status !== 401) {
        const jsonMsg = {
          time: moment().utc(). valueOf(),
          sourcetype: '_json',
          event: {
            orig: environment.app.bundleId,
            level: 'ERROR',
            src: 'Logger',
            function: 'transmitLogLineToCloud',
            message: 'API error',
            data: err,
            profileId: currentProfileId,
            deviceUuid: deviceData ? deviceData.deviceUuid : 'no-device-data',
            devicePlatform: deviceData ? deviceData.platform : null
          }
        };
        this.addLogLineToCache(jsonMsg);
      }
    });
    // if the cache is bigger than environment.logger.maxLogLineCache, prune it from the middle
    this.cacheBusy = true;
    const logLineCache = await this.storageService.get(StorageKeys.logLineCache);
    const logLineCacheJson: Array<any> = logLineCache ? logLineCache : new Array<any>();
    if (logLineCache && logLineCacheJson.length > environment.logger.maxLogLineCache) {
      logLineCacheJson.splice(environment.logger.maxLogLineCache % 3, environment.logger.maxLogLineCache % 10);
      this.storageService.set(StorageKeys.logLineCache, logLineCacheJson);
      this.cacheBusy = false;
    }
  }

  private async addLogLineToCache(jsonMsg: any) {
    // 2022-06-08 turning logging back on without cache, cache was causing issues so need to revisit but we'd like to get logging back if network/splunk available
    return;

    if (this.cacheBusy) {
      setTimeout(() => {
        this.addLogLineToCache(jsonMsg);
      }, 100);
    } else {
      this.cacheBusy = true;
      const logLineCache = await this.storageService.get(StorageKeys.logLineCache);
      const logLineCacheJson: Array<any> = logLineCache ? logLineCache : new Array<any>();
      logLineCacheJson.push({ id: this.generateGuid(), jsonMsg });
      this.storageService.set(StorageKeys.logLineCache, logLineCacheJson).then(() => {
        this.cacheBusy = false;
      });
    }
  }

  private async doRetryTransmitToCloud(loggingToken) {
    // 2022-06-08 turning logging back on without cache, cache was causing issues so need to revisit but we'd like to get logging back if network/splunk available
    return;

    this.cacheBusy = true;
    const logLineCache = await this.storageService.get(StorageKeys.logLineCache);
    const logLineCacheJson: Array<any> = logLineCache ? logLineCache : new Array<any>();
    if (logLineCache && logLineCacheJson.length > 0) {
      const logLine = logLineCacheJson.slice(0, 1)[0];
      // send directly to Splunk HEC
      const headers = new HttpHeaders({
        Authorization: 'Splunk ' + loggingToken,
        'Content-Type': 'application/x-www-form-urlencoded'
      });
      // send a line to the cloud
      this.http.post(environment.logger.splunkHecEndpoint, logLine.jsonMsg, { headers }).subscribe(() => {
        // now remove what you just sent
        const idx = logLineCacheJson.findIndex(item => {
          return item.id === logLine.id;
        });
        if (idx > -1) {
          logLineCacheJson.splice(idx, 1);
        }
        this.storageService.set(StorageKeys.logLineCache, logLineCacheJson).then(() => {
          // repeat until the cache is cleared
          this.doRetryTransmitToCloud(loggingToken);
        });
      }, err => {
        this.manageLogLineCache(err);
        this.cacheBusy = false;
      });
    } else {
      this.cacheBusy = false;
    }
  }

  private generateGuid(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      // tslint:disable-next-line:no-bitwise
      const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }
}

<ion-header>
  <ion-toolbar color="primary" ion-toolbar mode="md">
    <ion-buttons *ngIf="!isLargeView" slot="start">
      <ion-menu-button menu="none" autoHide="false" color="light" (click)="handleRouteEvent(appRoutes.back)">
        <ion-icon class="fs-24" name="arrow-back-outline"></ion-icon>
      </ion-menu-button>
    </ion-buttons>
    <ion-title>Sign In</ion-title>
    <ion-buttons *ngIf="isLargeView" slot="end">
      <ion-button color="primay" fill="solid" type="button" color="primary" fill="solid" (click)="handleRouteEvent(appRoutes.dismiss)">
        Cancel
        <ion-icon slot="end" name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding login-content">
  <h2 *ngIf="!alreadyhaveAnAccount" class="auth-title">
    Welcome!
    <br/>
    Discover your network.
  </h2>
  <h2 *ngIf="alreadyhaveAnAccount" class="auth-title">
    Welcome back!
  </h2>
  <form [formGroup]="loginForm">
    <ion-list class="inputs-list" lines="full">
      <ion-item class="input-item">
        <ion-label color="tertiary">Email:</ion-label>
        <ion-input type="email" placeholder="name@example.com" formControlName="email" clearInput autocapitalize="off" inputmode="email" tabindex="1" autofocus="true" [debounce]="500" (ionChange)="emailChanged($event)"></ion-input>
      </ion-item>
      <div class="error-container">
        <ng-container *ngFor="let validation of validation_messages.email">
          <div class="error-message" *ngIf="loginForm.get('email').hasError(validation.type) && (loginForm.get('email').dirty || loginForm.get('email').touched)">
            <ion-icon name="information-circle-outline"></ion-icon>
            <span>{{ validation.message }}</span>
          </div>
        </ng-container>
      </div>

      <ion-item class="input-item">
        <ion-label color="tertiary">Password:</ion-label>
        <app-show-hide-password [ngClass]="{'show-hide-password': !isLargeView, 'show-hide-password-isLargeView': isLargeView}">
          <ion-input type="password" placeholder="password" formControlName="password" tabindex="2"></ion-input>
        </app-show-hide-password>
      </ion-item>
      <div class="error-container">
        <ng-container *ngFor="let validation of validation_messages.password">
          <div class="error-message" *ngIf="loginForm.get('password').hasError(validation.type) && (loginForm.get('password').dirty || loginForm.get('password').touched)">
            <ion-icon name="information-circle-outline"></ion-icon>
            <span>{{ validation.message }}</span>
          </div>
        </ng-container>
      </div>
    </ion-list>

    <ion-button class="login-btn" type="submit" expand="block" [disabled]="!loginForm.valid" (click)="submitFormOnEnterPress()">Sign In</ion-button>
    <ion-row class="other-auth-options-row">
      <ion-button class="forgot-btn" fill="clear" (click)="handleRouteEvent(appRoutes.forgotPassword)">
        Forgot Password?
      </ion-button>
      <ion-button *ngIf="!alreadyhaveAnAccount" class="signup-btn" fill="clear" (click)="handleRouteEvent(appRoutes.upload)">
        Sign Up!
      </ion-button>
    </ion-row>
  </form>

</ion-content>

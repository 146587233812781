import { Injectable } from '@angular/core';
import { ToastController, ToastOptions } from '@ionic/angular';
import { Loader } from '../models/loader';
import { AuthService } from './auth.service';
import { LoadingService } from './loading.service';
import { ProfileService } from './profile.service';
import { StorageService } from './storage.service';
import { UtilsService } from './utils.service';

type ToastServiceOptions = Omit<ToastOptions , 'message'>;

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  private readonly duration = 1500;

  constructor(public toastController: ToastController, private loadingService: LoadingService, private utilsService: UtilsService, private storageService: StorageService,
    private profileService: ProfileService, private authService: AuthService
    ) {
  }


  public async showSuccess(message: string, additionalMessages?: string[], additionalOptions?: ToastServiceOptions) {
    if (additionalMessages && additionalMessages.length) {
      message = message + '\n\n' + additionalMessages.join('\n');
    }
    if (!additionalOptions) {
      additionalOptions = {};
    }
    if (!additionalOptions.color) {
      additionalOptions.color = 'success';
    }
    const toast = await this.toastController.create({
      message,
      duration: this.duration,
      cssClass: 'text-white',
      buttons: [{
        text: 'X',
        role: 'close'
      }],
      ...(additionalOptions ?? {})
    });

    toast.present();
  }

  public async showInfo(message: string, additionalMessages?: string[], additionalOptions?: ToastServiceOptions) {
    if (additionalMessages && additionalMessages.length) {
      message = message + '\n\n' + additionalMessages.join('\n');
    }
    if (!additionalOptions) {
      additionalOptions = {};
    }
    if (!additionalOptions.color) {
      additionalOptions.color = 'tertiary';
    }
    const toast = await this.toastController.create({
      message,
      cssClass: 'text-white',
      duration: this.duration,
      buttons: [{
        text: 'X',
        role: 'close'
      }],
      ...(additionalOptions ?? {})
    });

    toast.present();
  }

  public async showError(messages: string[], mustDismiss?: boolean, additionalOptions?: ToastServiceOptions) {
    let duration = this.duration * 3;
    if (mustDismiss) {
      duration = 0;
    }
    messages = messages ? messages : ['No error messages returned'];
    if (!Array.isArray(messages)) {
      messages = [messages];
    }
    if (!additionalOptions) {
      additionalOptions = {};
    }
    if (!additionalOptions.color) {
      additionalOptions.color = 'danger';
    }
    const toast = await this.toastController.create({
      message: messages.join('\n'),
      duration,
      buttons: [{
        text: 'X',
        role: 'close'
      }],
      ...(additionalOptions ?? {})
    });

    this.loadingService.loader$.next(new Loader(false, true));
    toast.present();
  }

  public async toastWithRoute(message: string, route: string, additionalMessages?: string[], additionalOptions?: ToastServiceOptions) {
    if (additionalMessages && additionalMessages.length) {
      message = message + '\n\n' + additionalMessages.join('\n');
    }
    if (!additionalOptions) {
      additionalOptions = {};
    }
    if (!additionalOptions.color) {
      additionalOptions.color = 'primary';
    }

    const toast = await this.toastController.create({
      message,
      duration: this.duration * 3,
      position: 'top',
      buttons: [
        {
          icon: 'open-outline',
          handler: () => {
            this.closeAndNavigateForward(route);
          },
        },
        {
          text: 'X',
          role: 'close',
          handler: () => {
            this.close();
          }
        }],
      ...(additionalOptions ?? {})
    });

    this.loadingService.loader$.next(new Loader(false, true));
    toast.present();
  }

  public async toastWithLink(message: string, link: string, additionalMessages?: string[], additionalOptions?: ToastServiceOptions) {
    if (additionalMessages && additionalMessages.length) {
      message = message + '\n\n' + additionalMessages.join('\n');
    }
    if (!additionalOptions) {
      additionalOptions = {};
    }
    if (!additionalOptions.color) {
      additionalOptions.color = 'primary';
    }
    const toast = await this.toastController.create({
      message,
      duration: this.duration * 3,
      position: 'top',
      buttons: [
        {
          icon: 'open-outline',
          cssClass: 'toast-link-button',
          handler: () => {
            this.openRouteInNewTab(link);
          }
        },
        {
          text: 'X',
          role: 'close',
          handler: () => {
            this.close();
          }
        }],
      ...(additionalOptions ?? {})
    });

    this.loadingService.loader$.next(new Loader(false, true));
    toast.present();
  }

  public async openRouteInNewTab(link: string) {
    await this.close();
    window.open(link, '_blank');
  }
  
  public async closeAndNavigateForward(route) {
    await this.close();
    this.utilsService.navigateForward(route);
  }

  public async close() {
  }
}

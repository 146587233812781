import { Component } from '@angular/core';
import { SplashScreen } from '@capacitor/splash-screen';
import { HistoryHelperService } from './utils/history-helper.service';
import { Platform } from '@ionic/angular';
import { SwUpdate } from '@angular/service-worker';
import { UtilsService } from './services/utils.service';
import { StorageService } from './services/storage.service';
import { Logger } from './services/logger.service';
import { ModalController } from '@ionic/angular';
import { GoogleAnalyticsService } from './services/ga.service';
import { Device } from '@capacitor/device';
import { App, AppState} from '@capacitor/app';
import { DeviceData } from './models/device-data';
import { StorageKeys } from './enums/storage-keys.enum';
import { Keyboard} from '@capacitor/keyboard';
import { HistoryService } from './services/history.service';
import { Router } from '@angular/router';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { environment } from '../environments/environment';
import * as moment from 'moment';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {
  private source = 'AppComponent';
  private device = Device;
  private app = App;
  private modal: HTMLIonModalElement;
  public router: Router;

  textDir = 'ltr';

  // Inject HistoryHelperService in the app.components.ts so its available app-wide
  constructor (
    private platform: Platform, 
    private swUpdate: SwUpdate,
    private utilsService: UtilsService, 
    private storageService: StorageService, 
    private logger: Logger,
    public modalController: ModalController,
    private ga: GoogleAnalyticsService,
    public historyHelper: HistoryHelperService,
    private historyService: HistoryService
  ) {
    this.initializeApp();
  }

  async initializeApp() {
    try {
      await SplashScreen.hide();
    } catch (err) {
    }

    const func = 'initializeApp';

    if (environment.integrations.datadog.rumEnabled) {
      datadogRum.init({
        applicationId: '9d4ca570-9183-41c8-b621-76f118aca61b',
        clientToken: 'pub8d3c7c68bb3ecfc0916647c844adc4ac',
        site: 'us5.datadoghq.com',
        service: `yeahshare-app-${environment.env}`,
        env: environment.env,
        version: environment.app.version,
        sessionSampleRate: 100,
        premiumSampleRate: 100,
        sessionReplaySampleRate: 100, // if not included, the default is 100
        trackUserInteractions: true,
      });

      datadogRum.startSessionReplayRecording();
    }

    if (environment.logger.datadog) {

      datadogLogs.init({
        clientToken: 'pub93156dd61143635c8d2b9ba15d1b91c4',
        site: 'us5.datadoghq.com',
        service: `yeahshare-app-${environment.env}`,
        env: environment.env,
        version: environment.app.version,
        forwardErrorsToLogs: true,
        sessionSampleRate: 100
      });

    }

    this.logger.logDebug(this.source, func, null, 'initializing app');
    this.logger.logInfoAndDebug(this.source, func, environment, 'environment');

    // when device data changes, update in local storage
    this.utilsService.deviceData$.pipe().subscribe((deviceData) => {
      if (deviceData) {
        this.logger.logInfoAndDebug(this.source, func, deviceData, 'device data updated');
        this.setDeviceData(deviceData);
      }
    });

    // set device data and init fcm
    Promise.all([this.device.getInfo(), this.device.getId()])
      .then(([result, deviceId]) => {
        this.logger.logInfoAndDebug(this.source, func, result, 'device.getInfo');
        const deviceData = new DeviceData(result);
        this.logger.logInfoAndDebug(this.source, func, deviceId, 'device.getId');
        deviceData.deviceUuid = deviceId.identifier;
        this.logger.logInfoAndDebug(this.source, func, deviceData, 'deviceData');
        this.utilsService.deviceData$.next(deviceData);
        this.utilsService.getIsNative().then((isNative) => {
          if (isNative) {
            this.setupNativeApp(deviceData);
          } else {
            this.checkForPwaUpdate();
          }
        });
      }).catch(err => console.error(err));

    if (environment.integrations.ga.enabled) {
      this.ga.startTrackerWithId(environment.integrations.ga.measurementId);
    }

    this.historyService.trackHistory();
  }

  private async setDeviceData(deviceData: DeviceData) {
    const func = 'setToDeviceData';
    await this.storageService.set(StorageKeys.deviceData, deviceData);
  }

  private checkForPwaUpdate() {
    const func = 'checkForPwaUpdate';
    // if pwa and service worker is enabled, check for update
    if (this.swUpdate.isEnabled) {
      this.logger.logInfo(this.source, func, 'swUpdate.isEnabled');
      if (environment.settings.enableAppUpdate) {
        this.logger.logInfo(this.source, func, 'subscribe to swUpdate.available');
        this.swUpdate.available.subscribe(() => {
          this.logger.logInfo(this.source, func, 'swUpdate.available');
          window.location.reload();
        });
      }
      this.swUpdate.checkForUpdate();
    }
  }

  private async setupNativeApp(deviceData: DeviceData) {
    const func = 'setupApp';
    this.app.getInfo().then((appResult) => {
      deviceData.appBuild = appResult.build;
      deviceData.appVersion = appResult.version;
      this.utilsService.deviceData$.next(deviceData);
      this.setupKeyboard();
      // this.initFcm(deviceData);
    });
    App.getLaunchUrl().then((launchUrl) => {
      if (launchUrl && launchUrl.url) {
        this.logger.logInfoAndDebug(this.source, func, launchUrl, 'launchUrl');
      }
    });
    App.addListener('appStateChange', (data: AppState) => {
      // state.isActive contains the active state
      this.logger.logInfoAndDebug(this.source, func, data, 'appStateChange');
    });
    App.addListener('appUrlOpen', (data: any) => {
      this.logger.logInfoAndDebug(this.source, func, data, 'openUrl');
    });
  }

  /*
  private initFcm(deviceData) {
    const func = 'initFcm';
    if (!(this.platform.is('cordova') || this.platform.is('capacitor'))) {
      this.logger.logWarn(this.source, func, null, 'fcm not initialized, cordova and capacitor not available.');
      return;
    }

    this.pushNotifications.addListener('registration',
      (token: Token) => {
        this.logger.logInfoAndDebug(this.source, func, token, 'registration');
        deviceData.fcmToken = token.value;
        this.utilsService.deviceData$.next(deviceData);
        this.putDeviceData(deviceData);
      }
    );

    this.pushNotifications.addListener('registrationError',
      (error: any) => {
        this.logger.logError(this.source, func, error, 'registrationError');
      }
    );

    this.pushNotifications.requestPermissions().then((result) => {
      this.logger.logInfoAndDebug(this.source, func, result, 'requestPermission');

      this.pushNotifications.register();
      this.logger.logInfoAndDebug(this.source, func, result, 'requestPermission');

      this.pushNotifications.addListener('pushNotificationReceived',
        (notification: PushNotificationSchema) => {
          this.logger.logInfoAndDebug(this.source, func, notification, 'pushNotificationReceived');
        }
      );

      this.pushNotifications.addListener('pushNotificationActionPerformed',
        (pushNotification: any) => {
          this.logger.logInfoAndDebug(this.source, func, pushNotification, 'pushNotificationActionPerformed');
          this.splashScreen.hide().then(() => {
            // ios
            if (pushNotification.notification.extra?.route) {
              this.utilsService.navigateForward(pushNotification.notification.extra.route);
            }
            // android
            if (pushNotification.notification.data?.route) {
              this.utilsService.navigateForward(pushNotification.notification.data.route);
            }
          });
        }
      );
    });
  }

  private async putDeviceData(deviceData: DeviceData) {
    const func = 'putDeviceData';
    if (!deviceData.appVersion) {
      deviceData.appVersion = environment.app.version;
    }
    this.utilsService.deviceData$.next(deviceData);
    const userId = await this.storageService.get(StorageKeys.userId);
    if (userId && userId !== environment.app.yeahShareUserId) {
      const deviceDataObj = new DeviceData(deviceData);
      deviceDataObj.cleanseForAPI();
      const deviceDataCloud: any = deviceDataObj;
      deviceDataCloud.userId = userId;
      this.apiService.put(this.source, func, ApiUrls.device, deviceDataCloud).subscribe((device) => {
        deviceData.id = device.id;
        this.utilsService.deviceData$.next(deviceData);
        this.logger.logInfoAndDebug(this.source, func, deviceDataCloud, 'API:' + ApiUrls.device);
        this.utilsService.deviceData$.next(device);
      }, err => {
        this.logger.logError(this.source, func, err, 'API:' + ApiUrls.device);
      });
    }
  }
  */

  private setupKeyboard() {
    // API
    Keyboard.setAccessoryBarVisible({ isVisible: true }).catch((error) => {
      // not implemented on android, will throw error
    });
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import FuzzySearch from 'fuzzy-search';

@Pipe({
  name: 'searchFilter'
})
export class SearchFilterPipe implements PipeTransform {

  transform<T extends object>(value: T[], term: string, fields?: (keyof T | string)[]): T[] {
    if (value && value[0] instanceof BehaviorSubject) {
      combineLatest(value).pipe().subscribe((searchVals) => {
        return this.handleSearch(searchVals, term, fields);
      });
    }
    return this.handleSearch(value, term, fields);
  }

  handleSearch<T extends object>(value: T[], term: string, fields?: (keyof T | string)[]) {
    if (!term) {
      return value;
    }
    if (!fields || fields.length === 0) {
      if (value && value.length) {
        fields = Object.keys(value[0]) as (keyof T)[];
      } else {
        return value;
      }
    }
    const fs = new FuzzySearch(value, fields, { caseSensitive: false, sort: true });
    return fs.search(term);
  }
}

import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ApiUrls } from '../enums/api-urls.enum';
import { AppRoutes } from '../enums/app-routes.enum';
import { App} from '@capacitor/app';
import { StorageKeys } from '../enums/storage-keys.enum';
import { DeviceData } from '../models/device-data';
import { Loader } from '../models/loader';
import { NewPassRequest } from '../models/new-pass-request';
import { RegisterRequest } from '../models/register-request';
import { RegisterResponse } from '../models/register-response';
import { SignInRequest } from '../models/sign-in-request';
import { SignInResponse } from '../models/sign-in-response';
import { User } from '../models/user';
import { UserProfile } from '../models/user-profile';
import { ApiService } from './api.service';
import { UtilsService } from './utils.service';
import { LoadingService } from './loading.service';
import { Logger } from './logger.service';
import { StorageService } from './storage.service';
import { HistoryService } from './history.service';
import { ProfileService } from './profile.service';
import { Device } from '@capacitor/device';
import { datadogRum } from '@datadog/browser-rum';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private source = 'AuthService';
  public user$ = new BehaviorSubject<User>(new User());
  public currentProfile$ = new BehaviorSubject<UserProfile>(null);
  public retainKeys = [StorageKeys.email, StorageKeys.musingHighlighted, StorageKeys.rememberMe, StorageKeys.noNetworkUrl, StorageKeys.logLineCache, StorageKeys.utm];
  private deviceData: DeviceData;
  private isNative = false;
  private app = App;
  private device = Device;

  constructor(private logger: Logger, private storageService: StorageService, private apiService: ApiService,
              private utilsService: UtilsService, private navController: NavController, private loadingService: LoadingService,
              private historyService: HistoryService, private profileService: ProfileService
) {
    this.utilsService.deviceData$.pipe().subscribe((deviceData) => {
      this.deviceData = deviceData;
    });
    this.utilsService.isNative$.pipe().subscribe((isNative) => {
      this.isNative = isNative;
    });
    this.subscribeToCurrentProfile();
  }

  public async isAuthenticated() {
    const jwt = await this.storageService.get(StorageKeys.jwt);
    if (jwt) {
      return this.whoAmI().pipe().subscribe((user) => {
        return this.setLoggedInUser(user).then(() => {
          return true;
        });
      });
    } else {
      return false;
    }
  }

  public loginWithPassword(req: SignInRequest): Observable<SignInResponse> {
    const func = 'loginWithPassword';
    req = new SignInRequest(req);
      req.includeToken = true;
      req.includeSecret = false;

    return this.apiService.post(this.source, func, ApiUrls.login, req).pipe(map((res) => {
      this.storageService.set(StorageKeys.loggingToken, res.loggingToken);
      return new SignInResponse(res);
    }, (error) => {
      return error;
    }));
  }

  public loginWithSecret(secret: string, renew: boolean): Observable<SignInResponse> {
    const func = 'loginWithSecret';
    const req = { secret, includeToken: false, includeSecret: false };
    req.includeToken = true;
    req.includeSecret = true;

    return this.apiService.post(this.source, func, ApiUrls.secretLogin, req).pipe(map((res) => {
      this.storageService.set(StorageKeys.loggingToken, res.loggingToken);
      return new SignInResponse(res);
    }, (error) => {
      return error;
    }));
  }

  public async loginWithToken(url: string) {
    const func = 'loginWithToken';
    const token = await this.storageService.get(StorageKeys.token);
    const email = await this.storageService.get(StorageKeys.email);
    if (token && email) {
      return this.loginWithTokenFromStorage(token, email, url);
    } else {
      return this.logout();
    }
  }

  private loginWithTokenFromStorage(token: string, email: string, url: string) {
    const func = 'loginWithTokenFromStorage';
    const req = { email, token, includeToken: false, includeSecret: false };
    req.includeToken = true;
    req.includeSecret = true;

    this.apiService.post(this.source, func, ApiUrls.tokenLogin, req)
      .subscribe((res) => {
        this.logger.logInfo(this.source, func, `tokenLogin: success`);
        this.finishLoginWithTokenFromStorage(res);
      }, (error) => {
        this.logger.logDebug(this.source, func, {error, url}, `tokenLogin: error`);
        this.logout();
      });
  }

  private async finishLoginWithTokenFromStorage(res: any) {
    const func = 'finishLoginWithTokenFromStorage';
    await this.storageService.remove(StorageKeys.jwt);
    await this.storageService.set(StorageKeys.jwt, res.jwt);
    await this.apiService.setJWT();
    if (res.token) {
      await this.storageService.set(StorageKeys.token, res.token);
    }
    if (res.secret) {
      await this.storageService.set(StorageKeys.secret, res.secret);
    }
    if (res.renewableSecret) {
      await this.storageService.set(StorageKeys.secretRenewable, res.renewableSecret);
    } else {
      await this.storageService.set(StorageKeys.secretRenewable, false);
    }
    this.whoAmI().subscribe((user) => {
      this.loadingService.loader$.next(new Loader(false, true));
      this.utilsService.isAuthenticated$.next(false);
      this.setLoggedInUser(user);
    }, (error) => {
      this.logger.logError(this.source, func, {error}, `whoAmI: error`);
      this.logout();
    });
  }

  public async refreshAuthUsingToken() {
    const func = 'refreshAuthUsingToken()';
    const token = await this.storageService.get(StorageKeys.token);
    const email = await this.storageService.get(StorageKeys.email);
    if (token && email) {
      const req = { email, token, includeToken: true, includeSecret: true };
      this.apiService.post(this.source, func, ApiUrls.tokenLogin, req)
      .subscribe((res) => {
        this.logger.logInfo(this.source, func, `tokenLogin: success`);
        this.finishRefreshAuthUsingToken(res);
      }, (error) => {
        this.logger.logDebug(this.source, func, {error}, `tokenLogin: error`);
        this.logout();
      });
    }
  }

  private async finishRefreshAuthUsingToken(res: any) {
    await this.storageService.remove(StorageKeys.jwt);
    await this.storageService.set(StorageKeys.jwt, res.jwt);
    await this.apiService.setJWT();
    if (res.token) {
      await this.storageService.set(StorageKeys.token, res.token);
    }
    if (res.secret) {
      await this.storageService.set(StorageKeys.secret, res.secret);
    }
    if (res.renewableSecret) {
      await this.storageService.set(StorageKeys.secretRenewable, res.renewableSecret);
    } else {
      await this.storageService.set(StorageKeys.secretRenewable, false);
    }
  }

  public register(req: RegisterRequest, userId: string, profileId: string): Observable<RegisterResponse> {
    const func = 'register';
    req = new RegisterRequest(req);

    req.includeToken = true;
    req.includeSecret = true;

    return this.apiService.put(this.source, func, `${ApiUrls.register}/${userId}/${profileId}`, req)
      .pipe(map((res) => {
        this.storageService.set(StorageKeys.loggingToken, res.loggingToken);
        return new RegisterResponse(res);
      }, (error) => {
        return error;
      }));
  }


  public forgotPassword(email: string): Observable<string> {
    const func = 'forgotPassword';
    return this.apiService.post(this.source, func, ApiUrls.forgotPassword, { email })
      .pipe(map((response) => {
        return response.message;
      }, (error) => {
        return error;
      }));
  }

  public setNewPassword(req: NewPassRequest): Observable<SignInResponse> {
    const func = 'setNewPassword';
    if (this.isNative) {
      req.includeToken = true;
      req.includeSecret = true;
    } else {
      req.includeSecret = true;
    }

    return this.apiService.post(this.source, func, ApiUrls.setPassword, req)
      .pipe(map((res) => {
        return res;
      }, (error) => {
        return error;
      }));
  }

  public whoAmI(): Observable<User> {
    const func = 'whoAmI';
    return this.apiService.get(this.source, func, ApiUrls.whoAmI)
      .pipe(map((res) => {
        return new User(res);
      }, (error) => {
        return error;
      }));
  }

  public setUserAndProfile(profileId: string): Observable<User> {
    const func = 'setUserAndProfile';
    return this.apiService.get(this.source, func, ApiUrls.whoAmI)
      .pipe(map((user) => {
        const currentUser = new User(user);
        this.user$.next(currentUser);
        const profile = currentUser.profiles.find((p) => {
          return p.id === profileId;
        });
        this.user$.next(currentUser);
        this.currentProfile$.next(profile);
        return user;
      }, (error) => {
        return error;
      }));
  }

  public async setLoggedInUser(user: User): Promise<UserProfile> {
    const func = 'setLoggedInUser';
    // find the default profile if they have one
    let currentProfile = user.profiles.find((profile) => {
      return profile.default === true;
    });
    if (!currentProfile) {
      currentProfile = user.profiles.find((profile) => {
        return profile.id;
      });
    }

    // check for utm
    let logUtm = false;
    const utmTracking = await this.storageService.get(StorageKeys.utm);
    if (utmTracking) {
      let utm: Array<any> = [];
      if (user.metadata.utm) {
        utm = user.metadata.utm;
      }
      const idx = utm.findIndex((utm) => utm.utm_id == utmTracking.utm_id);
      if(idx > -1) {
        utm[idx].views = utm[idx].views + 1;
        utm[idx].last = moment().format();
      } else {
        utmTracking.views = 1;
        utm.push(utmTracking);
        user.metadata.utm = utm;
      }
    }

    // if StorageKeys.currentProfileId is set, we will use that one instead below
    const getCurrentProfileId = await this.storageService.get(StorageKeys.currentProfileId);
    const setUserId = await this.storageService.set(StorageKeys.userId, user.id);
    const setEmail = await this.storageService.set(StorageKeys.email, currentProfile.email);
    return Promise.all([setEmail, setUserId, getCurrentProfileId]).then(([email, pass, currentProfileId]) => {
      if (currentProfileId) {
        const pIndex = user.profiles.findIndex((p) => p.id === currentProfileId);
        if (pIndex > -1) {
          currentProfile = user.profiles[pIndex];
        }
      }
      this.user$.next(user);
      this.currentProfile$.next(currentProfile);
      if (environment.logger.console) {
        this.logger.setLogLevels$.next(environment.logger.defaultLevels);
      } else {
        this.logger.setLogLevels$.next(user.logLevels ? user.logLevels : environment.logger.defaultLevels);
      }
      this.logger.retryTransmitLogLineToCloud.next(true);

      // set RUM user
      const fullName = `${currentProfile.firstName} ${currentProfile.lastName}`;
      const rumUser: any = {
        id: user.id,
        name: fullName,
        email: currentProfile.email
      };
      if (utmTracking && rumUser.utm_id) rumUser.utm_id = utmTracking.utm_id;
      if (utmTracking && rumUser.utm_source) rumUser.utm_source = utmTracking.utm_source;
      if (utmTracking && rumUser.utm_medium) rumUser.utm_medium = utmTracking.utm_medium;
      datadogRum.setUser(rumUser);

      // update device data and utm
      let updateDevicedata = false;
      if (currentProfile.userId && currentProfile.userId !== environment.app.yeahShareUserId) {
        updateDevicedata = true;
      }
      if (utmTracking || updateDevicedata) {
        return this.putDeviceData(utmTracking ? user : null, currentProfile);
      } else {
        return currentProfile;
      }
    });
  }

  private subscribeToCurrentProfile() {
    combineLatest([this.user$, this.currentProfile$])
     .subscribe(([user, profile]) => {
        if (user && profile) {
          this.apiService.currentProfileId = profile.id;
          this.apiService.currentProfile = profile;
          this.storageService.remove(StorageKeys.currentProfileId).finally(() => {
            this.storageService.set(StorageKeys.currentProfileId, profile.id).finally(() => {
              if (!this.utilsService.isAuthenticated$.getValue()) {
                this.utilsService.isAuthenticated$.next(true);
              }
            });
          });
        }
      });
  }

  private async putDeviceData(user: User, currentProfile: UserProfile): Promise<UserProfile> {
    const func = 'putDeviceData';
    if (!(this.deviceData && this.deviceData.deviceUuid)) {
      await this.getDeviceInfo();
    }
    const deviceData = new DeviceData(this.deviceData);
    if (!deviceData.appVersion) {
      deviceData.appVersion = environment.app.version;
    }
    this.utilsService.deviceData$.next(deviceData);
    deviceData.cleanseForAPI();
    const deviceDataCloud: any = deviceData;
    deviceDataCloud.userId = currentProfile.userId;
    return this.apiService.put(this.source, func, ApiUrls.device, deviceDataCloud).toPromise().then((device) => {
      this.deviceData.id = device.id;
      this.utilsService.deviceData$.next(this.deviceData);
      this.logger.logInfoAndDebug(this.source, func, deviceDataCloud, 'API:' + ApiUrls.device);
      return this.putUser(user, currentProfile);
    }, err => {
      this.logger.logError(this.source, func, err, 'API:' + ApiUrls.device);
      return this.putUser(user, currentProfile);
    });
  }

  private async putUser(user: User, currentProfile: UserProfile): Promise<UserProfile> {    
    const func = 'putUser';
    await this.storageService.remove(StorageKeys.utm);

    if (user) {
      const u = new User(user);
      u.cleanseForAPI();
      return this.apiService.put(this.source, func, `${ApiUrls.user}/${u.id}`, u).toPromise().then(() => {
        this.logger.logInfoAndDebug(this.source, func, user, 'API:' + ApiUrls.user);
        return Promise.resolve(currentProfile);
      }, err => {
        this.logger.logError(this.source, func, err, 'API:' + ApiUrls.device);
        return Promise.resolve(currentProfile);
      });
    } else {
      return Promise.resolve(currentProfile);
    }
  }

  private async getDeviceInfo() {
    const func = 'getDeviceInfo';
    await Promise.all([this.device.getInfo(), this.device.getId()])
      .then(([result, deviceId]) => {
        this.logger.logInfoAndDebug(this.source, func, result, 'device.getInfo');
        const deviceData = new DeviceData(result);
        this.logger.logInfoAndDebug(this.source, func, deviceId, 'device.getId');
        deviceData.deviceUuid = deviceId.identifier;
        this.logger.logInfoAndDebug(this.source, func, deviceData, 'deviceData');
        this.utilsService.deviceData$.next(deviceData);
      }).catch(err => console.error(err));
  }

  // no network
  public async noNetwork(url: string) {
    const func = 'noNetwork';
    this.logger.logInfo(this.source, func, 'url: ' + url);
    this.loadingService.loader$.next(new Loader(false, true));
    return this.storageService.set(StorageKeys.noNetworkUrl, url).then(() => {
      return this.utilsService.navigateForward(AppRoutes.noNetwork);
    });
  }

  // logout
  public async logout() {
    const func = 'logout';
    this.logger.logInfo(this.source, func, null);
    if (this.utilsService.isAuthenticated$.getValue()) {
      return this.apiService.get(this.source, func, `${ApiUrls.logout}${ApiUrls.device}/${this.deviceData.id}`).pipe()
        .subscribe(() => {
          return this.finishLogOut();
        }, (err) => {
          // TODO if jwt expoired then get a new one to logout with
          this.logger.logError(this.source, func, err, 'API:' + ApiUrls.logout);
          return this.finishLogOut();
        });
    } else {
      return this.finishLogOut();
    }
  }

  private async finishLogOut() {
    const func = 'finishLogOut';
    this.utilsService.isAuthenticated$.next(false);
    this.logger.logDebug(this.source, func, null, null);
    this.apiService.removeJWT();
    this.loadingService.loader$.next(new Loader(false, true));
    this.apiService.currentProfileId = null;
    this.apiService.currentProfile = null;
    await this.removeKeys();
    return this.utilsService.navigateRoot(AppRoutes.home);
  }

  public async prepForNewLogin() {
    const func = 'finishLogOut';
    this.utilsService.isAuthenticated$.next(false);
    this.logger.logDebug(this.source, func, null, null);
    this.apiService.removeJWT();
    this.loadingService.loader$.next(new Loader(false, true));
    this.historyService.clearHistory();
    this.apiService.currentProfileId = null;
    this.apiService.currentProfile = null;
    return await this.removeKeys();
  }

  public async removeKeys() {
    const removeKeys = Object.values(StorageKeys);
    for (const key of removeKeys) {
      if (!this.retainKeys.includes(key)) {
        await this.storageService.remove(key as StorageKeys);
      }
    }
  }
}

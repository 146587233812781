<ion-header>
  <ion-toolbar mode="md" color="secondary">
    <ion-buttons slot="end">
      <ion-button color="primay" fill="solid" type="button" color="primary" fill="clear" (click)="dismiss()">
        Close
        <ion-icon slot="end" name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      Contact us
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">

    <h2 class="auth-title">Send us a message</h2>
    <div>
      Send us an email and we'll follow up with you soon.
    </div>
    <div class="ion-padding flex-row align-center">
      <div class="fs-18">
        support@yeahshare.com
      </div>
      <div>
        <ion-button (click)="copyLink()" color="primary" fill="clear" class="toolbar-button">
          <ion-icon name="clipboard-outline"></ion-icon>
        </ion-button>
      </div>
    </div>
    <!--
    <p class="recover-message">
      Please enter your email address and leave us a message. We will follow up with you soon.
    </p>
    <form [formGroup]="contactUsForm">
      <ion-list class="inputs-list" lines="full">
        <ion-item class="input-item">
          <ion-label color="tertiary">Email:</ion-label>
          <ion-input type="email" placeholder="name@example.com" formControlName="email" clearInput autocapitalize="off" inputmode="email"></ion-input>
        </ion-item>
        <div class="error-container">
          <ng-container *ngFor="let validation of validation_messages.email">
            <div class="error-message" *ngIf="contactUsForm.get('email').hasError(validation.type) && (contactUsForm.get('email').dirty || contactUsForm.get('email').touched)">
              <ion-icon name="information-circle-outline"></ion-icon>
              <span>{{ validation.message }}</span>
            </div>
          </ng-container>
        </div>
      </ion-list>

      <ion-button class="reset-password-btn" type="submit" expand="block" [disabled]="!contactUsForm.valid" (click)="submitFormOnEnterPress()">Send</ion-button>
    </form>
    -->
  
</ion-content>

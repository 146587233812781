import { Injectable } from '@angular/core';
import { NavController, Platform } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { Logger } from '../services/logger.service';
import { DeviceData } from '../models/device-data';
import { HistoryService } from './history.service';
import { environment } from '../../environments/environment';
import { Platforms } from '../enums/platforms.enum';
import { ScreenInfo } from '../models/screen';
import { Orientation } from '../enums/orientation';
import { Device, DeviceInfo} from '@capacitor/device';
import { AppRoutes } from '../enums/app-routes.enum';
import { UploadService } from './upload.service';
import { LinkedInUpload } from '../models/linkedin-upload';
import { LoadingService } from './loading.service';
import { Loader } from 'app/models/loader';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  private source = 'UtilsService';
  public isNative$ = new BehaviorSubject<boolean>(false);
  public isAuthenticated$ = new BehaviorSubject<boolean>(false);
  public isCapacitor$ = new BehaviorSubject<boolean>(false);
  public platform$ = new BehaviorSubject<Platforms>(null);
  public screenInfo$ = new BehaviorSubject<ScreenInfo>(new ScreenInfo());
  public deviceData$ = new BehaviorSubject<DeviceData>(null);
  public isAppUpdateModalShowing$ = new BehaviorSubject<boolean>(false);
  public zipFile$ = new BehaviorSubject<Blob>(null);
  public accountEmail$ = new BehaviorSubject<string>(null);
  public accountFirstName$ = new BehaviorSubject<string>(null);
  public accountLastName$ = new BehaviorSubject<string>(null);
  public animated3dot$ = new BehaviorSubject<string>('...');
  public actionDisabledEnd$ = new BehaviorSubject<boolean>(false);
  public noWelcome$ = new BehaviorSubject<boolean>(false);

  public handleNewUpload$ = new BehaviorSubject<boolean>(false);
  public watchForNewUpload$ = new BehaviorSubject<boolean>(false);
  public showUploadError$ = new BehaviorSubject<boolean>(false);
  public processingUpload$ = new BehaviorSubject<boolean>(false);
  public latestUpload$ = new BehaviorSubject<LinkedInUpload>(null);

  private device = Device;
  private isNative: boolean;
  private zipFile: Blob;

  // skip routes on pop
  private skipRoutesOnPop = [];

  constructor(
      private logger: Logger, private platform: Platform, private navController: NavController,
      private historyService: HistoryService, private loadingService: LoadingService,
      private uploadService: UploadService
      // referenced by ApiService for isAuthenticated$
  ) {
    this.isCapacitor$.next(this.platform.is(Platforms.capacitor));
    this.subscribeToZipFile();
  }

  // display - set via app page
  public getScreenInfo(resize?: boolean): ScreenInfo {
    const func = 'getScreenInfo';
    let largeView = false;
    if (window.innerWidth >= environment.settings.largeViewSize &&
      (!(this.isNative || this.platform.is('mobileweb')) ||
        ((this.isNative || this.platform.is('mobileweb')) && window.innerWidth > environment.settings.largeViewSizeNative)
    )) {
      largeView = true;
    }
    let orientation = Orientation.unknown;
    if (this.platform.isPortrait() && !this.platform.isLandscape()) {
      orientation = Orientation.portrait;
    }
    if (this.platform.isLandscape() && !this.platform.isPortrait()) {
      orientation = Orientation.landscape;
    }
    const screenInfo = new ScreenInfo({
      width: window.innerWidth,
      height: window.innerHeight,
      orientation,
      isLargeView: largeView
    });
    this.logger.logDebug(this.source, func, screenInfo, `'resize: '${resize}`);
    this.screenInfo$.next(screenInfo);
    return screenInfo; 
  }

  public async getIsNative(): Promise<boolean> {
    return this.device.getInfo().then((deviceInfo) => {
      this.platform$.next(this.getPlatform(deviceInfo));
      if (deviceInfo && deviceInfo.platform && (deviceInfo.platform === 'ios' || deviceInfo.platform === 'android')) {
        return Promise.resolve(true);
      } else {
        return Promise.resolve(false);
      }
    });
  }

  private getPlatform(deviceInfo: DeviceInfo): Platforms {
    let platform = null;
    if (deviceInfo && deviceInfo.platform) {
      switch (deviceInfo.platform) {
        case 'ios':
          platform = Platforms.ios;
          break;
        case 'android':
          platform = Platforms.android;
          break;
        case 'web':
          if (this.platform.is('mobileweb')) {
            platform = Platforms.mobileweb;
          } else {
            platform = Platforms.pwa;
          }
          break;
      }
      return platform;
    }
  }

  // isLargeView
  public isLargeView(): boolean {
    let largeview = false;
    if (window.innerWidth >= environment.settings.largeViewSize &&
      (!(this.isNative || this.platform.is('mobileweb')) ||
        ((this.isNative || this.platform.is('mobileweb')) && window.innerWidth > environment.settings.largeViewSizeNative)
    )) {
      largeview = true;
    }
    return largeview;
  }

  public isMobileWeb() {
    return this.platform.is('mobileweb');
  }

  // nav
  public navigateBack(url): Promise<boolean> {
    if (!this.isLargeView()) {
      return this.navController.navigateBack(url).then(() => {
        return this.historyService.navigateBack(url).then(() => {
          this.afterNavigateBackOrPopActions(url);
          return Promise.resolve(true);
        });
      });
    } else {
      return this.navController.navigateBack(url);
    }
  }

  public navigateForward(url): Promise<boolean> {
    if (!this.isLargeView()) {
      return this.navController.navigateForward(url).then(() => {
        return this.historyService.navigateForward(url);
      });
    } else {
      return this.navController.navigateForward(url);
    }
  }

  public routerLinkForward(url): Promise<boolean> {
    return this.historyService.routerLinkForward(url);
  }

  public navigateRoot(url): Promise<boolean> {
    this.historyService.clearHistory();
    return this.navController.navigateRoot(url).then(() => {
      return this.historyService.navigateRoot(url);
    });
  }

  private afterNavigateBackOrPopActions(url: string) {
    // refetch etc.
  }

  public currentRoute(): string {
    let url: string = null;
    if (this.historyService.appHistory.length > 1) {
      const currentRoute: string = this.historyService.appHistory[this.historyService.appHistory.length - 1];
      let index = currentRoute.indexOf('spc=');
      if (index > -1) {
        url = decodeURIComponent(currentRoute.substring(index + 4));
      } else {
        url = decodeURIComponent(currentRoute);
      }
    }

    return url;
  }

  public previousRoute(): string {
    let url: string = '';
    if (this.historyService.appHistory.length > 1) {
      const previousRoute: string = this.historyService.appHistory[this.historyService.appHistory.length - 2];
      let index = previousRoute.indexOf('spc=');
      if (index > -1) {
        url = decodeURIComponent(previousRoute.substring(index + 4));
      } else {
        url = decodeURIComponent(previousRoute);
      }
    }

    return url;
  }

  // back
  public back() {
    let backRoute: string = null;
    // backrout is used for overrides

    if (backRoute) {
      this.navigateBack(backRoute).finally(() => {
        // deselect etc.
      });
    } else {
      if (this.historyService.appHistory.length < 2) {
        if (this.isAuthenticated$.getValue()) {
          this.navigateRoot(AppRoutes.record);
        } else {
          this.navigateRoot(AppRoutes.home);
        }
      } else {
        this.pop();
      }
    }
  }

  private pop() {
    // skip if pop leads to skip route
    let skip = false;
    let idx = this.historyService.appHistory.length - 2;
    while (idx > -1 && this.skipRoutesOnPop.find((route) => route === this.historyService.appHistory[idx])) {
      skip = true;
      idx--;
    }

    if (skip) {
      this.navigateBack(this.historyService.appHistory[idx]);
    } else {
      if (!this.isLargeView()) {
        this.navController.pop().then(() => {
          this.historyService.pop().then(() => {
            if (this.historyService.appHistory.length && (this.historyService.appHistory.length - 1) >= 0) {
              this.afterNavigateBackOrPopActions(this.historyService.appHistory[this.historyService.appHistory.length - 1]);
            }
            return Promise.resolve(true);
          });
        });
      } else {
        this.navController.back();
      }
    }
  }

  // routing
  public route(route: AppRoutes) {
    switch (route) {
      // unauthenticated
      case (AppRoutes.home):
        this.navigateRoot(AppRoutes.home);
        break;
      case (AppRoutes.login):
        this.navigateForward(AppRoutes.login);
        break;
      case (AppRoutes.signup):
        this.navigateForward(AppRoutes.signup);
        break;
      case (AppRoutes.forgotPassword):
        this.navigateForward(AppRoutes.forgotPassword);
        break;
      case (AppRoutes.share):
        this.navigateForward(AppRoutes.share);
        break;
      // mixed
      case (AppRoutes.back):
        this.back();
        break;
      case (AppRoutes.about):
        window.open('https://www.buymeacoffee.com/yeahshare', '_blank');
        break;
      case (AppRoutes.logout):
        this.navigateRoot(AppRoutes.logout);
        break;
      // authenticated
      case (AppRoutes.profile):
        this.navigateForward(AppRoutes.profile);
        break;
      case (AppRoutes.shares):
        this.navigateForward(AppRoutes.shares);
        break;
      case (AppRoutes.uploads):
        this.navigateForward(AppRoutes.uploads);
        break;
      case (AppRoutes.record):
        if (this.isAuthenticated$.getValue()) {
          this.navigateRoot(AppRoutes.record);
        } else {
          this.navigateRoot(AppRoutes.home);
        }
        break;
    }
  }

  // animations
  public animate3dot(animated3dot) {
    setTimeout(() => {
      switch (animated3dot) {
        case ('...'):
          this.animated3dot$.next('');
          break;
        case (''):
          this.animated3dot$.next('.');
          break;
        case ('.'):
          this.animated3dot$.next('..');
          break;
        case ('..'):
          this.animated3dot$.next('...');
          break;
      }
    }, 500);
  }
  
  // upload file
  private subscribeToZipFile() {
    this.zipFile$.subscribe((zipFile) => {
      this.zipFile = zipFile;
    });
  }

  public uploadFile() {
    if (this.zipFile) {
      this.uploadService.post(this.zipFile).subscribe({
        next: (status) => {
          this.zipFile$.next(null);
          this.watchForNewUpload$.next(true);
        },        
        error: (error) => {
          this.zipFile$.next(null);
          this.showUploadError$.next(true);
        }
      });
    }
  }
}

<ion-header>
  <ion-toolbar mode="md" color="secondary">
    <ion-buttons slot="end">
      <ion-button color="primay" fill="solid" type="button" color="primary" fill="clear" (click)="dismiss()">
        Close
        <ion-icon slot="end" name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      FAQ
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <div>
    <div class="faq-question">
      I already use LinkedIn. What is YeahShare?
    </div>
    <div>
      <p>Yeahshare delivers an easily understood overview of the who, what, and where of your professional LinkedIn network. The data yeahshare surfaces can be used to better engage the colleagues, peers, and connections who are the key to your career development and business goals.</p>
      <p>Just as importantly, yeahshare makes it easy to share the network you’ve built with job hunters, recruiters, teammates, and investors who will benefit from instant visibility into your relationships.</p>
    </div>
    <div class="faq-question">
      Do I need anything special to use YeahShare?
    </div>
    <div>
      <p>Yeahshare is a progressive web app accessible on any major browser. To power the delivered record, yeahshare requires the user to upload their LinkedIn Data Archive.</p>
    </div>
    <div class="faq-question">
      What is a yeahshare record?
    </div>
    <div>
      <p>A record is a visualized online snapshot in time of the company geographic locations, organizations, connections messaged, and job titles represented within the user’s connected LinkedIn network.</p>
    </div>
    <div class="faq-question">
      Who has access to my record?
    </div>
    <div>
      <p>The yeahshare record is private to the yeahshare user unless shared. If shared, the yeahshare record is tracked and an email is required for a recipient to access the record. Recipient views are tracked.</p>
    </div>
    <div class="faq-question">
      What are my record sharing limits?
    </div>
    <div>
      <p>A recommended best practice is to carefully choose which sections of the record you wish to share with the recipient and to also only share the desired amount within each section.</p>
      <p>For example: The default number of locations, companies, message recipients, and job titles in network shared is 10.</p>
    </div>
  </div>
  <div class="ion-text-end fs-14">
    App Version: {{appVersion}}
  </div>
</ion-content>

export enum Platforms {
  android = 'android',
  capacitor = 'capacitor',
  cordova = 'cordova',
  desktop = 'desktop',
  electron = 'electron',
  hybrid = 'hybrid',
  ios = 'ios',
  ipad = 'ipad',
  iphone = 'iphone',
  mobile = 'mobile',
  mobileweb = 'mobileweb',
  phablet = 'phablet',
  pwa = 'pwa',
  tablet = 'tablet'
}

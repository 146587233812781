import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Loader } from '../models/loader';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  public loader$ = new BehaviorSubject<Loader>(new Loader(false, false));
  private callCount = 0;

  constructor() {
    this.loader$.subscribe((loader: Loader) => {
      this.manageLoader(loader);
    });
  }

  private manageLoader(loader: Loader) {
    if (loader.isShowing) {
      this.callCount++;
    } else if (loader.doDismiss) {
      this.callCount--;
      if (this.callCount < 0) {
        this.callCount = 0;
      }
    }
    if (loader.doDismiss && loader.dismissAll) {
      this.callCount = 0;
    }
  }
}

import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { StorageKeys } from '../enums/storage-keys.enum';
import { Logger } from './logger.service';
import { StorageService } from './storage.service';
import { ApiUrls } from '../enums/api-urls.enum';
import { UserProfile } from '../models/user-profile';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  public jwt: string;
  public currentProfileId: string;
  public currentProfile: UserProfile;
  private readonly apiBaseUrl: string;
  private headers = new HttpHeaders();
  private correlationIdHeader = 'CorrelationId';

  constructor(
    private http: HttpClient,
    private storageService: StorageService,
    private logger: Logger
  ) {
    this.apiBaseUrl = `${environment.api.baseUrl}`;
    this.setJWT();
  }

  public async setJWT() {
    const jwt = await this.storageService.get(StorageKeys.jwt);
    this.jwt = jwt;
    return this.jwt;
  }

  public removeJWT() {
    this.jwt = null;
    this.headers = this.headers.delete(environment.api.headers.authorization.key);
  }

  /**
   * HTTP GET request
   */
  public get(source: string, func: string, path: string, params?: any): Observable<any> {
    this.setHeaders();
    return this.http.get(`${this.buildUrl(path)}`, {
      params: this.constructParams(params),
      headers: this.headers,
      observe: 'response'
    }).pipe(map((res) => {
      this.logger.logDebug(source, func,
        { verb: 'GET', request: null, status: res.status, response: res.body },
        res.url, res.headers.get(this.correlationIdHeader));
      return res.body;
    }), catchError((error) => {
      error.error.status = error.status;
      this.logger.logError(source, func,
        { verb: 'GET', request: null, status: error.status, response: error.body },
        error.url, error.headers.get(this.correlationIdHeader));
      return observableThrowError(error.error);
    }));
  }

    /**
   * HTTP GET request - return status only
   */
     public getStatus(source: string, func: string, path: string, params?: any): Observable<number> {
      this.setHeaders();
      return this.http.get(`${this.buildUrl(path)}`, {
        params: this.constructParams(params),
        headers: this.headers,
        observe: 'response'
      }).pipe(map((res) => {
        this.logger.logDebug(source, func,
          { verb: 'GET', request: null, status: res.status, response: res.body },
          res.url, res.headers.get(this.correlationIdHeader));
        return res.status;
      }), catchError((error) => {
        error.error.status = error.status;
        this.logger.logError(source, func,
          { verb: 'GET', request: null, status: error.status, response: error.body },
          error.url, error.headers.get(this.correlationIdHeader));
        return observableThrowError(error.error);
      }));
    }

  /**
   * HTTP PUT request
   */
  public put(source: string, func: string, path: string, payload: any): Observable<any> {
    this.setHeaders();
    return this.http.put(`${this.buildUrl(path)}`, payload, {
      params: this.constructParams(),
      headers: this.headers,
      observe: 'response'
    }).pipe(map((res) => {
      this.logger.logDebug(source, func,
        { verb: 'PUT', request: payload, status: res.status, response: res.body },
        res.url, res.headers.get(this.correlationIdHeader));
      return res.body;
    }), catchError((error) => {
      error.error.status = error.status;
      this.logger.logError(source, func,
        { verb: 'PUT', request: payload, status: error.status, response: error.body },
        error.url, error.headers.get(this.correlationIdHeader));
      return observableThrowError(error.error);
    }));
  }

  /**
   * HTTP POST request
   */
  public post(source: string, func: string, path: string, payload: any, params?: any, headers?: any): Observable<any> {
    this.setHeaders();

    if (payload && payload.id) {
      delete payload.id;
    }

    return this.http.post(`${this.buildUrl(path)}`, payload, {
      params: this.constructParams(params),
      headers: this.constructHeaders(headers),
      observe: 'response'
    }).pipe(map((res) => {
      this.logger.logDebug(source, func,
        { verb: 'POST', request: payload, status: res.status, response: res.body },
        res.url, res.headers.get(this.correlationIdHeader));
      return (res as any).body;
    }), catchError((error) => {
      error.error.status = error.status;
      this.logger.logError(source, func,
        { verb: 'POST', request: payload, status: error.status, response: error.body },
        error.url, error.headers.get(this.correlationIdHeader));
      return observableThrowError(error.error);
    }));
  }

  /**
   * HTTP DELETE request
   */
  public delete(source: string, func: string, path: string): Observable<any> {
    this.setHeaders();
    return this.http.delete(`${this.buildUrl(path)}`, {
      params: this.constructParams(), headers: this.headers,
      observe: 'response'
    }).pipe(map((res) => {
      this.logger.logDebug(source, func,
        { verb: 'DELETE', request: null, status: res.status, response: res.body },
        res.url, res.headers.get(this.correlationIdHeader));
      return (res as any).body;
    }), catchError((error) => {
      error.error.status = error.status;
      this.logger.logError(source, func,
        { verb: 'DELETE', request: null, status: error.status, response: error.body },
        error.url, error.headers.get(this.correlationIdHeader));
      return observableThrowError(error.error);
    }));
  }

  public buildUrl(path: string): string {
    return `${this.apiBaseUrl.includes('http') ? '' : window.location.origin}${this.apiBaseUrl}${path}`;
  }

  private constructParams(object = {}): HttpParams {
    if (!object) {
      object = {};
    }
    let params = new HttpParams();
    for (const key in object) {
      if (key && object[key]) {
        params = params.append(key, object[key]);
      }
    }
    return params;
  }

  private constructHeaders(object = {}): HttpHeaders {
    let headers = this.headers;
    for (const key in object) {
      if (key && object[key]) {
        headers = headers.append(key, object[key]);
      }
    }
    return headers;
  }

  /**
   * Sets all the headers for the api service
   */
  private setHeaders() {
    if (this.jwt) {
      this.headers = this.headers.set(environment.api.headers.authorization.key,
        `${environment.api.headers.authorization.value} ${this.jwt}`);
    } else {
      this.headers = this.headers.delete(environment.api.headers.authorization.key);
    }

    if (!this.headers.has(environment.api.headers.xApiKey.key)) {
      this.headers = this.headers.append(environment.api.headers.xApiKey.key, environment.api.headers.xApiKey.value);
    }

    if (!this.headers.has(environment.api.headers.subIdentity.key)) {
      const currentProfileId = this.currentProfileId || 'none';
      this.headers = this.headers.append(environment.api.headers.subIdentity.key, currentProfileId);
    }

    if (this.headers.has(environment.api.headers.subIdentity.key) &&
      this.headers.get(environment.api.headers.subIdentity.key) !== this.currentProfileId) {
      const currentProfileId = this.currentProfileId || 'none';
      this.headers = this.headers.set(environment.api.headers.subIdentity.key, currentProfileId);
    }
  }

  /**
   * Paging and expand params
   */

  public pagingParams(page: number, pageSize: number, params?: string): string {
    if (!params || params === '' && (page && pageSize)) {
      params = '?'
    }
    if (page && pageSize) {
      params += `page=${page}&page_size=${pageSize}`;
    }
    return params
  }

  public locationParams(city: string, state: string, country: string, params?: string): string {
    if ((!params || params === '') && (city || state || country)) {
      params = '?'
    }
    if (city && city.length > 0) {
      params += `${params.length > 1 ? '&' : ''}city=${city}`
    }
    if (state && state.length > 0) {
      params += `${params.length > 1 ? '&' : ''}state=${state}`
    }
    if (country && country.length > 0) {
      params += `${params.length > 1 ? '&' : ''}country=${country}`
    }
    return params
  }

  public companyParams(company: string, params?: string): string {
    if ((!params || params === '') && company) {
      params = '?'
    }
    if (company && company.length > 0) {
      params += `${params.length > 1 ? '&' : ''}company=${company}`
    }
    return params
  }

  public positionParams(position: string, params?: string): string {
    if ((!params || params === '') && position) {
      params = '?'
    }
    if (position && position.length > 0) {
      params += `${params.length > 1 ? '&' : ''}position=${position}`
    }
    return params
  }

  public expandParams(expand:  Array<string>, params?: string) {
    if ((!params || params === '') && (expand && expand.length > 0)) {
      params = '?'
    }
    expand?.forEach((e, i) => {
      if (i === 0) {
        params += 'expand=';
      }
      if (i !== 0) {
        params += ',';
      }
      params += `${e}`;
    });
    if (!params) {
      return '';
    }
    return params
  }
}

<div class="dash-section-container">
  <h1>Roles Driving My Network</h1>
  <div class="visual-border-bottom" id="chartBlock" style="width: 100%; height: 40vh;" echarts [options]="chartOption"></div>

  <app-musings [musingContext]="'network'" [shareDisplay$]="shareDisplay$"></app-musings>

  <h2 id="network-top">Top Roles</h2>
  <ion-list>
    <ion-item *ngFor="let net of displayedNetworks; let i = index" (click)="openConnModal(net.title, 'position')" class="cursor-pointer">
      <ion-row class="ion-align-items-center width-full">
        <ion-col>
          <div class="flex-row align-center">
            <div class="item-text-number">{{ i + 1 }}.</div>
            <div class="item-text clamp-2" [innerHTML]="net.title"></div>
          </div>
        </ion-col>
        <ion-col *ngIf="!shareDisplay" size="auto" class="rank-text-percentage" [innerHTML]="formatPercent(net.percentOfConnections)"></ion-col>
        <ion-col size="auto" class="rank-text">{{ net.numConnections }}</ion-col>
      </ion-row>
    </ion-item>
    <ion-item lines="none" *ngIf="showMore || displayedNetworks?.length > 10">
      <div *ngIf="showMore" class="more-button" (click)="getMoreNetworks()">More</div>
      <ion-button *ngIf="displayedNetworks?.length > 10" (click)="resetNetworks(true)" color="medium" fill="outline">
        <ion-icon name="chevron-up-outline"></ion-icon>
      </ion-button>
    </ion-item>
  </ion-list>

  <div *ngIf="isBigWig && showBigWigOnDashboard" class="bigwig-stuff">
    <div class="title">Bigwig Stuff</div>
    <p>{{ formatPercent(noTitlePercent) }} undetermined</p>
  </div>
</div>


import { Injectable } from '@angular/core';
declare var gtag;

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {
  private id: any;

  constructor() { }

  startTrackerWithId(id) {
    this.id = id;

    gtag('config', id, {
      send_page_view: false
    });
    gtag('get', id, 'client_id', (client_id) => {
      console.log('ga started')
    });
  }

  trackView(title: string, location: string, path: string) {
    gtag('get', this.id, 'client_id', (client_id) => {
      gtag('event', 'page_view'), {
        page_title: title,
        page_location: location,
        client_id: client_id
      }
    });
  }
}
import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CardActionsEvent } from '../../models/card-actions-event';
import { BaseComponent } from '../../components/base/base.component';
import { CardActionsMenu } from '../../models/card-actions-menu';

@Component({
  selector: 'app-card-action-popover',
  templateUrl: './card-action-popover.component.html',
  styleUrls: ['./card-action-popover.component.scss']
})
export class CardActionPopoverComponent extends BaseComponent implements OnInit {

  constructor(public popoverController: PopoverController) {
    super();
  }
  @Input() actions$ = new BehaviorSubject<Array<CardActionsEvent>>([]);
  @Input() menus$ = new BehaviorSubject<Array<CardActionsMenu>>([]);
  public title: string;
  public titleEnabled = false;
  public actions: Array<CardActionsEvent>;
  private menus: Array<CardActionsMenu>;

  /**
   * Set a manual y offset for popovers
   * this only applies to popovers using cssClass: ['popover-offset-y']
   */
  static setStylePropPopoverOffsetY(px: `${number}px`) {
    const root = document.documentElement;
    root.style.setProperty('--ys-popover-offset-y', px)
  }

  ngOnInit() {
    this.subscribeToActions();
    this.subscribeToMenus();
  }

  private subscribeToActions() {
    this.actions$.pipe(takeUntil(this.destroyed$)).subscribe((actions) => {
      this.actions = actions;
    });
  }

  private subscribeToMenus() {
    this.menus$.pipe(takeUntil(this.destroyed$)).subscribe((menus) => {
      this.menus = menus;
    });
  }

  public actionClicked(val: any) {
    const subMenu = this.menus.find((menu) => {
      return menu.key === val;
    });
    if (subMenu &&
      this.actions.find((action) => {
        return action.action === val;
      }).isSubmenu
    ) {
      this.title = subMenu.title ? subMenu.title : null;
      this.titleEnabled = subMenu.titleEnabled;
      this.actions = this.menus.find((menu) => {
        return menu.key === val;
      }).actions;
      setTimeout(() => {
        // wait long enough for browser to paint the submenu
        // before checking content bounds and adjusting the y offset
        // this only applies to popovers using cssClass: ['popover-offset-y']
        // TODO code smell - avoid magic timeouts!
        this.ensurePopoverIsWithinPageBounds();
      }, 25);
    } else {
      this.popoverController.dismiss(val);
    }
  }

  /**
   * Checks if the popover bottom is outside of the page bounds and adds the necessary y offset
   * this only applies to popovers using cssClass: ['popover-offset-y']
   */
  ensurePopoverIsWithinPageBounds() {
    const p = document.querySelector('.popover-viewport')
    const {height, top} = p?.getBoundingClientRect() || {height: 0, top: 0};
    const bottom = height + top;
    const isOffScreen = bottom > window.innerHeight;

    if (isOffScreen) {
      const offset = window.innerHeight - bottom;
      CardActionPopoverComponent.setStylePropPopoverOffsetY(`${offset}px`);
    }
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UserProfile } from '../../models/user-profile';
import { BaseComponent } from '../base/base.component';
import { LinkedInConnection } from '../../models/linkedin-connection';
import { InfiniteScrollCustomEvent } from '@ionic/angular';
import { ConnectionsService } from '../../services/connections.service';
import { takeUntil } from 'rxjs/operators';
import { ViewChild } from '@angular/core';
import { EChartsOption } from 'echarts';
import { ElementRef } from '@angular/core';
import { SummaryService } from '../../services/summary.service';
import { TitleResponse } from '../../models/title-response';
import { CompanySummary, CompanySummaryResponse } from '../../models/company-summary';

@Component({
  selector: 'app-company-summary',
  templateUrl: './company-summary.component.html',
  styleUrls: ['./company-summary.component.scss']
})
export class CompanySummaryComponent extends BaseComponent implements OnInit {
  @Input() openConnectionsModal: (searchTerm?: string, searchType?: string) => void;
  @Input() shareDisplay$: BehaviorSubject<string>;
  @Input() companies$: BehaviorSubject<CompanySummaryResponse>;
  public shareDisplay: string;

  public chartOption: EChartsOption

  public lastIndex = 0;
  public showMore = true;
  public companies: Array<CompanySummary>;
  public companiesRepsonse: CompanySummaryResponse;
  public displayedCompanies: Array<CompanySummary>;
  public noCompanyPercent: number;

  @ViewChild('chartBlock') chartBlock: ElementRef;

  constructor(private summaryService: SummaryService) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.subscribeToShareDisplay();
    this.subscribeToCompanies();
  }

  /*
  Subscriptions
  */

  private subscribeToShareDisplay() {
    this.shareDisplay$.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (shareDisplay) => {
        this.shareDisplay = shareDisplay;
      },
      error: (error) => {
        this.handleError(error);
      }
    });
  }

  private subscribeToCompanies() {
    this.companies$.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (comps) => {
        if (comps && comps.companies.length > 0) {
          const undetermined = comps.companies.find((c) => c.companyName === 'undetermined');
          if (undetermined) {
            this.noCompanyPercent = undetermined.percentOfConnections;
          }

          this.companiesRepsonse = comps;
          this.companies = comps.companies.filter((c) => c.companyName !== 'undetermined').sort((a, b) => {
            if (b.percentOfConnections > a.percentOfConnections) return 1;
            if (b.percentOfConnections < a.percentOfConnections) return -1;
            // sort alphabetically if same percentage
            const bName = b.companyName.toUpperCase();
            const aName = a.companyName.toUpperCase();
            if (aName > bName) return 1;
            if (aName < bName) return -1;
          });
          this.resetCompanies();

          const firstTen = this.companies.slice(0, 10);
          this.setChart(firstTen);
        }
      },
      error: (error) => {
        this.handleError(error);
      }
    });
  }

  /*
  API
  */

  public getCompanies() {
    this.summaryService.getCompaniesLatest().pipe(takeUntil(this.destroyed$)).subscribe({
      next: (comps) => {
        const undetermined = comps.companies.find((c) => c.companyName === 'undetermined');
        if (undetermined) {
          this.noCompanyPercent = undetermined.percentOfConnections;
        }

        this.companiesRepsonse = comps;
        this.companies = comps.companies.filter((c) => c.companyName !== 'undetermined').sort((a, b) => b.numConnections - a.numConnections);
        this.resetCompanies();

        const firstTen = this.companies.slice(0, 10);
        this.setChart(firstTen);
      },
      error: (error) => {
        this.handleError(error);
      }
    });
  }

  /*
  Utils
  */

  private setChart(firstTen: Array<CompanySummary>) {
    const data = firstTen.map(t => {
      return {value: this.isLargeView ? t.companyName : this.truncate(t.companyName), textStyle: {color: '#1A1A1A'}}
    }).reverse();

    this.chartOption = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      legend: {},
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        top: '3%',
        containLabel: true
      },
      xAxis: {
        type: 'value',
        boundaryGap: [0, 0.01]
      },
      yAxis: {
        type: 'category',
        data,
      },
      series: [
        {
          type: 'bar',
          data: firstTen.map(t => t.numConnections).reverse(),
          colorBy: 'data',
          cursor: 'unset'
        },
      ],
      color: ['#bfcfcd', '#bfcfcd', '#99a8a5', '#99a8a5', '#879491', '#1a5c56', '#1a5c56', '#1a5c56', '#004a43', '#004a43', '#004a43']
    };
  }

  public getMoreCompanies() {
    if (!this.companies) {
      return;
    }

    this.lastIndex += 10;
    if (this.lastIndex > this.companies.length) {
      this.lastIndex = this.companies.length;
      this.showMore = false;
    }
    this.displayedCompanies = this.companies.slice(0, this.lastIndex);
  }

  public resetCompanies(scroll: boolean = false) {
    if (scroll) {
      document.getElementById("company-top").scrollIntoView();
    }
    this.lastIndex = 0;
    this.showMore = true;
    this.getMoreCompanies();
  }

  public formatPercent(percent: number): string {
    if (!percent) {
      return 'unknown number';
    }
    return (percent * 100).toFixed(2) + "%";
  }

  private truncate(str: string): string {
    if (str.length > 10) {
      return str.substring(0, 8) + '...';
    }
    return str;
  }

  public openConnModal(searchTerm?: string, searchType?: string) {
    this.openConnectionsModal(searchTerm, searchType);
  }
}

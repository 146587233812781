import { environment } from 'environments/environment';
import { ProfileStatuses } from '../enums/profile-statuses.enum';
import { RegExps } from '../enums/reg-exps.enum';
import { IBaseApiModel } from '../interfaces/i-base-api-model';
import { ConsumerLegalInformation } from './consumer-legal-information';
import { LinkedInProfile } from './linkedin-profile';
import { LinkedInUpload } from './linkedin-upload';
import { User } from './user';

const defaultAvatarUrl = '/assets/imgs/default-avatar.png';
const deletedProfileAvatarUrl = '/assets/imgs/default-avatar-transparent.png';

export class UserProfile implements IBaseApiModel {
  public id: string;
  public instanceVersion: number;
  public createdBy: string;
  public createdAt: Date;
  public updatedBy: string;
  public updatedAt: Date;
  public deletedBy: string;
  public deletedAt: Date;
  public status: ProfileStatuses;
  public displayName: string;
  public avatarUrl: string;
  public email: string;
  public phone: string;
  public persona: any;
  public userId: string;
  public metadata: any;
  public welcome: boolean;
  public invitedById: string;
  public invitationAck: boolean;
  public invitationAcceptedAt: Date;
  public invitationRejectedAt: Date;
  public linkedInProfileUrl: string;
  // expanded
  public user: User;
  public invitedBy: UserProfile;
  public linkedInProfiles: LinkedInProfile[];
  public linkedInUploads: LinkedInUpload[];
  // injected by api
  public firstName: string;
  public lastName: string;
  // additive
  public defaultAvatar: string;
  public isSelected = false;
  public isDisabled = false;
  public displayNameBreak: boolean;
  public default: boolean;
  public initials: string;

  constructor(obj?: any) {
    Object.assign(this, obj);
    this.instanceVersion = this.instanceVersion ? this.instanceVersion : -1;
    if (this.status === ProfileStatuses.deleted) {
      this.avatarUrl = deletedProfileAvatarUrl;
    }
    this.defaultAvatar = defaultAvatarUrl;
    this.metadata = this.metadata ? this.metadata : {};
    this.metadata.settings = this.metadata.settings ? this.metadata.settings : {};

    this.metadata.legalInformation = this.metadata.legalInformation ? this.metadata.legalInformation : new ConsumerLegalInformation();

    this.metadata.settings.doNotShow = this.metadata.settings.doNotShow ? this.metadata.settings.doNotShow : {};
    this.metadata.settings.messages = this.metadata.settings.messages ? this.metadata.settings.messages : {};

    this.linkedInProfileUrl = this.linkedInProfileUrl ? this.linkedInProfileUrl : null;

    this.avatarUrl = this.avatarUrl ? this.avatarUrl : defaultAvatarUrl;

    // TODO BUG api should not be returning empty user on user.profile when not expand=user
    if (this.user && !this.user.id.length) {
      delete this.user;
    }
    this.user = this.user ? new User(this.user) : null;

    if (obj?.linkedInUploads) {
      this.linkedInUploads = new Array<LinkedInUpload>();
      obj.linkedInUploads.forEach(lu => {
        this.linkedInUploads.push(new LinkedInUpload(lu));
      });
    }

    if (obj?.linkedInProfiles) {
      this.linkedInProfiles = new Array<LinkedInProfile>();
      obj.linkedInProfiles.forEach(lp => {
        this.linkedInProfiles.push(new LinkedInProfile(lp));
      });
    }
  }

  get isValid(): boolean {
    const pattern = new RegExp(RegExps.email);
    const isEmail = !!(this.email && this.email.length && pattern.test(this.email));
    return isEmail;
  }

  get isBigWig(): boolean {
    return this.email.includes('nicholas.beardsley') || this.email.includes('@updraftiq.com') || (environment.env !== 'prod' && environment.env !== 'production');
  }

  public cleanseForAPI(): void {
    const removeKeys = [
      'instanceVersion', 'business', 'user', 'sortCategory',
      'defaultAvatar', 'isSelected', 'isDisabled', 'selectedRoleOption', 'roleOptions'
    ];
    const currentKeys = Object.keys(this);
    currentKeys.forEach((key) => {
      if (removeKeys.indexOf(key) > -1) {
        delete this[key];
      }
    });
  }
}

import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { BaseComponent } from '../../components/base/base.component';
import { state, style, trigger } from '@angular/animations';
import { Accordion } from '../../../animations/accordion';
import { BehaviorSubject, combineLatest, takeUntil } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UserService } from 'app/services/user.service';

@Component({
  selector: 'app-upload-modal',
  templateUrl: './upload-modal.component.html',
  styleUrls: ['./upload-modal.component.scss'],
  animations: [
    trigger('accordion', Accordion.animation),
    trigger('showHide', [
      state('true', style({ display: 'block' })),
      state('false', style({ display: 'none' }))
    ])
  ]
})
export class UploadModalComponent extends BaseComponent implements OnInit {
  @Input() isAuthenticated$ = new BehaviorSubject<boolean>(false);
  @ViewChild('fileInput', {static: true}) fileInput: ElementRef;
  signupForm: FormGroup;
  public fileUploaded = false;
  public isInstructionsShowing = true;
  public uploadButtonText = 'Upload Archive';
  public getStarted = true;
  public getStartedHaveDeets = false;

  validation_messages = {
    'email': [
      { type: 'required', message: 'Email is required.' },
      { type: 'pattern', message: 'Enter a valid email.' }
    ],
    'firstName': [
      { type: 'required', message: 'First name is required.' },
    ],
    'lastName': [
      { type: 'required', message: 'Last name is required.' },
    ],
  };

  constructor(public modalController: ModalController, private userService: UserService) {
    super();

    this.signupForm = new FormGroup({
      'firstName': new FormControl('', Validators.compose([
        Validators.required
      ])),
      'lastName': new FormControl('', Validators.compose([
        Validators.required
      ])),
      'email': new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*$')
      ]))
    });
  }

  ngOnInit(): void {
      super.ngOnInit();
      this.subscribeToIsAuthenticated();
      this.subscribeToAccountData();
  }

  private subscribeToIsAuthenticated() {
    this.isAuthenticated$.pipe(takeUntil(this.destroyed$))
      .subscribe((isAuthenticated) => {
        this.getStarted = !isAuthenticated;
      });
  }

  private subscribeToAccountData() {
    combineLatest([this.utilsService.accountEmail$, this.utilsService.accountFirstName$, this.utilsService.accountLastName$]).pipe(takeUntil(this.destroyed$))
      .subscribe(([email, firstName, lastName]) => {
        if (email) {
          this.signupForm.controls.email.setValue(email);
        }
        if (firstName) {
        this.signupForm.controls.firstName.setValue(firstName);
        }
        if (lastName) {
        this.signupForm.controls.lastName.setValue(lastName);
        }
      });
  }

  public showUploadFile() {
    this.fileInput.nativeElement.click();
  }

  public uploadArchive(event) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = () => {
      const zipFile = new Blob([new Uint8Array((reader.result as ArrayBuffer))]);
      this.utilsService.zipFile$.next(zipFile);
      if (file.name && (file.name as string).toLowerCase().includes('basic')) {
        this.fileUploaded = true;
        this.isInstructionsShowing = false;
        this.uploadButtonText = 'Upload&nbsp<span class="color-warning">Complete</span>&nbspArchive'
      } else {
        this.modalController.dismiss('upload');
      }
    };

    reader.onerror = (error) => {
      this.handleError(error);
    };
  };

  public continue(event) {
    this.modalController.dismiss('upload');
  }

  public cancel(event) {
    this.modalController.dismiss();
  }

  public login() {
    this.modalController.dismiss('login');
  }

  // get started
  doGetStarted(): void {
    const firstName = this.signupForm.controls.firstName.value;
    const lastName = this.signupForm.controls.lastName.value;
    const email = this.signupForm.controls.email.value;
    this.showLoader();
    this.authService.prepForNewLogin().finally(() => {
      if (this.utilsService.noWelcome$.getValue()) {
        this.userService.exists(email)
        .subscribe({
          next: (res) => {
            this.dismissLoader();
            if (res.exists && res.profiles[0].id !== "") {
              // already exists
              this.setAccountData(email, firstName, lastName);
              this.login();
            } else {
              this.setAccountData(email, firstName, lastName);
              this.getStartedHaveDeets = true;
            }
          },
          error: (error) => {
            this.setAccountData(email, firstName, lastName);
            this.handleError(error);
            this.dismissLoader();
          }
        }
        );
      } else {
        this.userService.existsWelcome(email, firstName, lastName)
        .subscribe({
          next: (res) => {
            this.dismissLoader();
            if (res.exists && res.profiles[0].id !== "") {
              // already exists
              this.setAccountData(email, firstName, lastName);
              this.login();
            } else {
              this.setAccountData(email, firstName, lastName);
              this.getStartedHaveDeets = true;
            }
          },
          error: (error) => {
            this.setAccountData(email, firstName, lastName);
            this.handleError(error);
            this.dismissLoader();
          }
        }
        );
      }
    });
  }

  private setAccountData(email: string, firstName: string, lastName: string) {
    this.utilsService.accountFirstName$.next(firstName);
    this.utilsService.accountLastName$.next(lastName);
    this.utilsService.accountEmail$.next(email);
  }

  @HostListener('document:keydown.enter')
  public submitFormOnEnterPress() {
    if (this.signupForm.valid) {
      this.doGetStarted();
    }
  }
}

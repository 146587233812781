import { Component, Input, OnInit, ElementRef, EventEmitter, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UserProfile } from '../../models/user-profile';
import { BaseComponent } from '../base/base.component';
import { ConnectionsService } from '../../services/connections.service';
import { takeUntil } from 'rxjs/operators';
import { ViewChild } from '@angular/core';
import { LinkedInUpload } from '../../models/linkedin-upload';
import { UploadService } from '../../services/upload.service';
import { UploadStatus } from '../../enums/upload-status';
import { AppRoutes } from 'app/enums/app-routes.enum';
import { Util } from 'app/util';

@Component({
  selector: 'app-upload-summary',
  templateUrl: './upload-summary.component.html',
  styleUrls: ['./upload-summary.component.scss']
})
export class UploadSummaryComponent extends BaseComponent implements OnInit {
  @Input() profile: UserProfile;
  @Output() routeEvent = new EventEmitter<AppRoutes>();
  public appRoutes = AppRoutes;
  public uploads: Array<LinkedInUpload>;
  public showUploadError = false;
  public zeroUploads = true;
  public processingUpload = false;
  public latestUpload: LinkedInUpload;
  public first: boolean;
  public util = Util;

  constructor(private connectionsService: ConnectionsService,
    private uploadService: UploadService) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.setup();
  }

  private setup() {
    this.first = true;
    this.getUploads();
  }

  private getUploads() {
    this.connectionsService.getUploads([]).pipe(takeUntil(this.destroyed$)).subscribe({
      next: (uploads) => {
        if (uploads && uploads.length > 0) {
          this.uploads = uploads.sort((a, b) => { return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime() });
          this.zeroUploads = false;
        } else {
          this.zeroUploads = true;
        }
      },
      error: (error) => {
        this.handleError(error);
      }
    });
  }

  public handleRouteEvent(event) {
    this.routeEvent.emit(event);
  }
}

import { UserProfile } from './user-profile';

export class ExistsResponse {
  public profiles: Array<UserProfile>;
  public exists: boolean;

  constructor(obj?: any) {
    Object.assign(this, obj);
    this.profiles = this.profiles ? this.profiles.map((p) => new UserProfile(p)) : [];
    this.exists = this.exists ? true : false;
  }
}

export enum StorageKeys {
  deviceData = 'dev',
  jwt = 'jwt',
  token = 'tok',
  loggingToken = 'ltok',
  logLineCache = 'lcache',
  userId = 'muid',
  email = 'email',
  rememberMe = 'rem',
  currentProfileId = 'cpid',
  noNetworkUrl = 'nurl',
  reloadPwa = 'rpwa',
  musingHighlighted = 'ml',
  viewShareTimer = 'vst',
  viewShareTimerId = 'vsti',
  utm = 'utm',
  // TODO unused?
  secret = 'sct',
  secretRenewable = 'sctr',
}

<ion-header>
  <ion-toolbar mode="md" color="secondary">
    <ion-buttons slot="end">
      <ion-button color="primay" fill="solid" type="button" color="primary" fill="clear" (click)="dismiss()">
        Close
        <ion-icon slot="end" name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      Terms of use
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="ion-padding text-justify" [innerHTML]="text"></div>
</ion-content>
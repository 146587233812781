import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { BaseComponent } from '../../components/base/base.component';
import { AppRoutes } from '../../enums/app-routes.enum';
import { ErrorModalComponent } from '../error-modal/error-modal.component';
import { Util } from 'app/util';
import { UserProfile } from 'app/models/user-profile';
import { BehaviorSubject } from 'rxjs';
import { UploadModalComponent } from '../upload-modal/upload-modal.component';

@Component({
  selector: 'app-app-page-modal',
  templateUrl: './app-page-modal.component.html',
  styleUrls: ['./app-page-modal.component.scss']
})
export class AppPageModalComponent extends BaseComponent {
  @Input() currentProfile$ = new BehaviorSubject<UserProfile>(null);
  @Input() pageContext: AppRoutes;
  public routes = AppRoutes;
  public util = Util;

  private baseErrorModal: HTMLIonModalElement;
  private baseErrorModalController: ModalController;

  constructor(public modalController: ModalController) {
    super();
  }

  public handleRouteEvent(route: AppRoutes) {
    if (this.pageContext === AppRoutes.login && route === AppRoutes.forgotPassword) {
      this.pageContext = AppRoutes.forgotPassword;
    } else if (this.pageContext === AppRoutes.forgotPassword && route === AppRoutes.back) {
      this.pageContext = AppRoutes.login;
    } else if (route === AppRoutes.upload) {
      this.showUploadModal();
    } else {
      this.modalController.dismiss(route);
    }
  }

  // upload modal
  public async showUploadModal() {
    if (this.modal) {
      await this.modal.dismiss();
    }

    this.modal = await this.modalController.create({
      component: UploadModalComponent,
      componentProps: {
        isAuthenticated$: this.utilsService.isAuthenticated$
      },
      showBackdrop: true,
      mode: 'md',
      cssClass: this.isLargeView ? 'modal-normal' : ''
    });

    await this.modal.present();

    this.modal.onDidDismiss().then((data: any) => {
      if (data.data) {
        if (!this.utilsService.isAuthenticated$.getValue()) {
          this.handleRouteEvent(AppRoutes.signup);
        } else {
          this.utilsService.handleNewUpload$.next(true);
          this.handleRouteEvent(AppRoutes.record);
        }
      }
    });
  }

  // error
  public handleError(error: any, back?: boolean, route?: string, display?: boolean) {
    const appError = Util.processError(error, back, route, display);
    this.dismissLoader(true);
    if (appError.back || appError.route || appError.status === 500) {
      this.showBaseErrorModal(appError.correlationId, appError.messages, appError.back, appError.route);
    } else if (appError.display) {
      this.toastService.showError(appError.messages);
    }
  }

  private async showBaseErrorModal(correlationId: string, messages: Array<string>, back: boolean, route: string) {
    this.baseErrorModal = await this.baseErrorModalController.create({
      component: ErrorModalComponent,
      componentProps: {
        correlationId,
        messages
      },
      showBackdrop: true,
      cssClass: this.isLargeView ? 'modal-normal' : ''
    });

    await this.baseErrorModal.present();

    this.baseErrorModal.onDidDismiss().then((data: any) => {
      if (route) {
        this.utilsService.navigateForward(route);
      } else if (back) {
        this.utilsService.back();
      }
    });
  }
}


export class LinkedInLocationResponse {
  public city: string;
  public state: string;
  public country: string;
  public lat: number;
  public lon: number;
  public numConnections: number;
  public percentOfConnections: number;

  constructor(obj?: any) {
    Object.assign(this, obj);
  }
}
export class AppError {
  messages: Array<string>;
  back: boolean;
  route: string;
  display: boolean;
  correlationId: string;
  status: number;

  constructor(obj?: any) {
    Object.assign(this, obj);
  }
}


export class MessageSummaryResponse {
  public messages: Array<MessageSummary>;
  public stats: MessageStats;

  constructor(obj: any) {
    Object.assign(this, obj);

    if (obj.messages) {
      this.messages = obj.messages.map((message) => new MessageSummary(message));
    }

    if (obj.stats) {
      this.stats = new MessageStats(obj.stats);
    }
  }
}

export class MessageSummary {
  public connectionId: string;
  public firstName: string;
  public lastName: string;
  public companyName: string;
  public position: string;
  public numMessages: number;
  public percentOfMessages: number;

  constructor(obj: any) {
    Object.assign(this, obj);
  }
}

export class MessageStats {
  public send: MessageTimeStats;
  public receive: MessageTimeStats;
  public responseRate: number;
  public numSentMessages: number;
  public numRecievedMessages: number;

  constructor(obj: any) {
    Object.assign(this, obj);

    if (obj.send) {
      this.send = new MessageTimeStats(obj.send);
    }

    if (obj.receive) {
      this.receive = new MessageTimeStats(obj.receive);
    }
  }
}

export class MessageTimeStats {
  public hourOfDay: number;
  public month: string;
  public year: number;

  constructor(obj: any) {
    Object.assign(this, obj);
  }
}
<ion-row id="musing-row" class="ion-align-items-center" [ngClass]="{'musing-container-highlight': highlight, 'musing-container': !highlight}" (click)="showModal()">
  <ion-col>
    <div class="flex-column align-center">
      <ion-icon class="cursor-pointer" name="bar-chart" [ngClass]="{'fs-24': isLargeView, 'fs-16': !isLargeView}"></ion-icon>
    </div>
    <div [id]="'musing-' + musingContext + '-stat'" class="flex-column align-center cursor-pointer" [ngClass]="{'musing-isLargeView': isLargeView, 'musing': !isLargeView}">
      Statistic
    </div>
  </ion-col>
  <ion-col>
    <div class="flex-column align-center">
      <ion-icon class="cursor-pointer" name="trophy" [ngClass]="{'fs-24': isLargeView, 'fs-16': !isLargeView}"></ion-icon>
    </div>
    <div [id]="'musing-' + musingContext + '-action'" class="flex-column align-center cursor-pointer" [ngClass]="{'musing-isLargeView': isLargeView, 'musing': !isLargeView}">
      Take Action
    </div>
  </ion-col>
  <ion-col>
    <div class="flex-column align-center">
      <ion-icon class="cursor-pointer" name="thumbs-up" [ngClass]="{'fs-24': isLargeView, 'fs-16': !isLargeView}"></ion-icon>
    </div>
    <div class="flex-column align-center cursor-pointer" [ngClass]="{'musing-isLargeView': isLargeView, 'musing': !isLargeView}">
      Best Practice
    </div>
  </ion-col>
</ion-row>

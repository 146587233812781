import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiUrls } from '../enums/api-urls.enum';
import { UserProfile } from '../models/user-profile';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class UploadService {
  private source = 'UploadService';

  constructor(private apiService: ApiService) {
  }

  public post(zipFile: Blob): Observable<any> {
    const func = 'post';
    return this.apiService.post(this.source, func, `${ApiUrls.upload}/linkedin-data`, zipFile)
      .pipe(map((res) => {
        return res;
      }, (error) => {
        return error;
      }));
  }
}

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ApiUrls } from '../enums/api-urls.enum';
import { AppRoutes } from '../enums/app-routes.enum';
import { AuthService } from './auth.service';
import { Logger } from './logger.service';
import { UtilsService } from './utils.service';

@Injectable()
export class Interceptor implements HttpInterceptor {
  private source = 'Interceptor';
  private currentUrl: any;
  private correlationIdHeader = 'CorrelationId';

  constructor(private logger: Logger, private route: Router, private authService: AuthService, private utilsService: UtilsService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const func = 'intercept';
    this.currentUrl = this.route.url;
    return next.handle(req).pipe(tap(event => {
    }), catchError((res) => {
      this.logger.logTrace(this.source, func, res, 'catchError');
      switch (res.status) {
        case 0:
          if (!res.url.includes(ApiUrls.splunk) && !res.url.includes(ApiUrls.device) && !res.url.includes(ApiUrls.logout) && !environment.settings.disableNoNetwork) {
            this.logError(res, func);
            this.noNetwork(this.currentUrl);
          } else {
            return throwError(res);
          }
          break;
        case 401:
          if (res.url.includes(ApiUrls.login) || res.url.includes(ApiUrls.tokenLogin) || res.url.includes(ApiUrls.secretLogin)) {
            if (this.utilsService.isAuthenticated$.getValue()) {
              this.logger.logTrace(this.source, func, null, '401 tokenLogin: logout');
              this.logout().then(() => {
                return throwError(res);
              });
            }
          } else if (this.currentUrl === AppRoutes.home || this.currentUrl === AppRoutes.forgotPassword) {
            if (this.utilsService.isAuthenticated$.getValue()) {
              this.prepForNewLogin(res).then(() => {
                return throwError(res);
              });
            } else {
              return throwError(res);
            }
          } else if (!res.url.includes(ApiUrls.device)) {
            this.logger.logDebug(this.source, func, {currentUrl: this.currentUrl}, '401: try loginWithToken: reload: false');
            this.loginWithToken(this.currentUrl).then(() => {
              return throwError(res);
            });
          } else {
            this.logger.logDebug(this.source, func, {currentUrl: this.currentUrl}, '401: put device data: just throw error');
            return throwError(res);
          }
          break;
      }
      return throwError(res);
    }));
  }

  private logError(res, func) {
    if (!res.url.includes(ApiUrls.splunk)) {
      this.logger.logError(this.source, func,
        { verb: null, request: null, status: res.status, response: res.body },
        res.url, res.headers.get(this.correlationIdHeader));
    } else {
      this.logger.addLineToCache(this.logger.ERROR, this.source, func,
        { verb: null, request: null, status: res.status, response: res.body },
        res.url, res.headers.get(this.correlationIdHeader));
    }
  }

  private async loginWithToken(url: string) {
    return this.authService.loginWithToken(url);
  }

  private async prepForNewLogin(res) {
    return await this.authService.prepForNewLogin();
  }

  private async logout() {
    return await this.authService.logout();
  }

  private async noNetwork(url: string) {
    return this.authService.noNetwork(url);
  }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiUrls } from '../enums/api-urls.enum';
import { ApiService } from './api.service';
import { LinkedInConnection } from '../models/linkedin-connection';
import { LinkedInConnectionsResponse, LinkedInSharedConnectionsResponse } from '../models/linkedin-connections-response';
import { LinkedInUpload } from '../models/linkedin-upload';
import { LinkedInProfile } from '../models/linkedin-profile';

@Injectable({
  providedIn: 'root'
})
export class ConnectionsService {
  private source = 'ConnectionService';

  constructor(private apiService: ApiService) {
  }

  public getlatest(page?: number, pageSize?: number, city?: string, state?: string, country?: string): Observable<LinkedInConnectionsResponse> {
    const func = 'getlatest';

    let params = this.apiService.pagingParams(page, pageSize);
    params = this.apiService.locationParams(city, state, country, params);
    
    return this.apiService.get(this.source, func, `${ApiUrls.connection}s/latest${params}`)
      .pipe(map((res) => {
        return new LinkedInConnectionsResponse(res);
      }, (error) => {
        return error;
      }));
  }

  public getShared(sharedId: string, page?: number, pageSize?: number, city?: string, state?: string, country?: string, company?: string, position?: string): Observable<LinkedInSharedConnectionsResponse> {
    const func = 'getShared';

    let params = this.apiService.pagingParams(page, pageSize);
    params = this.apiService.locationParams(city, state, country, params);
    params = this.apiService.companyParams(company, params);
    params = this.apiService.positionParams(position, params);
    
    return this.apiService.get(this.source, func, `${ApiUrls.connection}s/shared/${sharedId}${params}`)
      .pipe(map((res) => {
        return new LinkedInConnectionsResponse(res);
      }, (error) => {
        return error;
      }));
  }

  public get(connectionId: string, expand: Array<string>): Observable<LinkedInConnection> {
    const func = 'get';

    const params = this.apiService.expandParams(expand);
    
    return this.apiService.get(this.source, func, `${ApiUrls.connection}/${connectionId}${params}`)
      .pipe(map((res) => {
        return new LinkedInConnection(res);
      }, (error) => {
        return error;
      }));
  }

  public getUploads(expand: Array<string>): Observable<Array<LinkedInUpload>> {
    const func = 'getUploads';

    const params = this.apiService.expandParams(expand);
    
    return this.apiService.get(this.source, func, `${ApiUrls.linkedInUpload}${params}`)
      .pipe(map((res) => {
        return res.map((r) => new LinkedInUpload(r));
      }, (error) => {
        return error;
      }));
  }

  public getLatestUpload(expand: Array<string>): Observable<LinkedInUpload> {
    const func = 'getLatestUpload';

    const params = this.apiService.expandParams(expand);
    
    return this.apiService.get(this.source, func, `${ApiUrls.linkedInUpload}s/latest${params}`)
      .pipe(map((res) => {
        return  new LinkedInUpload(res);
      }, (error) => {
        return error;
      }));
  }

  public getLatestProfile(expand: Array<string>): Observable<LinkedInProfile> {
    const func = 'get';

    const params = this.apiService.expandParams(expand);
    
    return this.apiService.get(this.source, func, `${ApiUrls.linkedInProfile}/latest${params}`)
      .pipe(map((res) => {
        return new LinkedInProfile(res);
      }, (error) => {
        return error;
      }));
  }
}

import { CompanySummary } from "./company-summary";
import { LinkedInLocationResponse } from "./linkedin-location-response";
import { MessageSummary, MessageStats } from "./message-summary";
import { TitleResponse } from "./title-response";

export class ShareSummary {
  public shareId: string;
  public location: Array<LinkedInLocationResponse>;
  public company: Array<CompanySummary>;
  public network: Array<TitleResponse>;
  public messages: Array<MessageSummary>;
  public messageStats: MessageStats;

  constructor(obj: any) {
    Object.assign(this, obj);

    if (obj.location) {
      this.location = obj.location.map((item: any) => new LinkedInLocationResponse(item));
    }

    if (obj.company) {
      this.company = obj.company.map((item: any) => new CompanySummary(item));
    }

    if (obj.network) {
      this.network = obj.network.map((item: any) => new TitleResponse(item));
    }

    if (obj.messages) {
      this.messages = obj.messages.map((item: any) => new MessageSummary(item));
    }

    if (obj.messageStats) {
      this.messageStats = new MessageStats(obj.messageStats);
    }
  }
}
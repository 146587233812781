import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { BaseComponent } from '../base/base.component';
import { AppRoutes } from 'app/enums/app-routes.enum';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: [
    './forgot-password.component.scss'
  ]
})
export class ForgotPasswordComponent extends BaseComponent implements OnInit {
  @Output() routeEvent = new EventEmitter<AppRoutes>();
  forgotPasswordForm: FormGroup;
  public appRoutes = AppRoutes;

  validation_messages = {
    'email': [
      { type: 'required', message: 'Email is required.' },
      { type: 'pattern', message: 'Enter a valid email.' }
    ]
  };

  constructor(
    public router: Router
  ) {
    super();
    this.forgotPasswordForm = new FormGroup({
      'email': new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*$')
      ]))
    });
  }

  ngOnInit() {
    super.ngOnInit();
  }

  recoverPassword(): void {
    this.showLoader();
    this.authService.forgotPassword(this.forgotPasswordForm.value.email).pipe(takeUntil(this.destroyed$))
    .subscribe({
      next: (res) => {
        this.dismissLoader();
        this.toastService.showSuccess('Check your email for a password reset link.', null, {duration: 3000, position: 'top'})
        this.routeEvent.emit(AppRoutes.back);
      },
      error: (error) => {
        this.handleError(error);
      }
    });
  }

  handleRouteEvent(route: AppRoutes) {
    this.routeEvent.emit(route);
  }

  @HostListener('document:keydown.enter')
  public submitFormOnEnterPress() {
    if (this.forgotPasswordForm.valid) {
      this.recoverPassword();
    }
  }
}

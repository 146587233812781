import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { UserProfile } from '../../models/user-profile';
import { BaseComponent } from '../base/base.component';
import { AppRoutes } from 'app/enums/app-routes.enum';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs';
import { ProfileService } from 'app/services/profile.service';
import { PasswordValidator } from 'app/validators/password.validator';
import { state, style, trigger } from '@angular/animations';
import { UserService } from 'app/services/user.service';
import { NewPassRequest } from 'app/models/new-pass-request';
import { StorageKeys } from 'app/enums/storage-keys.enum';
import { ApiService } from 'app/services/api.service';

@Component({
  selector: 'app-profile-display',
  templateUrl: './profile-display.component.html',
  styleUrls: ['./profile-display.component.scss'],
  animations: [
    trigger('showHide', [
      state('true', style({ display: 'block' })),
      state('false', style({ display: 'none' }))
    ])
  ]
})
export class ProfileDisplayComponent extends BaseComponent implements OnInit {
  @Input() profile: UserProfile;
  @Output() actionEvent = new EventEmitter<boolean>();
  public appRoutes = AppRoutes;
  public editProfile = false;
  public resetPassword = false;


  profileForm: FormGroup;
  passwordForm: FormGroup;
  matching_passwords_group: FormGroup;

  validation_messages = {
    'email': [
      { type: 'required', message: 'Email is required.' },
      { type: 'pattern', message: 'Enter a valid email.' }
    ],
    'url': [
      { type: 'required', message: 'LinkedIn url is required.' },
      { type: 'pattern', message: 'Enter a valid LinkedIn url.' }
    ],
    'firstName': [
      { type: 'required', message: 'First name is required.' },
    ],
    'lastName': [
      { type: 'required', message: 'Last name is required.' },
    ],
    'password': [
      { type: 'required', message: 'Password is required.' },
      { type: 'minlength', message: 'Password must be at least 5 characters long.' }
    ],
    'confirm_password': [
      { type: 'required', message: 'Confirm password is required' }
    ],
    'matching_passwords': [
      { type: 'areNotEqual', message: 'Password mismatch' }
    ]
  };

  constructor(private userService: UserService, private profileService: ProfileService, private apiService: ApiService) {
    super();

    this.profileForm = new FormGroup({
      'firstName': new FormControl('', Validators.compose([
        Validators.required
      ])),
      'lastName': new FormControl('', Validators.compose([
        Validators.required
      ])),
      'url': new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('^(https?:\/\/)?(www\.)?linkedin\.com\/in\/.+\/?$')
      ])),
      'email': new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*$')
      ]))
    });

    this.matching_passwords_group = new FormGroup({
      'password': new FormControl('', Validators.compose([
        Validators.minLength(5),
        Validators.required
      ])),
      'confirm_password': new FormControl('', Validators.required)
    }, (formGroup: FormGroup) => {
      return PasswordValidator.areNotEqual(formGroup);
    }); 
    
    this.passwordForm = new FormGroup({
      'matching_passwords': this.matching_passwords_group
    });
  }

  ngOnInit() {
    super.ngOnInit();
    this.setup();
  }

  private setup() {
    this.profile.user.profiles = null;
    this.profileForm.reset({
      email: this.profile.email,
      firstName: this.profile.user.firstName,
      lastName: this.profile.user.lastName,
      url: this.profile.linkedInProfileUrl
    });
  }

  public submitProfileForm() {
    this.updateProfile();
  }

  public updateProfile() {
    this.profile.email = this.profileForm.controls.email.value;
    this.profile.displayName = this.profileForm.controls.firstName.value;
    this.profile.linkedInProfileUrl = this.profileForm.controls.url.value;
    this.profile.user.firstName = this.profileForm.controls.firstName.value;
    this.profile.user.lastName = this.profileForm.controls.lastName.value;
    this.showLoader();
    this.userService.put(this.profile.user).pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: () => {
          this.profile.user = null;
          this.profileService.put(this.profile).pipe(takeUntil(this.destroyed$))
          .subscribe({
            next: () => {
              this.setUserAndProfile(this.profile.id);
              this.toastService.showSuccess('Profile settings updated!', null, {duration: 3000, position: 'top'});
              this.dismissLoader();
              this.editProfile = false;
            },
            error: (error) => this.handleError(error)
          });
        },
        error: (error) => this.handleError(error)
      });
  }

  public cancelSubmitProfile() {
    this.setup();
    this.editProfile = false;
  }

  private setUserAndProfile(profileId: string) {
    this.showLoader();
    this.authService.setUserAndProfile(profileId).pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: () => this.dismissLoader(),
        error: (error) => this.handleError(error)
      });
  }

  public submitPasswordForm() {
    this.changePassword();
  }

  public changePassword() {
    this.showLoader();
    this.profile.user.password = this.passwordForm.controls.matching_passwords.value.password;
    this.userService.put(this.profile.user).pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (res) => {
          this.toastService.showSuccess('Password updated!', null, {duration: 3000, position: 'top'});
          this.dismissLoader();
          this.cancelResetPasword();
        },
        error: (error) => this.handleError(error)
    });
  }

  public cancelResetPasword() {
    this.passwordForm.reset();
    this.resetPassword = false;
  }

  public handleActionEvent(event) {
    this.routeEvent.emit(event);
  }
}

<div class="dash-section-container">
  <h1>My Engagement</h1>

  <div class="visual-border-bottom" id="chartBlock" style="width: 100%; height: 40vh;" echarts [options]="chartOption"></div>

  <app-musings [musingContext]="'message'" [shareDisplay$]="shareDisplay$"></app-musings>


  <div class="width-full border-bottom-medium">
    <h2 class="italic" [ngClass]="{'ion-text-center': isLargeView}">Insights</h2>
    <div *ngIf="messageSummary?.stats && !shareDisplay"
      class="flex-row align-center justify-space-between fs-14 mx-auto"
      [ngClass]="{'width-60': isLargeView}">
      <div>
        <div class="color-tertiary pb-6">Most often sent:</div>
        <div>
          Time of day: <span *ngIf="messageSummary.stats.send?.hourOfDay" [innerHTML]="getTimeOfDay(messageSummary.stats.send.hourOfDay)"></span>
          <br>Month: {{ messageSummary.stats.send?.month }}
          <br>Year: {{ messageSummary.stats.send?.year }}
        </div>
      </div>
      <div>
        <div class="color-tertiary pb-6">Most often received:</div>
        <div>
          Time of day: <span *ngIf="messageSummary.stats.send?.hourOfDay" [innerHTML]="getTimeOfDay(messageSummary.stats?.receive?.hourOfDay)"></span>
          <br>Month: {{ messageSummary.stats.receive?.month }}
          <br>Year: {{ messageSummary.stats.receive?.year }}
        </div>
      </div>
    </div>
    <div class="width-full ion-text-center">
      <p class="color-tertiary fs-14 italic" [innerHTML]="util.suffix(util.formatPercent(notConnected), ' of all message are outside of my network')"></p>
    </div>
  </div>

  <ion-row class="ion-no-padding">
    <ion-col> 
      <h2 id="messages-top">Who I Message Most</h2>
    </ion-col>
    <ion-col *ngIf="!shareDisplay" size="auto" class="col-header-small">
      Total Sent &<br>Received
    </ion-col>
  </ion-row>

  <ion-list>
    <ion-item *ngFor="let net of displayedMessages; let i = index" (click)="showModal(net.connectionId)" class="pt-6 pb-2" [ngClass]="{'cursor-pointer': !shareDisplay}">
      <ion-row class="ion-align-items-center width-full">
        <ion-col>
          <div class="flex-row align-start">
            <div class="item-text-number">{{ i + 1 }}.</div>
            <div>
              <div class="item-text clamp-1">{{ net.firstName }} {{ net.lastName }}</div>
              <div class="item-text-sub">
                {{ net.companyName }}
              </div>
            </div>
          </div>
        </ion-col>
        <ion-col *ngIf="!shareDisplay" size="auto" class="rank-text" >{{ net.numMessages }}</ion-col>
      </ion-row>
    </ion-item>
    <ion-item lines="none" *ngIf="showMore || displayedMessages?.length > 10">
      <div *ngIf="showMore" class="more-button" (click)="getMoreMessages()">More</div>
      <ion-button *ngIf="displayedMessages?.length > 10" (click)="resetMessages(true)" color="medium" fill="outline">
        <ion-icon name="chevron-up-outline"></ion-icon>
      </ion-button>
    </ion-item>
  </ion-list>
</div>

<ion-modal [isOpen]="modalShowing"  (didDismiss)="modalShowing=false" [ngClass]="{'modal-tall': isLargeView && detailedConnection?.linkedInMessages?.length > 5}">
  <ng-template>
    <ion-header>
      <ion-toolbar color="primary" ion-toolbar mode="md">
        <ion-title>Connection Details</ion-title>
        <ion-buttons slot="end">
          <ion-button color="primay" fill="solid" type="button" color="primary" fill="solid" (click)="closeModal()">
            Close
            <ion-icon slot="end" name="close-outline"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
      <div class="basic-connection-info">
        <ion-item class="connection-detail-info-line">
          <ion-label>Name</ion-label>
          <span>{{ detailedConnection?.firstName }} {{ detailedConnection?.lastName }}</span>
        </ion-item>
        <ion-item class="connection-detail-info-line">
          <ion-label>Position</ion-label>
          <span>{{ detailedConnection?.position }}</span>
        </ion-item>
        <ion-item class="connection-detail-info-line">
          <ion-label>Company</ion-label>
          <span>{{ detailedConnection?.companyName }}</span>
        </ion-item>
        <ion-item class="connection-detail-info-line">
          <ion-label>Location</ion-label>
          <span [innerHTML]="util.getLocation(detailedConnection) | safeHtml"></span>
        </ion-item>
        <ion-item class="connection-detail-info-line">
          <ion-label>Connected on</ion-label>
          <span [innerHTML]="util.getDate(detailedConnection?.connectedOn)"></span>
        </ion-item>
        <ion-item *ngIf="detailedConnection?.email" class="connection-detail-info-line">
          <ion-label>Email</ion-label>
          <span>{{ detailedConnection?.email }}</span>
        </ion-item>
      </div>
      <h2 class="detail-section-header">Messages</h2>
      <p class="detail-section-header" *ngIf="!detailedConnection?.linkedInMessages || detailedConnection?.linkedInMessages?.length === 0">
        There are no messages to/from {{ detailedConnection?.firstName }} {{ detailedConnection?.lastName }} in this record.
      </p>
      <ion-list>
        <div *ngFor="let message of detailedConnection?.linkedInMessages" lines="none"  class="ion-no-padding flex-column my-8" [ngClass]="{'align-end': message.sentByMe, 'align-start': !message.sentByMe}">
          <div class="ion-padding flex-column width-80" [ngClass]="{'message-me': message.sentByMe, 'message-other': !message.sentByMe}">
            <div class="flex-row justify-space-between py-8 message-heading">
              <div>{{ message.from }}</div>
              <div [innerHTML]="util.getDateAndTime(message.date, true)"></div> 
            </div>
            <div>
              {{ message.content }}
            </div>
          </div>
        </div>
      </ion-list>
    </ion-content>
  </ng-template>
</ion-modal>
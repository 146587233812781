import { Injectable, resolveForwardRef } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HistoryService {
  public historyChanged$ = new BehaviorSubject(false );
  public appHistory = Array<string>();

  constructor(private router: Router) {
  }

  public trackHistory() {
    this.appHistory = [];
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(({ urlAfterRedirects }: NavigationEnd) => {
        this.appHistory.push(urlAfterRedirects);
      });
  }

  public clearHistory() {
    this.appHistory = new Array<string>();
    this.historyChanged$.next(true);
  }

  public seedHistory(url): Promise<boolean> {
    this.appHistory.push(url);
    return Promise.resolve(true);
  }

  public navigateForward(url): Promise<boolean> {
    this.purgeDups();
    return Promise.resolve(true);
  }

  public routerLinkForward(url): Promise<boolean> {
    // note that when routerLink is used, url is added to appHistory AFTER this fires
    return Promise.resolve(true);
  }

  public navigateBack(url: string): Promise<boolean> {
    if (url.includes('?mpid')) {
      url = url.substring(0, url.indexOf('?mpid'));
    }
    this.purgeDups();
    let idx = this.appHistory.length - 1;
    if (this.appHistory[idx] === url) {
      let match = false;
      while (!match && idx > 0) {
        idx--;
        match = this.appHistory[idx] === url;
      }
      this.appHistory = this.appHistory.slice(0, idx + 1);
    }
    this.historyChanged$.next(true);
    return Promise.resolve(true);
  }

  public navigateRoot(url: string): Promise<boolean> {
    this.historyChanged$.next(true);
    return Promise.resolve(true);
  }

  public pop(): Promise<boolean> {
    this.purgeDups();
    this.appHistory = this.appHistory.slice(0, -2);
    this.historyChanged$.next(true);
    return Promise.resolve(true);
  }

  private purgeDups() {
    this.appHistory = this.appHistory[this.appHistory.length - 1] === this.appHistory[this.appHistory.length - 2] ? this.appHistory.slice(0, -2) : this.appHistory;
  }
}

export class RouteBasedStructure {
  public mainRouteVisible = true;
  public mainRouteColSize = '12';
  public mainRouteComponent: string;
  public mainOriginalRoute: string;
  public spcRouteVisible = false;
  public spcRouteColSize = '0';
  public spcRouteComponent: string;
  public spcOriginalRoute: string;
  public extrasRouteComponent: string;
  public extrasRouteVisible = false;
  public extrasRouteColSize = '0';
  public isExtrasColSize = false;
  public isExtraLargeView = false;

  constructor(obj?: any) {
    if (obj) {
      Object.assign(this, obj);
    }
  }
}

import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { BaseComponent } from '../../components/base/base.component';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss']
})
export class ErrorModalComponent extends BaseComponent {
  @Input() correlationId: string;
  @Input() messages: string;
  public showErrorMessages = false;
  public showErrorSupportMessage = false;

  constructor(public modalController: ModalController) {
    super();
    if (environment.settings.showErrorMessages) {
      this.showErrorMessages = true;
    }
    if (environment.settings.showErrorSupportMessage) {
      this.showErrorSupportMessage = true;
    }
  }

  public continue() {
    this.modalController.dismiss(false);
  }
}

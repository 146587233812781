import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(value: any, propToFilter: string, filterVals: [string | number]): any {
    return (filterVals.indexOf(value[propToFilter]) > -1);
  }

}

export enum ApiUrls {
  exists = '/exists',
  register = '/register',
  login = '/login',
  forgotPassword = '/forgot-password',
  setPassword = '/set-password',
  whoAmI = '/who-am-i',
  profile = '/profile',
  tokenLogin = '/login-token',
  user = '/user',
  secretLogin = '/login-secret',
  device = '/device',
  logout = '/logout',
  appVersion = '/app/version',
  storeVersion = '/app/storeVersion',
  playVersion = '/app/playVersion',
  splunk = 'splunk.neb.io',
  upload = '/upload',
  connection = '/linkedin/connection',
  summary = '/summary',
  share = '/share',
  linkedInUpload = '/linkedin/upload',
  linkedInProfile = '/linkedin/profile',
}

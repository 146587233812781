export enum ProfileStatuses {
  invited = 'invited',
  pending = 'pending',
  connected = 'connected',
  active = 'active',
  recent = 'recent',
  deleted = 'deleted',
  notConnected = 'other',
  me = 'me',
  hide = 'hide'
}

import { LinkedInConnection } from "./linkedin-connection";
import { LinkedInMessage } from "./linkedin-message";
import { LinkedInUpload } from "./linkedin-upload";
import { UserProfile } from "./user-profile";

export class LinkedInProfile {
  public id: string
  public createdAt: string
  public updatedAt: string
  public deletedAt: any
  public instanceVersion: number
  public createdBy: string
  public updatedBy: string
  public metadata: LinkedInProfileMetadata
  public firstName: string
  public lastName: string
  public headline: string
  public industry: string
  public zipCode: string
  public geoLocation: string
  public linkedInUploadId: string
  public profileId: string
  public linkedInConnections: LinkedInConnection[]
  public linkedInMessages: LinkedInMessage[]
  public linkedInUpload: LinkedInUpload
  public profile: UserProfile

  constructor(obj?: any) {
    Object.assign(this, obj);

    if (obj.profile) {
      this.profile = new UserProfile(obj.profile)
    }

    if (obj.linkedInUpload) {
      this.linkedInUpload = new LinkedInUpload(obj.linkedInUpload)
    }

    if (obj.metadata) {
      this.metadata = new LinkedInProfileMetadata(obj.metadata)
    }

    if (obj.linkedInMessages) {
      this.linkedInMessages = new Array<LinkedInMessage>();
      obj.linkedInMessages.forEach(lm => {
        this.linkedInMessages.push(new LinkedInMessage(lm));
      });
    }

    if (obj.linkedInConnections) {
      this.linkedInConnections = new Array<LinkedInConnection>();
      obj.linkedInConnections.forEach(lc => {
        this.linkedInConnections.push(new LinkedInConnection(lc));
      });
    }
  }
}

export class LinkedInProfileMetadata {
  public address: string
  public birthDate: any
  public instantMessangers: any[]
  public originalFirstName: string
  public originalLastName: string
  public originalMaidenName: string
  public summary: string
  public twitterHandles: any[]
  public websites: any[]

  constructor(obj?: any) {
    Object.assign(this, obj);
  }
}
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { takeUntil } from 'rxjs';
import { LinkedInUpload } from '../../../app/models/linkedin-upload';
import { BaseComponent } from '../../components/base/base.component';
import { state, style, trigger } from '@angular/animations';
import { AppRoutes } from 'app/enums/app-routes.enum';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  animations: [
    trigger('showHide', [
      state('true', style({ display: 'block' })),
      state('false', style({ display: 'none' }))
    ])
  ]
})
export class MenuComponent extends BaseComponent implements OnInit {
  @Output() actionEvent = new EventEmitter<string>();
  public appRoutes = AppRoutes;
  public latestUpload: LinkedInUpload;
  public bigWigMenu = false;

  constructor() {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.subscribeToIsAuthenticated();
    this.subscribeToLatestUpload();
  }

  private subscribeToIsAuthenticated() {
    this.utilsService.isAuthenticated$.pipe(takeUntil(this.destroyed$))
      .subscribe((isAuthenticated) => {
        if (isAuthenticated) {
          if (this.isBigWig) {
            if (!this.isLargeView) {
              this.bigWigMenu = true;
            }
          }
        }
      });
  }

  private subscribeToLatestUpload() {
    this.utilsService.latestUpload$.pipe(takeUntil(this.destroyed$))
    .subscribe((latestUpload) => {
      if (latestUpload) {
        this.latestUpload = latestUpload;
      }
    });
  }

  public emitAction(action: AppRoutes) {
    this.actionEvent.emit(action);
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { ShellModule } from '../shell/shell.module';

import {PipesModule} from '../pipes/pipes.module';
import { CheckboxWrapperComponent } from './checkbox-wrapper/checkbox-wrapper.component';
import { ShowHidePasswordComponent } from './show-hide-password/show-hide-password.component';
import { CountdownTimerComponent } from './countdown-timer/countdown-timer.component';
import { CounterInputComponent } from './counter-input/counter-input.component';
import { RatingInputComponent } from './rating-input/rating-input.component';
import { GoogleMapComponent } from './google-map/google-map.component';
import { TextPopoverComponent } from './text-popover/text-popover.component';
import { ErrorModalComponent } from './error-modal/error-modal.component';
import { UploadModalComponent } from './upload-modal/upload-modal.component'
import { LoadingComponent } from './loading/loading.component';
import { ConnectionsComponent } from './connections/connections.component';
import { LocationComponent } from './location-summary/location.component';
import { NetworkComponent } from './network-summary/network.component';
import { NgxEchartsModule } from 'ngx-echarts';
import { MessageSummaryComponent } from './messages-summary/messages.component';
import { CompanySummaryComponent } from './company-summary/company-summary.component';
import { FooterComponent } from './footer/footer.component';
import { MusingsComponent } from './musings/musings.component';
import { ProfileDisplayComponent } from './profile-display/profile-display.component';
import { UploadSummaryComponent } from './upload-summary/upload-summary.component';
import { AppMessageComponent } from './app-message/app-message.component';
import { AppMessageModalComponent } from './app-message-modal/app-message-modal.component';
import { CardActionPopoverComponent } from './card-action-popover/card-action-popover.component';
import { MenuPopoverComponent } from './menu-popover/menu-popover.component';
import { MenuComponent } from './menu/menu.component';
import { DefaultAvatarComponent } from './default-avatar/default-avatar.component';
import { SharesDisplayComponent } from './shares-display/shares-display.component';
import { SharesCreateComponent } from './shares-create/shares-create.component';
import { ShareDetailComponent } from './share-detail/share-detail.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { SignupComponent } from './signup/signup.component';
import { LoginComponent } from './login/login.component';
import { TermsOfServiceComponent } from './terms-of-service/terms-of-service.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { FaqComponent } from './faq/faq.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { MusingModalComponent } from './musing-modal/musing-modal.component';
import { AppPageModalComponent } from './app-page-modal/app-page-modal.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    PipesModule,
    ShellModule,
    IonicModule,
    NgxEchartsModule.forChild(),
    ReactiveFormsModule
  ],
  declarations: [
    CheckboxWrapperComponent,
    ShowHidePasswordComponent,
    CountdownTimerComponent,
    CounterInputComponent,
    RatingInputComponent,
    GoogleMapComponent,
    TextPopoverComponent,
    ErrorModalComponent,
    UploadModalComponent,
    LoadingComponent,
    ConnectionsComponent,
    LocationComponent,
    NetworkComponent,
    MessageSummaryComponent,
    CompanySummaryComponent,
    FooterComponent,
    MusingsComponent,
    ProfileDisplayComponent,
    UploadSummaryComponent,
    AppMessageComponent,
    AppMessageModalComponent,
    CardActionPopoverComponent,
    MenuPopoverComponent,
    MenuComponent,
    DefaultAvatarComponent,
    SharesDisplayComponent,
    SharesCreateComponent,
    ShareDetailComponent,
    ForgotPasswordComponent,
    SignupComponent,
    LoginComponent,
    TermsOfServiceComponent,
    PrivacyPolicyComponent,
    FaqComponent,
    ContactUsComponent,
    MusingModalComponent,
    AppPageModalComponent
  ],
  exports: [
    ShellModule,
    CheckboxWrapperComponent,
    ShowHidePasswordComponent,
    CountdownTimerComponent,
    CounterInputComponent,
    RatingInputComponent,
    GoogleMapComponent,
    TextPopoverComponent,
    ErrorModalComponent,
    UploadModalComponent,
    LoadingComponent,
    ConnectionsComponent,
    LocationComponent,
    NetworkComponent,
    MessageSummaryComponent,
    CompanySummaryComponent,
    FooterComponent,
    MusingsComponent,
    ProfileDisplayComponent,
    UploadSummaryComponent,
    AppMessageComponent,
    AppMessageModalComponent,
    CardActionPopoverComponent,
    MenuPopoverComponent,
    MenuComponent,
    DefaultAvatarComponent,
    SharesDisplayComponent,
    SharesCreateComponent,
    ShareDetailComponent,
    ForgotPasswordComponent,
    SignupComponent,
    LoginComponent,
    TermsOfServiceComponent,
    PrivacyPolicyComponent,
    FaqComponent,
    ContactUsComponent,
    MusingModalComponent,
    AppPageModalComponent
  ]
})
export class ComponentsModule {}

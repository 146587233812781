<div class="footer-container" [ngClass]="{'background-secondary': background === 'background-secondary'}">
  <div class="mx-auto mb-8" [ngClass]="{'w-500': isLargeView, 'w-250': !isLargeView}">
    <div *ngIf="isLargeView" class="width-full flex-row align-center justify-space-between">
      <p (click)="showCardModal('faq')">FAQ</p>
      <p (click)="showCardModal('contact')">Contact Us</p>
      <p (click)="showCardModal('privacy')">Privacy & Cookies</p>
      <p (click)="showCardModal('terms')">Terms of Use</p>
    </div>
    <div  *ngIf="!isLargeView" class="width-full flex-row align-center justify-space-between">
      <p (click)="showCardModal('faq')">FAQ</p>
      <p (click)="showCardModal('contact')">Contact Us</p>
    </div>
    <div  *ngIf="!isLargeView" class="width-full flex-row align-center justify-space-between">
      <p (click)="showCardModal('privacy')">Privacy & Cookies</p>
      <p (click)="showCardModal('terms')">Terms of Use</p>
    </div>
  </div>
  <div class="width-full flex-row align-center justify-center">
    <span class="copy">© 2023 yeahshare</span>
  </div>
</div>
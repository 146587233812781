<ion-header *ngIf="isLargeView">
  <ion-toolbar color="primary" ion-toolbar mode="md">
    <ion-buttons *ngIf="!isLargeView" slot="start">
      <ion-menu-button menu="none" autoHide="false" color="light" (click)="handleRouteEvent(appRoutes.back)">
        <ion-icon class="fs-24" name="arrow-back-outline"></ion-icon>
      </ion-menu-button>
    </ion-buttons>
    <ion-title>Upload</ion-title>
    <ion-buttons *ngIf="isLargeView" slot="end">
      <ion-button color="primay" fill="solid" type="button" color="primary" fill="solid" (click)="handleRouteEvent(appRoutes.dismiss)">
        Close
        <ion-icon slot="end" name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding" [ngClass]="{'content-56': isLargeView}">

    <div class="width-full">
      <div class="flex-row align-center width-full upload-summary-title" style="justify-content: space-between;">
        <h1 class="mt-0">My Uploads</h1>

        <div class="flex-row align-center">
          <ion-button (click)="handleRouteEvent(appRoutes.upload)" type="button" color="primary" fill="clear" class="toolbar-button">
            Upload Archive
            <ion-icon slot="end" name="cloud-upload-outline"></ion-icon>
          </ion-button>
        </div>
      </div>

      <div *ngIf="showUploadError" class="flex-row" style="width: 100%; justify-content: center; color: red;">
        <p>Error uploading zip. Please try again.</p>
      </div>

      <div>
        <ion-list>
          <ion-item *ngIf="zeroUploads">
            <ion-label>
              No uploads? Click "+" to upload.
            </ion-label>
          </ion-item>
          <ion-item *ngFor="let upload of uploads; let index ">
            <ion-label>
              <div *ngIf="upload" class="flex-column">
                <p [innerHTML]="util.capitalizeFirstLetter(upload.type, 'Archive')"></p>
                <p [innerHTML]="util.getDateAndTime(upload.createdAt)"></p>
              </div>
            </ion-label>
            <p [innerHTML]="util.capitalizeFirstLetter(upload?.uploadStatus)"></p>
          </ion-item>
        </ion-list>
      </div>
    </div>

  </ion-content>
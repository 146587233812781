<ion-modal [isOpen]="modalShowing" [cssClass]="this.isLargeView ? 'modal-tall' : ''" (didDismiss)="modalShowing=false">
    <ng-template>

      <ion-header>
        <ion-toolbar color="primary" ion-toolbar mode="md">
          <ion-buttons slot="start">
            <ion-menu-button menu="none" autoHide="false" color="light" (click)="closeModal()">
              <ion-icon class="fs-24" name="arrow-back-outline"></ion-icon>
            </ion-menu-button>
          </ion-buttons>
          <ion-title>Share detail</ion-title>
        </ion-toolbar>
      </ion-header>
      
      <ion-content class="ion-padding">

        <ion-item>
          <ion-label color="tertiary">Share Link:</ion-label>
          <div class="clamp-2 fs-14" style="max-width: calc(100% - 50px);" [innerHTML]="getShareLink(share.id, true)"></div>
          <ion-buttons slot="end" class="mr--10">
            <ion-button (click)="copyLink()" color="primary" fill="clear" class="toolbar-button">
              <ion-icon name="clipboard-outline"></ion-icon>
            </ion-button>
            <ion-button (click)="goToShare()" color="primary" fill="clear" class="toolbar-button">
              <ion-icon name="open-outline"></ion-icon>
            </ion-button>
          </ion-buttons>
        </ion-item>

        <ion-item>
          <ion-label color="tertiary">Recipient Name:</ion-label>
          {{ share.sharedWithName }}
        </ion-item>
        
        <ion-item>
          <ion-label color="tertiary">Recipient Email:</ion-label>
          {{ share.sharedWithEmail }}
        </ion-item>

        <ion-item>
          <ion-label color="tertiary"><span *ngIf="isLargeView">Last </span>Updated:</ion-label>
          <div [innerHTML]="getDate(share.createdAt)"></div>
        </ion-item>

        <ion-item *ngIf="share.sendEmail">
          <ion-label color="tertiary">Shared Message:</ion-label>
          <div class="fs-14 width-max-200 ion-text-end">Yeahshare delivered your record link via email.</div>
          <div *ngIf="share.sharedWithEmailMessage" class="pt-6 pl-8 cursor-pointer" (click)="viewMessage($event)"><ion-icon class="fs-24" color="tertiary" name="eye-outline"></ion-icon></div>
        </ion-item>
        <ion-item *ngIf="!share.sendEmail" class="color-medium-shade">
          <ion-label>Message:</ion-label>
          <div class="fs-14 width-max-200 ion-text-end">Yeahshare did not send your record link, per your request.</div>
        </ion-item>

        <ion-item>
          <ion-label color="tertiary">Views:</ion-label>
          {{ share.viewed }}
          <div *ngIf="share.shareViews.length > 0" class="pt-6 pl-8 cursor-pointer" (click)="viewShares($event)"><ion-icon color="tertiary" name="eye-outline"></ion-icon></div>
        </ion-item>

        <div [@showHide]="showShares">
          <ion-buttons class="ion-align-items-center ion-float-end">
            <ion-button type="button" color="primary" fill="clear" class="toolbar-button"  (click)="showShares = false">
              Close
              <ion-icon slot="end" name="close-outline"></ion-icon>
            </ion-button>
          </ion-buttons>
          <h3>View history</h3>
          <ion-item *ngFor="let view of share.shareViews; let index" class="fs-14">
            <ion-label color="primary">{{ view?.sharedWithEmail }}</ion-label>
            <p class="color-medium-shade" [innerHTML]="util.getDateAndTime(view?.createdAt)"></p>
          </ion-item>
        </div>

        <h3>Shared Content</h3>

        <div class="flex-row">
          <ion-item style="width: 50%;">
            <ion-checkbox slot="start" [checked]="share.locationShared" (ionChange)="share.locationShared = !!!share.locationShared"></ion-checkbox>
            <ion-label [ngClass]="{'color-medium-shade': !share.locationShared}">Location</ion-label>
          </ion-item>
          <div style="width: 50%;" class="flex-row">
            <p [ngClass]="{'color-medium-shade': !share.locationShared}">Top:</p>
            <ion-item >
              <ion-select [disabled]="!share.locationShared" [ngModel]="getString(share.locationTop)" (ionChange)="share.locationTop = getNumber($event.target.value)">
                <ion-select-option value="10">10</ion-select-option>
                <ion-select-option value="50">50</ion-select-option>
                <ion-select-option value="100">100</ion-select-option>
                <ion-select-option value="-1">All</ion-select-option>
              </ion-select>
            </ion-item>
          </div>
        </div>

        <div class="flex-row">
          <ion-item style="width: 50%;">
            <ion-checkbox slot="start" [checked]="share.companiesShared" (ionChange)="share.companiesShared = !!!share.companiesShared"></ion-checkbox>
            <ion-label [ngClass]="{'color-medium-shade': !share.companiesShared}">Companies</ion-label>
          </ion-item>
          <div style="width: 50%;" class="flex-row">
            <p [ngClass]="{'color-medium-shade': !share.companiesShared}">Top:</p>
            <ion-item >
              <ion-select [disabled]="!share.companiesShared" [ngModel]="getString(share.companiesTop)" (ionChange)="share.companiesTop = getNumber($event.target.value)">
                <ion-select-option value="10">10</ion-select-option>
                <ion-select-option value="50">50</ion-select-option>
                <ion-select-option value="100">100</ion-select-option>
                <ion-select-option value="-1">All</ion-select-option>
              </ion-select>
            </ion-item>
          </div>
        </div>

        <div class="flex-row">
          <ion-item style="width: 50%;">
            <ion-checkbox slot="start" [checked]="share.messagesShared" (ionChange)="share.messagesShared = !!!share.messagesShared"></ion-checkbox>
            <ion-label [ngClass]="{'color-medium-shade': !share.messagesShared}">Messages</ion-label>
          </ion-item>
          <div style="width: 50%;" class="flex-row">
            <p [ngClass]="{'color-medium-shade': !share.messagesShared}">Top:</p>
            <ion-item >
              <ion-select [disabled]="!share.messagesShared" [ngModel]="getString(share.messagesTop)" (ionChange)="share.messagesTop = getNumber($event.target.value)">
                <ion-select-option value="10">10</ion-select-option>
                <ion-select-option value="50">50</ion-select-option>
                <ion-select-option value="100">100</ion-select-option>
                <ion-select-option value="-1">All</ion-select-option>
              </ion-select>
            </ion-item>
          </div>
        </div>

        <div class="flex-row">
          <ion-item style="width: 50%;">
            <ion-checkbox slot="start" [checked]="share.networkShared" (ionChange)="share.networkShared = !!!share.networkShared"></ion-checkbox>
            <ion-label [ngClass]="{'color-medium-shade': !share.networkShared}">Network</ion-label>
          </ion-item>
          <div style="width: 50%;" class="flex-row">
            <p [ngClass]="{'color-medium-shade': !share.networkShared}">Top:</p>
            <ion-item >
              <ion-select [disabled]="!share.networkShared" [ngModel]="getString(share.networkTop)" (ionChange)="share.networkTop = getNumber($event.target.value)">
                <ion-select-option value="10">10</ion-select-option>
                <ion-select-option value="50">50</ion-select-option>
                <ion-select-option value="100">100</ion-select-option>
                <ion-select-option value="all">All</ion-select-option>
              </ion-select>
            </ion-item>
          </div>
        </div>

        <div class="pt-10">
          <ion-button type="submit" expand="block" color="primary" fill="solid" (click)="update()">Update</ion-button>
          <ion-button type="submit" expand="block" color="primary" fill="outline" (click)="delete($event)">Delete</ion-button>
        </div>
        
      </ion-content>

    </ng-template>
  </ion-modal>

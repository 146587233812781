import { IBaseApiModel } from '../interfaces/i-base-api-model';

export class SignInRequest implements IBaseApiModel {
  public email: string;
  public password: string;
  public includeToken = false;
  public includeSecret = false;

  // UX Properties
  public rememberMe = false;

  constructor(obj?: any) {
    Object.assign(this, obj);
    this.includeToken = this.includeToken ? this.includeToken : false;
    this.includeSecret = this.includeSecret ? this.includeSecret : false;
  }

  get isValid(): boolean {
    return !!this.email && !!this.password;
  }

  public cleanseForAPI(): void {
    const removeKeys = ['rememberMe'];
    const currentKeys = Object.keys(this);
    currentKeys.forEach((key) => {
      if (removeKeys.indexOf(key) > -1) {
        delete this[key];
      }
    });
  }

}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AppRoutes } from '../enums/app-routes.enum';
import { StorageKeys } from '../enums/storage-keys.enum';
import { StorageService } from '../services/storage.service';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  private currentUrl: string;

  constructor(private router: Router, private storageService: StorageService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.currentUrl = state.url;
    return this.check(route);
  }

  private async check(route: ActivatedRouteSnapshot) {
    await this.utmTracking(route);

    const jwt = await this.storageService.get(StorageKeys.jwt);
    // Check that the user is logged in    
    if (!jwt && this.currentUrl !== AppRoutes.home) {
      const pIdx = this.currentUrl.indexOf('?');
      const params = pIdx > -1 ? this.currentUrl.substring(pIdx) : '';
      this.router.navigateByUrl(`${AppRoutes.home}${params}`);
    }

    switch (this.currentUrl) {
      default:
        // must not be a protected route
        return true;
    }
  }

  private async utmTracking(route: ActivatedRouteSnapshot) {
    const utm_id: string = route.queryParams.utm_id ? route.queryParams.utm_id : '';
    const utm_source: string = route.queryParams.utm_source ? route.queryParams.utm_source : '';
    const utm_medium: string = route.queryParams.utm_medium ? route.queryParams.utm_medium : '';
    const utm_campaign: string = route.queryParams.utm_campaign ? route.queryParams.utm_campaign : '';
    const utm_term: string = route.queryParams.utm_term ? route.queryParams.utm_term : '';
    const utm_content: string = route.queryParams.utm_content ? route.queryParams.utm_content : '';
    if (utm_id.length > 0) {
      const utmTracking: any = {};
      utmTracking.when = moment().format();
      utmTracking.utm_id = utm_id;
      if (utm_source.length > 0) utmTracking.utm_source = utm_source;
      if (utm_medium.length > 0) utmTracking.utm_medium = utm_medium;
      if (utm_campaign.length > 0) utmTracking.utm_campaign = utm_campaign;
      if (utm_term.length > 0) utmTracking.utm_term = utm_term;
      if (utm_content.length > 0) utmTracking.utm_content = utm_content;
      this.storageService.set(StorageKeys.utm, utmTracking).then(() => {});
    }
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UserProfile } from '../../models/user-profile';
import { BaseComponent } from '../../components/base/base.component';
import { LinkedInConnection } from '../../models/linkedin-connection';
import { InfiniteScrollCustomEvent } from '@ionic/angular';
import { ConnectionsService } from '../../services/connections.service';
import { takeUntil } from 'rxjs/operators';
import { AppRoutes } from '../../enums/app-routes.enum';
import { Util } from 'app/util';
import { SearchFilterPipe } from 'app/pipes/search-filter.pipe';
import FuzzySearch from 'fuzzy-search';

@Component({
  selector: 'app-connections',
  templateUrl: './connections.component.html',
  styleUrls: ['./connections.component.scss']
})
export class ConnectionsComponent extends BaseComponent implements OnInit {
  @Input() profile$ = new BehaviorSubject<UserProfile>(null);
  @Input() closeConnectionsModal: () => void;
  @Input() searchTerm: string;
  @Input() searchType: string;
  public page = 0;
  public pageSize = 10;
  public totalPages = null;

  public connections = new Array<LinkedInConnection>();
  public filteredConnections = new Array<LinkedInConnection>();
  public displayedConnections = new Array<LinkedInConnection>();
  public lastIndex = 0;

  // search/filter
  public search = true;
  public filterType: string;
  public searchValue = '';
  public filterValue: string;
  public showMore = true;
  private readonly fuzzySearch = new SearchFilterPipe();

  public modalShowing = false;
  public detailedConnection: LinkedInConnection;
  public profile = new UserProfile();

  public util = Util;


  constructor(private connectionService: ConnectionsService) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.subscribeToProfile();
  }

  /*
  Subscriptions
  */

  private subscribeToProfile() {
    this.profile$.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (profile) => {
        if (!this.profile && profile) {
          this.profile = profile;
          this.page = 1;
          this.getConnections();
        } else if (profile && this.profile.id !== profile.id) {
          this.profile = profile;
          this.page = 1;
          this.getConnections();
        }
      },
      error: (error) => {
        this.handleError(error);
      }
    });
  }

  /*
  API calls
  */

  private getConnections() {
    this.connectionService.getlatest().subscribe({
      next: (res) => {
        res.rows.forEach((connection) => {
          if (connection.firstName && connection.firstName !== '') {
            this.connections.push(connection);
          }
        });

        this.connections.sort((a, b) => {
          const fullNameA = a.firstName + ' ' + a.lastName;
          const fullNameB = b.firstName + ' ' + b.lastName;
          return fullNameA.localeCompare(fullNameB);
        });

        this.filteredConnections = this.connections;

        if (this.searchTerm && this.searchType === 'connectionId') {
          this.getDetailedConnectionWithMessages(this.searchTerm);
          this.searchType = '';
          this.searchTerm = '';
          this.search = true;
          this.filteredConnections = this.connections;
          this.resetDisplayedConnections();
          this.showModal(this.detailedConnection);
        } else if (this.searchTerm) {
          if (this.searchType) {
            this.changeFilter(this.searchType);
          }
    
          const sterm  = this.searchTerm;
          this.searchTerm = null;
          this.searchValue = sterm;
          this.filterValue = this.filterType;
          this.newSearchInput(sterm);
        }

        this.resetDisplayedConnections();
      },
      error: (error) => {
        this.handleError(error);
      }
    });
  }

  private getDetailedConnectionWithMessages(connectionId: string) {
    this.connectionService.get(connectionId, ["LinkedInMessages"]).subscribe({
      next: (res) => {
        this.detailedConnection = res;
        this.detailedConnection.linkedInMessages.sort((a, b) => {
          return new Date(a.date).getTime() - new Date(b.date).getTime();
        });
      },
      error: (error) => {
        this.handleError(error);
      }
    });
  }

  /*
  Utils
  */

  public getMoreConnections() {
    this.lastIndex = this.lastIndex + 10;
    if (this.lastIndex > this.filteredConnections.length) {
      this.lastIndex = this.filteredConnections.length;
      this.showMore = false;
    }
    this.displayedConnections = this.filteredConnections.slice(0, this.lastIndex + 1);
  }

  public resetDisplayedConnections() {
    this.lastIndex = 0;
    this.showMore = true;
    this.getMoreConnections();
  }

  public onIonInfinite(event) {
    this.getMoreConnections();
    setTimeout(() => {
      (event as InfiniteScrollCustomEvent).target.complete();
    }, 1000);
  }

  public showModal(connection: LinkedInConnection) {
    this.modalShowing = true;
    this.detailedConnection = connection;
    this.getDetailedConnectionWithMessages(connection.id);
  }

  public closeModal() {
    this.modalShowing = false;
    this.detailedConnection = null;
  }

  public moreExist(): boolean {
    return false;
  }

  /*
  Search and Filter
  */

  public setSearch(s: boolean) {
    this.search = s;
    this.filteredConnections = this.connections;
    this.resetDisplayedConnections();
    this.searchValue = null;
    this.filterValue = null;
  }

  public getSearchTabClass(): string {
    if (this.search) {
      return '';
    } else {
      return 'tabUnselected';
    }
  }

  public getFilterTabClass(): string {
    if (!this.search) {
      return '';
    } else {
      return 'tabUnselected';
    }
  }

  public handleFilterChange(event) {
    const filter = event.target.value;
    this.changeFilter(filter);
  }

  private changeFilter(filter: string) {
    switch (filter) {
      case 'company':
        this.filterType = 'company';
        break;
      case 'position':
        this.filterType = 'position';
        break;
      case 'location':
        this.filterType = 'location';
        break;
      default:
        this.filterType = null;
        break;
    }

    this.newSearchInput(this.searchValue);
  }

  public handleSearchChange(event) {
    const searchTerm = event.target.value;
    this.newSearchInput(searchTerm);
  }

  private newSearchInput(searchTerm: string) {
    if (searchTerm === '') {
      this.filteredConnections = this.connections;
      this.resetDisplayedConnections();
    } else {
      const fs = new FuzzySearch(this.connections, [
        'firstName', 'lastName', 'companyName','position',
        'location.city','location.state','location.country'
      ], { caseSensitive: false, sort: true });
      this.filteredConnections = fs.search(searchTerm);
      this.resetDisplayedConnections();
    }
    /*

      this.filteredConnections = this.connections.filter((connection) => {
        const fullName = connection.firstName + ' ' + connection.lastName;
        return connection.firstName.toLowerCase().startsWith(searchTerm.toLowerCase()) ||
          connection.lastName.toLowerCase().startsWith(searchTerm.toLowerCase()) ||
          fullName.toLowerCase().startsWith(searchTerm.toLowerCase());
      });

      switch (this.filterType) {
        case 'company':
          this.filteredConnections = this.connections.filter((connection) => {
            return connection.companyName.toLowerCase().startsWith(searchTerm.toLowerCase());
          });
          this.resetDisplayedConnections();
          break;
        case 'position':
          this.filteredConnections = this.connections.filter((connection) => {
            return connection.position.toLowerCase().includes(searchTerm.toLowerCase());
          });
          this.resetDisplayedConnections();
          break;
        case 'location':
          this.filteredConnections = this.connections.filter((connection) => {
            if (!connection.location) {
              return false;
            }
            return connection.location.city?.toLowerCase().includes(searchTerm.toLowerCase()) ||
              connection.location.country?.toLowerCase().includes(searchTerm.toLowerCase()) ||
              connection.location.state?.toLowerCase().includes(searchTerm.toLowerCase());
          });
          this.resetDisplayedConnections();
          break;
        default:
          this.filteredConnections = this.connections.filter((connection) => {
            const fullName = connection.firstName + ' ' + connection.lastName;
            return connection.firstName.toLowerCase().startsWith(searchTerm.toLowerCase()) ||
              connection.lastName.toLowerCase().startsWith(searchTerm.toLowerCase()) ||
              fullName.toLowerCase().startsWith(searchTerm.toLowerCase());
          });
          break;
      }
    */
  }

  public closeThisModal() {
    if (this.closeConnectionsModal) {
      this.closeConnectionsModal();
    } else {
      this.handleRouteEvent(AppRoutes.dismiss)
    }
  }

}

<ion-header *ngIf="isLargeView">
  <ion-toolbar color="primary" ion-toolbar mode="md">
    <ion-buttons *ngIf="!isLargeView" slot="start">
      <ion-menu-button menu="none" autoHide="false" color="light" (click)="handleRouteEvent(appRoutes.back)">
        <ion-icon class="fs-24" name="arrow-back-outline"></ion-icon>
      </ion-menu-button>
    </ion-buttons>
    <ion-title>Share your record</ion-title>
    <ion-buttons *ngIf="isLargeView" slot="end">
      <ion-button color="primay" fill="solid" type="button" color="primary" fill="solid" (click)="handleRouteEvent(appRoutes.dismiss)">
        Close
        <ion-icon slot="end" name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding" [ngClass]="{'content-56': isLargeView}">

  <ion-refresher *ngIf="isMobileWeb" (ionRefresh)="refresh($event)" slot="fixed">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>

  <h1 class="mt-0" *ngIf="isLargeView">How it works</h1>
  <h1 class="mt-0" *ngIf="!isLargeView">Share your record</h1>
  <ul class="share-how-bullet">
    <li>Securely send your yeahshare record to help others identify opportunities within your relationships.</li>
    <li>Share only the parts of your LinkedIn network you want to share. Track who accesses your record.</li>
    <li>More easily help you and yours get hired, get ahead, and get better engaged.</li>
  </ul>

  <div class="flex-row align-center justify-space-between">
    <h2>Share History</h2>
    <ion-button color="primay" fill="solid" type="button" color="primary" fill="solid"  (click)="showCreateModal()">
      Share
      <ion-icon slot="end" name="share-outline"></ion-icon>
    </ion-button>
  </div>

  <ion-list>
    <ion-item *ngIf="shares.length === 0">
      <ion-label>
        No Shares. Click icon to share.
      </ion-label>
    </ion-item>
    <ion-item *ngFor="let share of shares; let index" (click)="showDetailModal(share)" class="cursor-pointer">
      <ion-label color="primary">
        <div class="flex-column" [ngClass]="{'width-max-225': !isLargeView}">
          <div class="clamp-1 fw-500">{{ share?.sharedWithName }}</div>
          <div class="clamp-2">{{ share?.sharedWithEmail }}</div>
          <div class="color-medium-shade" [innerHTML]="util.getDateAndTime(share?.createdAt)"></div>
        </div>
      </ion-label>
      <ion-row class="ion-align-items-center">
        <ion-col>
          <span class="color-primary"><span class="fw-500">{{ share.viewed }}</span> Views</span>
        </ion-col>
        <ion-col *ngIf="isLargeView" size="auto">
          <ion-icon class="ml-16 fs-24" color="tertiary" name="eye-outline"></ion-icon>
        </ion-col>
      </ion-row>
    </ion-item>
  </ion-list>

  <app-share-detail 
    [closeDetailModal]="closeDetailModal.bind(this)"
    [profile]="profile"
    [modalShowing]="detailModalShowing"
    [share]="detailShare"
  ></app-share-detail>

  <app-shares-create 
    [closeCreateModal]="closeCreateModal.bind(this)"
    [profile]="profile"
    [modalShowing]="createModalShowing"
    [linkedInUploadId]="latestUpload?.id"
    [linkedInProfileId]="latestProfile?.id"
  ></app-shares-create>

</ion-content>
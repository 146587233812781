import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiUrls } from '../enums/api-urls.enum';
import { LinkedInConnection } from '../models/linkedin-connection';
import { UserProfile } from '../models/user-profile';
import { ApiService } from './api.service';
import { LinkedInConnectionsResponse } from '../models/linkedin-connections-response';
import { LinkedInLocationResponse } from '../models/linkedin-location-response';
import { TitleResponse } from '../models/title-response';
import { MessageSummaryResponse } from '../models/message-summary';
import { CompanySummaryResponse } from '../models/company-summary';

@Injectable({
  providedIn: 'root'
})
export class SummaryService {
  private source = 'SummaryService';

  constructor(private apiService: ApiService) {
  }

  public getLocationLatest(): Observable<Array<LinkedInLocationResponse>> {
    const func = 'get';

    return this.apiService.get(this.source, func, `${ApiUrls.summary}/locations/latest`)
      .pipe(map((res) => {
        return res.map((r) => new LinkedInLocationResponse(r));
      }, (error) => {
        return error;
      }));
  }

  public getTitlesLatest(): Observable<Array<TitleResponse>> {
    const func = 'get';

    return this.apiService.get(this.source, func, `${ApiUrls.summary}/titles/latest`)
      .pipe(map((res) => {
        return res.map((r) => new TitleResponse(r));
      }, (error) => {
        return error;
      }));
  }

  public getMessagesLatest(): Observable<MessageSummaryResponse> {
    const func = 'get';

    return this.apiService.get(this.source, func, `${ApiUrls.summary}/messages/latest`)
      .pipe(map((res) => {
        return new MessageSummaryResponse(res);
      }, (error) => {
        return error;
      }));
  }

  public getCompaniesLatest(): Observable<CompanySummaryResponse> {
    const func = 'get';

    return this.apiService.get(this.source, func, `${ApiUrls.summary}/companies/latest`)
      .pipe(map((res) => {
        return new CompanySummaryResponse(res);
      }, (error) => {
        return error;
      }));
  }
}

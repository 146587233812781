export enum AppRoutes {
  // unauthenticated
  home = '/home',
  login = '/auth/login',
  signup = '/auth/signup',
  forgotPassword = '/auth/forgot-password',
  share = '/share',
  about = 'about',
  // mixed
  dismiss = 'dismiss',
  back = 'back',
  logout = 'logout',
  upload = 'upload',
  // authenticated
  uploadFile = 'uploadFile',
  profile = '/app/profile',
  uploads = '/app/uploads',
  shares = '/app/shares',
  record = '/app/record',
  connections = '/app/connections',
  // testing
  zeroUploads = 'zeroUploads',
  processing = 'processing',
  latestFailed = 'latestFailed',
  loader = 'loader',
  // TODO routes
  noNetwork = '/no-network'
}

import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UserProfile } from '../../models/user-profile';
import { BaseComponent } from '../base/base.component';
import { LinkedInConnection } from '../../models/linkedin-connection';
import { InfiniteScrollCustomEvent } from '@ionic/angular';
import { ConnectionsService } from '../../services/connections.service';
import { takeUntil } from 'rxjs/operators';
import { ViewChild } from '@angular/core';
import { EChartsOption } from 'echarts';
import { ElementRef } from '@angular/core';
import { SummaryService } from '../../services/summary.service';
import { TitleResponse } from '../../models/title-response';

@Component({
  selector: 'app-network',
  templateUrl: './network.component.html',
  styleUrls: ['./network.component.scss']
})
export class NetworkComponent extends BaseComponent implements OnInit {
  @Input() openConnectionsModal: (searchTerm?: string, searchType?: string) => void;
  @Input() shareDisplay$: BehaviorSubject<string>;
  @Input() network$: BehaviorSubject<Array<TitleResponse>>;
  public shareDisplay: string;

  public chartOption: EChartsOption

  public displayedNetworks = [];

  public lastIndex = 0;
  public showMore = true;
  public titles: Array<TitleResponse>;
  public noTitlePercent: number;

  @ViewChild('chartBlock') chartBlock: ElementRef;

  constructor(private summaryService: SummaryService) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.subscribeToShareDisplay();
    this.subscribeToNetwork();
  }

  /*
  Subscriptions
  */

  private subscribeToShareDisplay() {
    this.shareDisplay$.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (shareDisplay) => {
        this.shareDisplay = shareDisplay;
      },
      error: (error) => {
        this.handleError(error);
      }
    });
  }

  private subscribeToNetwork() {
    this.network$.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (titles) => {
        if (titles && titles.length > 0) {
          const undetermined = titles.find(t => t.title === 'undetermined');
          if (undetermined) {
            this.noTitlePercent = undetermined.percentOfConnections;
          }

          this.titles = titles.filter(t => t.title !== 'undetermined').sort((a, b) => b.numConnections - a.numConnections);

          const firstTen = this.titles.slice(0, 10);
          const chart = [];
          for (let i = 0; i < 5; i++) {
            chart.push({
              value: firstTen[i].numConnections,
              name: firstTen[i].title,
              label: {show: this.isLargeView ? true : false}
            });
          }

          this.chartOption = {
            tooltip: {
              trigger: 'item'
            },
            series: [
              {
                type: 'pie',
                radius: ['15%', '75%'],
                center: ['50%', '50%'],
                roseType: 'area',
                itemStyle: {
                  borderRadius: 8
                },
                data: chart,
                emphasis: {
                  itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                  }
                },
                color: ['#004a43', '#1a5c56', '#879491', '#99a8a5', '#bfcfcd'],
                cursor: 'unset'
              }
            ],
            legend: {show: this.isLargeView ? false : true, top: 'top', left: 'left', orient: 'vertical'}
          }

          this.lastIndex = 0;
          this.getMoreNetworks();
        }
      },
      error: (error) => {
        this.handleError(error);
      }
    });
  }

  /*
  Utils
  */

  public getMoreNetworks() {
    if (!this.titles) {
      return;
    }

    this.lastIndex += 10;
    if (this.lastIndex > this.titles.length) {
      this.lastIndex = this.titles.length;
      this.showMore = false;
    }
    this.displayedNetworks = this.titles.slice(0, this.lastIndex);
  }

  public resetNetworks(scroll: boolean = false) {
    if (scroll) {
      document.getElementById("network-top").scrollIntoView();
    }
    this.lastIndex = 0;
    this.showMore = true;
    this.getMoreNetworks();
  }

  public formatPercent(percent: number): string {
    if (!percent) {
      return 'unknown number';
    }
    return (percent * 100).toFixed(2) + "%";
  }

  public openConnModal(searchTerm?: string, searchType?: string) {
    this.openConnectionsModal(searchTerm, searchType);
  }
}

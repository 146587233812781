import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BaseComponent } from '../../components/base/base.component';
import { LinkedInConnection } from '../../models/linkedin-connection';
import { ConnectionsService } from '../../services/connections.service';
import { takeUntil } from 'rxjs/operators';
import { ViewChild } from '@angular/core';
import { EChartsOption } from 'echarts';
import { ElementRef } from '@angular/core';
import { SummaryService } from '../../services/summary.service';
import { MessageSummary, MessageSummaryResponse } from '../../models/message-summary';
import { Util } from 'app/util';

@Component({
  selector: 'app-message-summary',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss']
})
export class MessageSummaryComponent extends BaseComponent implements OnInit {
  @Input() openConnectionsModal: (searchTerm?: string, searchType?: string) => void;
  @Input() shareDisplay$: BehaviorSubject<string>;
  @Input() messageSummary$: BehaviorSubject<MessageSummaryResponse>;
  public shareDisplay: string;

  public lastIndex = 0;
  public showMore = true;

  @ViewChild('chartBlock') chartBlock: ElementRef;

  public messageSummary: MessageSummaryResponse;
  public messages: Array<MessageSummary>;
  public displayedMessages: Array<MessageSummary>;
  public notConnected: number;

  public chartOption: EChartsOption;
  public chartHtml: string = '';

  public modalShowing = false;
  public detailedConnection: LinkedInConnection;

  public util = Util;

  constructor(private summaryService: SummaryService, private connectionService: ConnectionsService) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.subscribeToShareDisplay();
    this.subscribeToMessageSummary();
  }

  /*
  Subscriptions
  */

  private subscribeToShareDisplay() {
    this.shareDisplay$.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (shareDisplay) => {
        this.shareDisplay = shareDisplay;
      },
      error: (error) => {
        this.handleError(error);
      }
    });
  }

  private subscribeToMessageSummary() {
    this.messageSummary$.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (messageSummary) => {
        if (messageSummary && messageSummary.messages.length > 0) {
          this.notConnected = messageSummary.messages.find((a) => { return a.firstName === "undetermined" }).percentOfMessages;
          this.messageSummary = messageSummary;
          this.messages = messageSummary.messages.filter((a) => { return a.firstName !== "undetermined" }).sort((a, b) => { return b.numMessages - a.numMessages; });
          this.resetMessages();
          this.setChart();
        }
      },
      error: (error) => {
        this.handleError(error);
      }
    });
  }

  /*
  API
  */

  public getMessages() {
    this.summaryService.getMessagesLatest().pipe(takeUntil(this.destroyed$)).subscribe({
      next: (messageSummary) => {
        this.notConnected = messageSummary.messages.find((a) => { return a.firstName === "undetermined" }).percentOfMessages;
        this.messageSummary = messageSummary;
        this.messages = messageSummary.messages.filter((a) => { return a.firstName !== "undetermined" }).sort((a, b) => { return b.numMessages - a.numMessages; });
        this.resetMessages();
        this.setChart();
      },
      error: (error) => {
        this.handleError(error);
      }
    });
  }

  /*
  Utils
  */

  private setChart() {
    const chartAttributes = {title: {bottom: '20', fontSize: 20}, detail: {fontSize: 40}};

    const chartBlockDiv = document.getElementById('chartBlock');
    const chartBlockDims = chartBlockDiv ? chartBlockDiv.getBoundingClientRect() : {height: 450, width: 275};
    if (chartBlockDims && chartBlockDims.height) {
      chartAttributes.title.bottom = this.isLargeView ? '7%' : '5.5%';
      chartAttributes.title.fontSize = this.isLargeView ? chartBlockDims.height * 0.0475 : chartBlockDims.height * 0.055;
    } else {
      chartAttributes.title.bottom = this.isLargeView ? '7%' : '5.5%';
      chartAttributes.title.fontSize = this.isLargeView ? 20 : 15;
    }

    const option = {
      title: {
        text: 'Response Rate',
        bottom: chartAttributes.title.bottom,
        left: 'center',
        textStyle: {
          fontSize: chartAttributes.title.fontSize,
          color: '#0092ba'
        }
      },
      series: [
        {
          type: 'gauge',
          progress: {
            show: true,
            width: 12,
            itemStyle: {
              color: '#004a43'
            }
          },
          pointer: {
            itemStyle: {
              color: '#004a43'
            }
          },
          axisLine: {
            lineStyle: {
              width: 12,
              color: [[1, '#d9ebe9']]
            }
          },
          axisTick: {
            show: true
          },
          splitLine: {
            length: 6,
            lineStyle: {
              width: 2,
              color: '#999'
            },
            show: false
          },
          axisLabel: {
            distance: 15,
            color: '#999',
            fontSize: 12
          },
          anchor: {
            show: true,
            showAbove: true,
            size: 12,
            itemStyle: {
              borderWidth: 10,
              borderColor: '#004a43'
            }
          },
          detail: {
            valueAnimation: true,
            fontSize: chartAttributes.detail.fontSize,
            offsetCenter: [0, '80%'],
            formatter: function (value) {
              return `${value.toFixed(0)}%`;
            },
            color: '#004a43'
          },
          data: [
            {
              value: Math.round(this.messageSummary.stats.responseRate * 100)
            }
          ]          
        }
      ]
    };
    this.chartOption = (option as EChartsOption);
  }

  public getMoreMessages() {
    if (!this.messages) {
      return;
    }

    this.lastIndex += 10;
    if (this.lastIndex > this.messages.length) {
      this.lastIndex = this.messages.length;
      this.showMore = false;
    }
    this.displayedMessages = this.messages.slice(0, this.lastIndex);
  }

  public resetMessages(scroll: boolean = false) {
    if (scroll) {
      document.getElementById("messages-top").scrollIntoView();
    }
    this.lastIndex = 0;
    this.showMore = true;
    this.getMoreMessages();
  }

  public getTimeOfDay(time: number): string {
    if (time === 0) {
      return "12am";
    } else if (time < 12) {
      return time + "am";
    } else if (time === 12) {
      return "12pm";
    } else {
      return (time - 12) + "pm";
    }
  }

  public showModal(connectionId: string) {
    if (this.shareDisplay) {
      return;
    }

    this.getDetailedConnectionWithMessages(connectionId);
  }

  private getDetailedConnectionWithMessages(connectionId: string) {
    this.connectionService.get(connectionId, ["LinkedInMessages"]).subscribe({
      next: (res) => {
        this.detailedConnection = res;
        this.detailedConnection.linkedInMessages.sort((a, b) => {
          return new Date(a.date).getTime() - new Date(b.date).getTime();
        });
        this.modalShowing = true;
      },
      error: (error) => {
        this.handleError(error);
      }
    });
  }

  public closeModal() {
    this.modalShowing = false;
  }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './guards/auth-guard.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'app/record',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'auth/login',
    loadChildren: () => import('./unauthenticated/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'auth/signup',
    loadChildren: () => import('./unauthenticated/signup/signup.module').then(m => m.SignupPageModule)
  },
  {
    path: 'auth/forgot-password',
    loadChildren: () => import('./unauthenticated/forgot-password/forgot-password.module').then(m => m.ForgotPasswordPageModule)
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./unauthenticated/reset-password/reset-password.module').then(m => m.ResetPasswordPageModule)
  },
  {
    path: 'page-not-found',
    loadChildren: () => import('./unauthenticated/page-not-found/page-not-found.module').then(m => m.PageNotFoundModule)
  },
  {
    path: 'no-network',
    loadChildren: () => import('./unauthenticated/no-network/no-network.module').then(m => m.NoNetworkPageModule)
  },
  {
    path: 'share/:id',
    loadChildren: () => import('./pages/share/share.module').then(m => m.SharePageModule)
  },
  {
    path: 'logout',
    loadChildren: () => import('./logout/logout.module').then(m => m.LogoutPageModule)
  },
  {
    path: 'app',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/app.module').then(m => m.AppPageModule)
  },
  {
    path: '**',
    redirectTo: 'page-not-found'
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // This value is required for server-side rendering to work.
      initialNavigation: 'enabled',
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}

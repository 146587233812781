export class SignInResponse {
  public jwt: string;
  public token: string;
  public secret: string;
  public renewableSecret: boolean;
  public loggingToken: string;
  

  constructor(obj?: any) {
    Object.assign(this, obj);
  }

}

import {Injector} from '@angular/core';
import { AppError } from './models/error';
import { AppRoutes } from './enums/app-routes.enum';
import { RegExps } from './enums/reg-exps.enum';
import { BehaviorSubject } from 'rxjs';
import { LinkedInConnection } from './models/linkedin-connection';

// colors
const colors = [];


export class Util {
  static injector: Injector;

  public static getSpcObjectId(spc: string, route: AppRoutes): string {
    let idParam = null

    if (spc && spc.startsWith(route)) {
      const ids = spc.match(new RegExp(RegExps.guid, 'g'));
      if (ids && ids.length && spc.includes(route)) {
        idParam = ids[0];
      }
    }

    return idParam;
  }


  /**
   * Misc
   */

  // error
  public static processError(error: any, back?: boolean, route?: string, display?: boolean): AppError {
    let messages = new Array<string>();
    if (error && error.message) {
      if (Array.isArray(error.message)) {
        messages = messages.concat(error.message);
      } else {
        messages.push(error.message);
      }
    } else if (error && error.messages) {
      // is already AppError
      messages.push(error.messages);
    } else {
      messages.push(error);
    }
    const appError = new AppError({messages, back: false, route: null, display: true});
    if (error && error.back) {
      appError.back = error.back;
    }
    if (back) {
      appError.back = true;
    }
    if (error && error.route) {
      appError.route = error.route;
    }
    if (route) {
      appError.route = route;
    }
    if (error && error.display) {
      appError.display = error.display;
    }
    if (display) {
      appError.display = true;
    }
    if (error && error.correlationId && (error.correlationId as string).replace(' ', '').length) {
      appError.correlationId = error.correlationId;
    }
    if (error && error.status) {
      appError.status = error.status;
      if (appError.status === 401) {
        appError.display = false;
        appError.back = false;
        appError.route = null;
      }
    }
    return appError;
  }

  // behaviorSubject
  public static getBehaviorSubject(obj: any) {
    return new BehaviorSubject(obj);
  }

  // params
  public static getQueryParamsAsObject(queryParams: string): any {
    const objectToReturn = {};

    if (queryParams.indexOf('?') > -1) {
      queryParams = queryParams.substring(queryParams.indexOf('?'));
    }

    const pairs = queryParams.slice(1).split('&');
    pairs.forEach((pair) => {
      const splitPair = pair.split('=');
      objectToReturn[splitPair[0]] = decodeURIComponent(splitPair[1] || '');
    });

    return objectToReturn;
  }

  public static getSelectOptionFromEnum(enumToParse: any, value: string): { value: any, text: any } {
    const option = {value, text: ''};
    for (const key in enumToParse) {
      if (key === value) {
        option.value = key;
        option.text = enumToParse[key];
      }
    }
    return option;
  }

  // uuid
  public static uuidv4() {
    // @ts-ignore
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
      // tslint:disable-next-line:no-bitwise
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
  }

  // text
  public static prefix(text: string, prefix: string): string {
    return `${prefix}${text}`
  }

  public static suffix(text: string, suffix: string): string {
    return `${text}${suffix}`
  }

  public static capitalizeFirstLetter(s: string, suffix?: string): string {
    let text = s.charAt(0).toUpperCase() + s.slice(1);
    if (suffix) {
      text = `${text} ${suffix}`;
    }
    return text;
  }

  public static formatPercent(percent: number): string {
    if (!percent) {
      return 'unknown number';
    }
    let result = (percent * 100).toFixed(2);
    if (result.endsWith('.00')) {
      result = result.substring(0, result.length - 3);
    }
    return `${result}%`;
  }

  public static getPosAndCompanyText(connection: LinkedInConnection): string {
    if (connection.position && connection.companyName) {
      return connection.position + ', ' + connection.companyName;
    } else if (connection.position) {
      return connection.position;
    } else if (connection.companyName) {
      return connection.companyName;
    } else {
      return '';
    }
  }

  // date time
  public static getCurrentTimeTimestampInSeconds(): number {
    return new Date().valueOf();
  }

  public static getDate(s: string): string {
    const date = new Date(s);
    return date.toLocaleDateString().trim();
  }

  public static getDateAndTime(s: string, removeSeconds?: boolean): string {
    const date = new Date(s);
    if (removeSeconds) {
      return `${date.toLocaleDateString()} ${date.toLocaleTimeString([], {hour: 'numeric', minute: '2-digit'})}`;
    } else {
      return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
    }
  }

  // address
  public static getLocation(connection: LinkedInConnection): string {
    if (!connection?.location || !(connection.location.city || connection.location.state || connection.location.country)) {
      return '<span class="color-medium italic pr-8">undetermined</span>';
    }

    let place = "";
    if (connection.location.city) {
      place += connection.location.city;
    }
    if (connection.location.state) {
      if (place !== "") { place += ", "; }
      place += connection.location.state;
    }

    if (connection.location.country && connection.location.country !== 'United States') {
      if (place !== "") { place += ", "; }
      place += connection.location.country;
    }

    return place
  }

  // colors
  public static StringToColor(input: string): string {
    if (!input) {
      return '#FFFFFF';
    }
    let hash = 0;
    for (let i = 0; i < input.length; i++) {
      // tslint:disable:no-bitwise
      hash = input.charCodeAt(i) + ((hash << 5) - hash);
    }
    return colors[Math.abs(hash) % colors.length];
  }

  public static getRandomColor(): string {
    return colors[Math.floor(Math.random() * 15)];
  }

  public static generateGuid(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      // tslint:disable-next-line:no-bitwise
      const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  public static generateRandomString(length: number): string {
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let genString = '';
    for (let i = 0; i < length; i++) {
      genString += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return genString;
  }

  public static generateRandomArrayOfStrings(strLen: number, arrLen: number): Array<string> {
    const strArray = [];
    for (let i = 0; i < arrLen; i++) {
      strArray.push(this.generateRandomString(strLen));
    }
    return strArray;
  }

  public static getRandomNumInRange(from, to, fixed): number {
    return Number((Math.random() * (to - from) + from).toFixed(fixed));
  }

  public static getRandomLat() {
    return this.getRandomNumInRange(-90, 90, 4);
  }

  public static getRandomLong() {
    return this.getRandomNumInRange(-180, 180, 4);
  }
}

import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { BaseComponent } from '../../components/base/base.component';

@Component({
  selector: 'app-app-message-modal',
  templateUrl: './app-message-modal.component.html',
  styleUrls: ['./app-message-modal.component.scss']
})
export class AppMessageModalComponent extends BaseComponent {
  @Input() messageContext: string;
  @Input() actionDisabled = false;

  constructor(public modalController: ModalController) {
    super();
  }

  public handleActionEvent(action: string) {
    this.modalController.dismiss(action);
  }
}

import {Injectable} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {BehaviorSubject} from 'rxjs';
import {environment} from '../../environments/environment';
import {RouteBasedStructure} from '../models/route-based-structure';
import {Util} from '../util';
import {Platform} from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class RouteBasedStructureService {
  public routeBasedStructure$ = new BehaviorSubject<RouteBasedStructure>(new RouteBasedStructure());

  public noSpcCloseButtonRoutes = [
  ];

  constructor(private platform: Platform, private router: Router) {
    this.subscribeToRoute();
  }

  private subscribeToRoute() {
    this.router.events.pipe().subscribe((event) => {
    });
  }

  public getNewStructure(): RouteBasedStructure {
    const params = Util.getQueryParamsAsObject(window.location.search);
    let structure = this.routeBasedStructure$.getValue();
    structure.spcRouteComponent = this.getComponentForSpc(params.spc);
    structure.spcOriginalRoute = params.spc;
    structure.mainRouteComponent = this.getComponentForMain(window.location.pathname);
    structure.mainOriginalRoute = window.location.pathname;
    structure.mainRouteVisible = true;
    structure.spcRouteVisible = !!params.spc;
    structure.extrasRouteVisible = false;
    return structure;
  }

  private getOverrides(path: string, structure: RouteBasedStructure): RouteBasedStructure {
    // reset for extras route column or base route with no spc
    if (structure.isExtrasColSize) {
      structure.mainRouteColSize = '4';
      structure.spcRouteColSize = '6';
      structure.extrasRouteColSize = '2';
      structure.extrasRouteVisible = true;
      if (!structure.spcRouteVisible) {
        structure.mainRouteColSize = '10';
        structure.spcRouteColSize = '0';
        structure.extrasRouteColSize = '2';
      }
    }

    /* then adjust for specific routes... */

    return structure;
  }

  private async getIdParam(path: string, route: string, ) {
    const startIdx = route.length;
    return path.substr(startIdx + 1, 36);
  }

  private getComponentForSpc(spcRoute: string): string {
    if(!spcRoute)
      return null;  //WA 12/27/21: no need to muddy the issue by traversing the switch block if spcRoute is empty

    spcRoute = spcRoute || '';
    const currentStructure = this.routeBasedStructure$.getValue();
    let cleanSpcRoute = currentStructure.spcRouteComponent;
    // This ensures that a component doesn't reload on a route change for spc if it doesn't need to
    // This will ensure that a change in id, doesn't change the component and result in a cancelled http call
    // which results in a hanging loader because angular http doesn't respond with an error or response on cancel
    switch (true) {
      
      default:
        if (!spcRoute) {
          cleanSpcRoute = null;
        }
    }

    return cleanSpcRoute;
  }

  private getComponentForMain(mainRoute: string): string {
    if(!mainRoute)
      return null; //WA 12/27/21 no need to travers switch block if there's nothing to process

    mainRoute = mainRoute || '';
    const currentStructure = this.routeBasedStructure$.getValue();
    let cleanMainRoute = currentStructure.mainRouteComponent;
    // This ensures that a component doesn't reload on a route change for spc if it doesn't need to
    // This will ensure that a change in id, doesn't change the component and result in a cancelled http call
    // which results in a hanging loader because angular http doesn't respond with an error or response on cancel
    switch (true) {

      default:
        if (!mainRoute) {
          cleanMainRoute = null;
        }
    }

    return cleanMainRoute;
  }

}

import {environment} from '../../environments/environment';
import { IBaseApiModel } from '../interfaces/i-base-api-model';
import {LogLevels} from './logLevels';
import {UserProfile} from './user-profile';

export class User implements IBaseApiModel {
  public id: string;
  public instanceVersion: number;
  public createdBy: string;
  public createdAt: Date;
  public updatedBy: string;
  public updatedAt: Date;
  public deletedBy: string;
  public deletedAt: Date;
  public firstName: string;
  public middleName: string;
  public lastName: string;
  public metadata: any;
  public logLevels: LogLevels;
  public registeredAt: number;
  public passwordSetAt: number;
  // expanded
  public profiles: Array<UserProfile>;
  // additive
  public password: string;
  public initials: string;

  constructor(obj?: any) {
    Object.assign(this, obj);
    this.instanceVersion = this.instanceVersion ? this.instanceVersion : -1;
    this.profiles = this.profiles ? this.profiles.map((p) => {
      // api should not be returning user on user.profile
      delete p.user;

      return new UserProfile(p);
    }) : [];
    this.metadata = this.metadata ? this.metadata : {};
    this.metadata.agreements = this.metadata.agreements ? this.metadata.agreements : [];
    this.metadata.ackCookies = this.metadata.ackCookies ? this.metadata.ackCookies : false;
    this.metadata.userPlan = this.metadata.userPlan ?? {};
    if (!environment.settings.disableDefaultAvatarComponent && this.firstName && this.lastName) {
      this.initials = `${this.firstName[0]}${this.lastName[0]}`;
      this.initials = this.initials.toUpperCase();
    }
  }

  get hasAgreedToTerms(): boolean {
    return this.metadata.agreements.findIndex((ag) => {
      return ag.version === environment.app.versionAgreements && ag.ack;
    }) > -1;
  }

  get isValid(): boolean {
    const isFirstNameValid = !!(this.firstName && this.firstName.length);
    const isLastNameValid = !!(this.lastName && this.lastName.length);
    let isProfilesValid = true;
    for (const p of this.profiles) {
      isProfilesValid = p.isValid;
      if (!isProfilesValid) {
        break;
      }
    }
    return isFirstNameValid && isLastNameValid && isProfilesValid;
  }

  public cleanseForAPI(): void {
    const removeKeys = ['instanceVersion', 'profiles'];
    const currentKeys = Object.keys(this);
    currentKeys.forEach((key) => {
      if (removeKeys.indexOf(key) > -1) {
        delete this[key];
      }
    });
  }
}

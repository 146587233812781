import {Component, Input, OnInit} from '@angular/core';
import {BaseComponent} from '../base/base.component';
import {UserProfile} from '../../models/user-profile';
import {User} from '../../models/user';
import {UserService} from '../../services/user.service';
import {catchError, map, switchMap} from 'rxjs/operators';
import {BehaviorSubject, combineLatest, Observable, of} from 'rxjs';


@Component({
    selector: 'app-default-avatar',
    templateUrl: './default-avatar.component.html',
    styleUrls: ['./default-avatar.component.scss']
})
export class DefaultAvatarComponent extends BaseComponent implements OnInit {

    @Input() set profile(value: UserProfile) {
        this.profile$.next(value);
    }

    public profile$ = new BehaviorSubject<UserProfile>(null);
    public user$: Observable<User> = this.profile$.pipe(
        switchMap((profile) => {
            if (!profile?.initials && profile?.userId) {
                return this.userService.getById(profile.userId).pipe(catchError(err => {
                    this.handleError(err);
                    return of(null);
                }))
            } else if (profile?.initials) {
              return of(new User({
                avatarUrl: profile?.avatarUrl,
                initials: profile?.initials || profile?.displayName?.[0] || '',
                persona: profile?.persona
              }));
            } else {
                return of(null);
            }
        })
    );
    public vm$ = combineLatest([this.profile$, this.user$]).pipe(
        map(([profile, user]) => {
            return {
                avatarUrl: profile?.avatarUrl,
                initials: user?.initials || profile?.displayName?.[0] || '',
                persona: profile?.persona
            }
        })
    )

    constructor(public userService: UserService) {
        super();
    }
}